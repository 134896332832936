import { Route, Routes } from 'react-router-dom'

import { ForgotPassword } from './components/ForgotPassword'
import { Login } from './components/Login'
import { AuthLayout } from './AuthLayout'
import { RecoveryPassword } from './components/RecoveryPassword'
import { useNavigate } from 'react-router-dom'


const redirectToDashboard = () => {
  
  console.log("llegue a la ruta")
  window.location.href = '/dashboard/';

}
const handleSuccess = (data: any) => {
  console.log("llegue a la ruta")
  redirectToDashboard();

}

const handleFailure = (err: any) => {
  console.log('Failure', err);
  // Do something with the failed login data
}

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='login' element={<Login onSuccess={handleSuccess} onFailure={handleFailure} />} />

      <Route path='forgot-password' element={<ForgotPassword />} />
      <Route path='reset-password/:token' element={<RecoveryPassword />} />
      <Route index element={<Login onSuccess={handleSuccess} onFailure={handleFailure} />} />
    </Route>
  </Routes>
)

export { AuthPage }
