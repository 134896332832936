import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { fetchRequests, uploadDirectlyById } from './apis/rechargesAPI'
import { useIntl } from 'react-intl'

import Swal, { SweetAlertOptions } from 'sweetalert2';
import { getTokenConfig } from '../../../../src/auth'
import { downloadImage } from '../reportsPassport/apis/requestsApi';
import moment from 'moment';

import { ITransaction } from '../reportsPassport/core/Report.Model';
import Pagination from '../../components/Pagination';
interface LastRecordPassportProps {
  customerId: number; // o el tipo de dato que sea customerId
  registroExitoso: boolean;
}
const LastRecordPassport: React.FC<LastRecordPassportProps> = ({ customerId, registroExitoso }) => {
  const intl = useIntl()

  const [currentPage, setCurrentPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [totalPages, setTotalPages] = useState(0)

  const [refreshKey, setRefreshKey] = useState(0); // Añadir una nueva variable de estado

  const [data, setData] = useState<ITransaction[]>([])
  const [timezone, setTimezone] = useState('')


  interface CustomSweetAlertOptions extends SweetAlertOptions {
    onOpen?: () => void;
  }

  const calculateIndex = useCallback((index: number) => {
    return (currentPage - 1) * limit + index + 1;
  }, [currentPage, limit]);


  useEffect(() => {

    const fetchData = async () => {


      try {
        const { transactionsRequest, totalPages, timezone } = await fetchRequests(customerId, limit, currentPage)
        setTotalPages(totalPages)
        setData(transactionsRequest)

      
        setTimezone(timezone)

      } catch (err: any) {
        console.log(err)

      }
    }

    fetchData()
  }, [currentPage, limit]) // Arreglo de dependencias vacío

  const cargarDatos = async () => {
    // Aquí puedes hacer lo que sea necesario cuando se realiza un registro exitoso
    if (registroExitoso) {
      // Recargar los datos o realizar acciones adicionales
      const { transactionsRequest, totalPages , timezone } = await fetchRequests(customerId, limit, currentPage);
      setTotalPages(totalPages);
      setData(transactionsRequest);
      setTimezone(timezone)
    }
  };


  useEffect(() => {
    cargarDatos();
  }, [registroExitoso, refreshKey]); // Añadir `refreshKey` aquí
  


  // ... otros imports ...

  const handleDownload = async (image: string) => {
    try {
      Swal.fire({
        html: `
        <div class="spinner-container" style="overflow: hidden;">
          <i class="fas fa-spinner fa-spin"></i>
          <span>Descargando archivo...</span>
        </div>
        `,
        showConfirmButton: false,
        allowOutsideClick: false,
      } as CustomSweetAlertOptions);

      const config = await getTokenConfig();
      const url = await downloadImage(config, image);

      if (url) {
        const link = document.createElement('a');
        link.href = url;

        const fileName = url.split('/').pop() || 'downloaded_file';
        link.download = fileName;

        document.body.appendChild(link); // Agregar temporalmente al DOM
        link.click();
        document.body.removeChild(link); // Remover del DOM después de hacer clic
      } else {
        console.error("No se pudo obtener la URL del archivo o el archivo aún no está listo.");
      }


      Swal.close();
    } catch (err: any) {
      Swal.close();
      console.error(err);
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<ITransaction | null>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null)

  const openModal = (item: ITransaction) => {
    setSelectedItem(item);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedItem(null);
    setIsModalOpen(false);
  };


  const handleFileUpload = async () => {
    if (selectedFile && selectedItem) {
      try {

        Swal.fire({
          html: `
                  <div class="spinner-container" style="overflow: hidden;>
                      <i class="fas fa-spinner fa-spin"></i>
                      <span>Cargando imagen...</span>
                    </div>
                  `,
          showConfirmButton: false,
          allowOutsideClick: false,
        } as CustomSweetAlertOptions)


        await uploadDirectlyById(selectedFile, selectedItem.id);
        const { transactionsRequest, totalPages } = await fetchRequests(customerId, limit, currentPage);
      setTotalPages(totalPages);
      setData(transactionsRequest);
        setRefreshKey(prevKey => prevKey + 1); // Forzar un re-renderizado

        Swal.close()
        // console.log(`File uploaded successfully for item with id ${selectedItem.id}:`, fileUrl);
        closeModal();
     

      } catch (error) {

        Swal.close()

        Swal.fire({
          title: 'Error de Subida',
          text: 'Hubo un problema al subir el archivo. Por favor, intenta de nuevo.',
          icon: 'error'
        });
        console.error('Error uploading file:', error);
      }
    }
  };



  return (
    <>



      <div className='row mt-10'>
        <div className='col-12'>
          <div className='card'>
            <div className='card-header cursor-pointer'>
              <div className='card-title m-0'>
                <h3 className='fw-bolder m-0'>

                  Resumen de Transacciones del Último Mes
                </h3>
              </div>
            </div>

            <div className='card-body '>
              <div className="row  justify-content-left">

                <div className="table-responsive">
                  <table className='table table-striped mx-4 my-4' id='table' >
                    <thead>
                      <tr className='fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200'>
                        <th className='min-w-50px'>#</th>
                        <th className='min-w-150px'>
                          {intl.formatMessage({ id: 'REPORT_PASSPORT_CLIENT' })}
                        </th>
                        <th className='min-w-150px'>
                          {intl.formatMessage({ id: 'REPORT_PASSPORT_BAG' })}
                        </th>
                        <th className='min-w-150px'>
                          {intl.formatMessage({ id: 'REPORT_PASSPORT_MOVEMENT_TYPE' })}
                        </th>
                        <th className='min-w-150px'>
                          {intl.formatMessage({ id: 'REPORT_PASSPORT_BANK_ACCOUNT' })}
                        </th>
                        <th className='min-w-150px'>
                          {intl.formatMessage({ id: 'REPORT_PASSPORT_PAYMENT_METHOD' })}
                        </th>
                        <th className='min-w-150px'>
                          {intl.formatMessage({ id: 'REPORT_PASSPORT_AMOUNT' })}
                        </th>
                        <th className='min-w-150px'>
                          {intl.formatMessage({ id: 'REPORT_PASSPORT_AUTHORIZATION_NUMBER' })}
                        </th>
                        <th className='min-w-150px'>
                          {intl.formatMessage({ id: 'REPORT_PASSPORT_PAYMENT_DATE' })}
                        </th>

                        <th className='min-w-150px'>
                          {intl.formatMessage({ id: 'REPORT_PASSPORT_NOTE' })}
                        </th>

                        <th className='min-w-150px'>
                          Creado por
                        </th>
                        <th className='min-w-150px'>
                          Fecha de creación
                        </th>
                        <th>Imagen</th>


                      </tr>
                    </thead>
                    <tbody>


                      {data.map((item, index) => {
                        return (
                          <tr key={item.id}>
                            <td style={{ width: '14ch' }}>{calculateIndex(index)}</td>
                            <td style={{ width: '14ch' }}>{item.nameCustomer}</td>
                            <td style={{ width: '14ch' }}>{item.moneybagCustomerType.name}</td>

                            <td>{item.transactionType.name}</td>
                            <td style={{ width: '14ch' }}>{item.accountBank}</td>
                            <td style={{ width: '14ch' }}>{item.paymentType.name}</td>
                            <td style={{
                              width: '14ch',
                              color: item.transactionType.id === 1 ? 'green' :
                                item.transactionType.id === 2 ? 'red' : 'black'
                            }}>
                              $ {item.amount}
                            </td>

                            <td style={{ width: '14ch' }}>{item.noAut}</td>
                            <td style={{ width: '14ch' }}>

                            {moment(item.datePayment).tz(timezone).format('DD-MM-YYYY')}
                        </td>


                            <td style={{ width: '14ch' }}>{item.note}</td>
                            <td style={{ width: '14ch' }}>
                              {item.createdBy}</td>
                            <td style={{ width: '14ch' }}>
                            {moment(item.createdAt).tz(timezone).format('DD-MM-YYYY')}
                             </td>
                            <td>


                              {item.vaucher ? (
                                <a onClick={(e) => {
                                  e.preventDefault(); // Previene el comportamiento por defecto
                                  handleDownload(item.vaucher);
                                }} className="btn btn-primary circular-button">
                                  <i className="fa-solid fa-file-arrow-down p-0"></i>
                                </a>

                              ) : (
                                <button
                                  className="btn btn-muted circular-button"
                                  onClick={() => openModal(item)}
                                  style={{ backgroundColor: 'gray', color: 'white' }}
                                >
                                  <i className="fa-solid fa-file-arrow-down p-0" style={{ color: 'white' }}></i>
                                </button>

                              )}
                            </td>

                            {/* <td style={{ width: '14ch' }}>{item.vaucher}</td> */}

                          </tr>
                        );
                      })}


                      <Modal show={isModalOpen} onHide={closeModal}>
                        <Modal.Header closeButton>
                          <Modal.Title>Cargar Archivo</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <div className="mb-3">
                            <label className="form-label">
                              Seleccionar Archivo
                            </label>
                            <input
                              type="file"
                              className="form-control"
                              accept="image/*"
                              onChange={(e) => {
                                if (e.target.files && e.target.files.length > 0) {
                                  const file = e.target.files[0];

                                  // Verificar si el archivo es una imagen
                                  if (!file.type.startsWith('image/')) {
                                    Swal.fire({
                                      title: 'Error',
                                      text: 'Por favor, selecciona un archivo de imagen válido.',
                                      icon: 'error'
                                    });
                                    return;
                                  }

                                  // Tamaño del archivo en megabytes
                                  const fileSizeInMB = file.size / (1024 * 1024);

                                  // Comprobar si el archivo supera el límite de 2 MB
                                  if (fileSizeInMB > 2) {
                                    Swal.fire({
                                      title: 'Error',
                                      text: 'El archivo es demasiado grande. El tamaño máximo permitido es de 2 MB.',
                                      icon: 'error'
                                    });
                                  } else {
                                    setSelectedFile(file);
                                  }
                                }
                              }}
                            />

                          </div>
                          <div className="d-flex justify-content-end mb-3">
                            <button className="btn btn-primary me-2" onClick={handleFileUpload} disabled={!selectedItem}>
                              Cargar
                            </button>
                            <button className="btn btn-secondary" onClick={closeModal}>
                              Cancelar
                            </button>
                          </div>
                        </Modal.Body>
                      </Modal>
                    </tbody>



                  </table>
                </div>
                <div className='d-flex justify-content mb-4 same-col'>
                  <div>
                    <select
                      value={limit}
                      onChange={(event) => setLimit(Number(event.target.value))}
                      className='form-select form-select-sm custom-select-page'
                    >
                      <option value={10}>10</option>
                      <option value={15}>15</option>
                      <option value={30}>30</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                    </select>
                  </div>
                  <div>
                    {data.length !== 0 && (
                      <Pagination
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        totalPages={totalPages}
                        limit={limit}
                        lengthData={data.length}
                      />
                    )}
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>


    </>
  )
}
export { LastRecordPassport }
