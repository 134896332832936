/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { SidebarMenuItem } from './SidebarMenuItem'
import { SidebarMenuItemWithSub } from './SidebarMenuItemWithSub'
import PermissionsService from '../../../../../../src/app/modules/auth/PermissionsService'
import { boolean } from 'yup'
interface AllPermissions {
  hasUserListPermission: boolean;
  hasUserViewPermission: boolean;
  hasUserAddPermission: boolean;
  hasUserEditPermission: boolean;
  hasUserActivatePermission: boolean;
  hasUserDesactivePermission: boolean;
  hasUserDeletePermission: boolean;

  hasClientsConfigureChainsPermission: boolean;
  hasClientsViewReferencesPermission: boolean;
  hasClientsDeactivatePermission: boolean;
  hasClientsActivatePermission: boolean;
  hasClientsDeletePermission: boolean;
  hasClientsViewPermission: boolean;
  hasClientsEditPermission: boolean;
  hasClientsAddPermission: boolean;
  hasClientsListPermission: boolean;
  hasConsumptionReportCreditScorePermission: boolean;
  hasConsumptionReportIdentityValidationPermission: boolean;
  hasConsumptionReportPaymentsPermission: boolean;
  hasProductsConfigureChainsPermission: boolean;
  hasProductsDeactivatePermission: boolean;
  hasProductsActivatePermission: boolean;
  hasProductsDeletePermission: boolean;
  hasProductsListPermission: boolean;
  hasDashboardPermission: boolean;
  hasBitacoraPermission: boolean;

  hasGroupAddPermission: boolean;
  hasGroupEditPermission: boolean;
  hasGroupListPermission: boolean;

  hasProductConfigCommissionPermission: boolean;



  hasProductConfigPaymentServiceCommissionPermission: boolean;

  hasConsumptionReportRechargePermission: boolean;
  hasConsumptionReportTransactionPermission: boolean;
  hasConsumptionReportCC: boolean;
  hasConsumptionReportRI: boolean;
  hasConsumptionReportERPPermission: boolean;
  hasConsumptionReportPaymentServicePermission: boolean
  hasConsumptionReportJourneyServicePermission: boolean
  hasConsumptionReportHBMServicePermission: boolean
  hasConsumptionReportGenerateDocumentsPermission: boolean,
  hasConfigLimitSMS: boolean
  hasConsumptionReportSMS: boolean
  hasConsumptionReportMails: boolean



}
const SidebarMenuMain = () => {
  const intl = useIntl()
  const [permissions, setPermissions] = useState<AllPermissions | null>(null);
  const [isLoading, setLoading] = useState(true);




  useEffect(() => {
    let isMounted = true; // Para manejar el desmontaje del componente
    let retryCount = 0; // Contador de intentos de reintento

    const fetchPermissions = async () => {
      try {
        const fetchedPermissions = await new Promise((resolve, reject) => {
          setTimeout(async () => {
            const result = await PermissionsService.getPermissions(); // Asumiendo que esto es una función asíncrona
            resolve(result);
          }, 4000); // Simular un retraso de 4 segundos
        });

        if (isMounted && fetchedPermissions !== null) {
          setPermissions(fetchedPermissions as AllPermissions);
          setLoading(false);  // Actualiza el estado de carga solo cuando se obtienen los permisos
        } else if (isMounted && retryCount < 5) {
          // Si no se obtuvieron los permisos y aún no se ha alcanzado el límite de intentos, reintenta
          retryCount += 1;
          fetchPermissions();
        }
      } catch (error) {
        console.error('Error al obtener los permisos:', error);

        // Reintenta si el contador de intentos es menor que 5
        if (isMounted && retryCount < 5) {
          retryCount += 1;
          fetchPermissions();
        }
      } finally {
        if (isMounted && retryCount >= 5) {
          setLoading(false); // Esto se ejecutará si se alcanza el límite de intentos de reintento
        }
      }
    };

    fetchPermissions();

    // Limpiar el efecto
    return () => {
      isMounted = false;
    };
  }, []);



  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!permissions) {
    return <div>Error al cargar los permisos</div>;
  }



  const {
    hasUserListPermission,
    hasDashboardPermission,
    hasClientsListPermission,
    hasProductsListPermission,
    hasProductsConfigureChainsPermission,
    hasConsumptionReportIdentityValidationPermission,
    hasConsumptionReportPaymentsPermission,
    hasBitacoraPermission,
    hasGroupListPermission,
    hasConsumptionReportCreditScorePermission,
    hasProductConfigCommissionPermission,
    hasConsumptionReportRechargePermission,
    hasConsumptionReportTransactionPermission,
    hasProductConfigPaymentServiceCommissionPermission,
    hasConsumptionReportCC,
    hasConsumptionReportRI,
    hasConsumptionReportERPPermission,
    hasConsumptionReportPaymentServicePermission,
    hasConsumptionReportJourneyServicePermission,
    hasConsumptionReportHBMServicePermission,
    hasConsumptionReportGenerateDocumentsPermission,
    hasConfigLimitSMS, 
    hasConsumptionReportSMS,
    hasConsumptionReportMails

  } = permissions;


  return (
    <>
      {hasDashboardPermission && (
        <SidebarMenuItem
          to='/dashboard'
          icon='/media/icons/duotune/art/art002.svg'
          title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
          fontIcon='bi-app-indicator'
        />
      )}
      {hasUserListPermission && (
        <SidebarMenuItem
          to='/users'
          icon='/media/icons/duotune/communication/com006.svg'
          title={intl.formatMessage({ id: 'MENU.USERS' })}
          fontIcon='bi-app-indicator'
        />
      )}

      {hasClientsListPermission && (
        <SidebarMenuItem
          to='/customers'
          icon='/media/icons/duotune/finance/fin006.svg'
          title={intl.formatMessage({ id: 'CUSTOMER.HEADER' })}
          fontIcon='bi-app-indicator'
        />
      )}


      {(hasProductsListPermission || hasProductsConfigureChainsPermission || hasProductConfigCommissionPermission
        || hasProductConfigPaymentServiceCommissionPermission) && (

          <SidebarMenuItemWithSub
            to='/products'
            title='Productos'
            icon='/media/icons/duotune/ecommerce/ecm009.svg'
            fontIcon='bi-person'
          >
            {hasProductsListPermission && (
              <SidebarMenuItem to='/products' title='Listado de Productos' hasBullet={true} />

            )}

            {hasProductsConfigureChainsPermission && (
              <SidebarMenuItem to='/services/config' title='Configurar Cadenas' hasBullet={true} />
            )}

            {hasProductConfigCommissionPermission && (
              <SidebarMenuItem
                to='/recharges/config/desc'
                title='Configurar comisiones de recargas'
                fontIcon='bi-app-indicator'
                hasBullet={true}
              />
            )}

            {hasProductConfigPaymentServiceCommissionPermission && (
              <SidebarMenuItem
                to='/payment-services/config/commission'
                title='Configurar comisiones de pago de servicios'
                fontIcon='bi-app-indicator'
                hasBullet={true}
              />
            )}


            {hasConfigLimitSMS && (
              <SidebarMenuItem
                to='/services/SMS'
                title='Configurar alertas de créditos SMS'
                fontIcon='bi-app-indicator'
                hasBullet={true}
              />
            )}
          </SidebarMenuItemWithSub>



        )}

      {(hasConsumptionReportIdentityValidationPermission || hasConsumptionReportPaymentsPermission || 
      hasConsumptionReportCreditScorePermission ||hasConsumptionReportCC ||
        hasConsumptionReportRI) && (


          < SidebarMenuItemWithSub
            to='/crafted/pages'
            title='Reportes de consumo'
            fontIcon='bi-archive'
            icon='/media/icons/duotune/general/gen032.svg'
          >
            {hasConsumptionReportPaymentsPermission && (
              <SidebarMenuItem to='/reports' title='Reporte de Passport Cash-in' hasBullet={true} />
            )}
            {hasConsumptionReportIdentityValidationPermission && (
              <SidebarMenuItem to='/validate-identity-reports' title='Reporte de KYC' hasBullet={true} />
            )}
            {hasConsumptionReportCreditScorePermission && (
              <SidebarMenuItem to='/score-reports' title='Reporte de SCA' hasBullet={true} />
            )}

            {hasConsumptionReportRI && (
              <SidebarMenuItem
                to='/risk-indicators-reports'
                title='Reporte de Indicadores de Riesgo'
                hasBullet={true}
              />
            )}
            {hasConsumptionReportCC && (
              <SidebarMenuItem
                to='/cc-reports'
                title='Reportes de Círculo de crédito'
                hasBullet={true}
              />
            )}

{ hasConsumptionReportMails && (
              <SidebarMenuItem
                to='/mails-reports'
                title='Reporte de Email masivos'
                hasBullet={true}
              />
            )}
  

            {hasConsumptionReportTransactionPermission && (
              <SidebarMenuItem to='/passport-reports' title='Reporte de Transacciones Passport' hasBullet={true} />

            )}
         {hasConsumptionReportRechargePermission && (
              <SidebarMenuItem to='/recharges-reports/list' title='Reportes Recargas' hasBullet={true} />

            )} 

            {hasConsumptionReportPaymentServicePermission && (
              <SidebarMenuItem to='/payment-services-reports/list' title='Reportes de pago de servicios' hasBullet={true} />

            )}
            
            {/* {hasConsumptionReportRechargePermission && ( */}
              {/* <SidebarMenuItem to='/odoo-reports/list' title='Reportes ERP' hasBullet={true} /> */}
              {hasConsumptionReportERPPermission && (
              
              <SidebarMenuItem to='/odoo-reports/list' title='Reportes ERP' hasBullet={true} /> 
              )}
            {/* )} */}

            {hasConsumptionReportJourneyServicePermission && (
              <SidebarMenuItem to='/journey-event-reports' title='Reporte de eventos generados' hasBullet={true} />
            )}

            {hasConsumptionReportHBMServicePermission && (
              <SidebarMenuItem to='/hbm-reports/list' title='Reportes Device check' hasBullet={true} />
            )}

            {hasConsumptionReportGenerateDocumentsPermission && (
              <SidebarMenuItem to='/generacion-documentos-reports/list' title='Reportes Generación Documentos' hasBullet={true} />
            )}


            {hasConsumptionReportSMS && (
              <SidebarMenuItem to='/sms-reports/list' title='Reportes SMS' hasBullet={true} />
            )}




          </SidebarMenuItemWithSub >
        )}


      {hasGroupListPermission && (
        <SidebarMenuItem
          to='/groups'
          icon='/media/icons/duotune/communication/com014.svg'
          title={intl.formatMessage({ id: 'GROUPS.HEADER' })}
          fontIcon='bi-app-indicator'
        />
      )}
      {hasBitacoraPermission && (
        <SidebarMenuItem
          to='/logs'
          icon='/media/icons/duotune/general/gen012.svg'
          title={intl.formatMessage({ id: 'LOG.HEADER' })}
          fontIcon='bi-app-indicator'
        />
      )}




    </>
  )
}

export { SidebarMenuMain }
