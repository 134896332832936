import { useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'

import { useIntl } from 'react-intl'

import axios from 'axios'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'

const initialValues = {
  email: '',
}

export function ForgotPassword() {
  const intl = useIntl()
  let apiUrl = process.env.REACT_APP_API_URL;  //return axios.put(`${process.env.REACT_APP_API_URL}/groups/${id}`, data, config)
  const [statusCode, setStatusCode] = useState(undefined);
  const forgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email(intl.formatMessage({ id: 'WRONG_EMAIL_FORMAT' }))
      .required(intl.formatMessage({ id: 'EMAIL_REQUIRED' })),
  })


  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {

      setLoading(true);
      setHasErrors(undefined)



      setTimeout(() => {
        const { email } = values;

        let queryString = `${apiUrl}/forgot-password`;
        axios
          .post(queryString, { email })
          .then(response => {

            const status = response.data.status;
            setStatusCode(status);


            if (status === 500) {
              setHasErrors(true);
              setLoading(false);
              setSubmitting(false);
            } else {
              setSubmitting(false);
              setHasErrors(false);
              setLoading(false);

            }

          })
          .catch(error => {
            setHasErrors(error);
            setLoading(false);
            setSubmitting(false);
            setStatus("Error sending password reset email");
          });

      }, 1000)
    },
  })



  return (
    <>
      {loading && (
        <div className="d-flex justify-content-center mt-5">
          <div className="spinner-border" role="status">
            <span className="sr-only">Cargando...</span>
          </div>
        </div>
      )}
      {!loading && statusCode === 200 ? (
        <div className='text-center'>
          <img style={{ width: 100 }} src={toAbsoluteUrl('/media/email/confirm.png')} alt="" />
          <h1>Éxito</h1>
          <h4>Te hemos enviado un correo electrónico, por favor sigue las instrucciones para recuperar tu contraseña .</h4>
          <Link to="/login" className="btn btn-primary w-70 mt-3">Aceptar</Link>
        </div>
      ) : (

        <form
          className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
          noValidate
          id='kt_login_password_reset_form'
          onSubmit={formik.handleSubmit}
        >
          <div className='mb-10'>
            {/* begin::Title */}
            <h1 className='text-dark fw-bolder mb-3'>   {intl.formatMessage({ id: 'AUTH.FORGOT.TITLE' })}</h1>
            {/* end::Title */}

            {/* begin::Link */}
            <p>
              {intl.formatMessage({ id: 'AUTH.FORGOT.DESC' })}

            </p>
            {/* end::Link */}
          </div>


          {hasErrors === true && (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>

                {statusCode === 500 && (
                  intl.formatMessage({ id: 'AUTH.FORGOT.SORRY.NO.EXIST.EMAIL' })
                )}
                {statusCode !== 500 && (
                  intl.formatMessage({ id: 'AUTH.FORGOT.SORRY' })
                )}
              </div>
            </div>
          )}

          {/* end::Title */}

          {/* begin::Form group */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-gray-900 fs-6'>   {intl.formatMessage({ id: 'AUTH.INPUT.EMAIL' })}</label>
            <input
              type='email'
              placeholder=''
              autoComplete='off'
              {...formik.getFieldProps('email')}
              className={clsx(
                'form-control bg-transparent',
                { 'is-invalid': formik.touched.email && formik.errors.email },
                {
                  'is-valid': formik.touched.email && !formik.errors.email,
                }
              )}
            />
            {formik.touched.email && formik.errors.email && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.email}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Form group */}
          <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
            <button type='submit' id='kt_password_reset_submit' className='btn btn-primary me-4 w-100 rounded-pill'>
              <span className='indicator-label'>
                {intl.formatMessage({ id: 'AUTH.GENERAL.SUBMIT_BUTTON' })}
              </span>
              {loading && (
                <span className='indicator-progress'>
                  Espere por favor...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
            <Link to='/auth/login'>
              <button
                type='button'
                id='kt_login_password_reset_form_cancel_button'
                className='btn link-primary w-100 rounded-pill'
                disabled={formik.isSubmitting || !formik.isValid}
              >
                {intl.formatMessage({ id: 'AUTH.GENERAL.CANCEL' })}
              </button>
            </Link>{' '}
          </div>
          {/* end::Form group */}
        </form>
      )}
    </>

  )
}
