import { Suspense, useEffect, useState } from 'react'
import * as Yup from 'yup'

import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import { useParams } from "react-router-dom";
import { useIntl } from 'react-intl'
import axios from 'axios'



interface ResponseData {
    status: number;
    data: {
        message: string;
    };
}

interface IUpdatePassword {
    password: string;
    confirmPassword: string;
}

export function RecoveryPassword() {

    const { token } = useParams();
    let apiUrl = process.env.REACT_APP_API_URL;
    const [error, setError] = useState(null);
    const [data, setData] = useState<ResponseData>({
        status: 0,
        data: {
            message: ''
        }
    });

    const initialValues: IUpdatePassword = {
        password: '',
        confirmPassword: ''
    };
    const [loading, setLoading] = useState(true);
    const [loadingButton, setLoadingButton] = useState(false);
    const intl = useIntl()
    const [statusCode, setStatusCode] = useState(undefined);
    const forgotPasswordSchema = Yup.object().shape({

        password: Yup.string().required(intl.formatMessage({ id: 'CUSTOMER.VALIDATION.PASSWORD_REQUIRED' })).
            matches(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^\w\d\s:])(?!.*\s).{8,15}$/, intl.formatMessage({ id: 'CUSTOMER.VALIDATION.PASSWORD_COMPLEXITY' })),
        confirmPassword: Yup.string().required(intl.formatMessage({ id: 'CUSTOMER.VALIDATION.PASSWORD_CONFIRMATION_REQUIRED' }))
            .when('password', {
                is: (val: string) => val && val.length > 0,
                then: Yup.string().oneOf([Yup.ref('password')], intl.formatMessage({ id: 'CUSTOMER.VALIDATION.PASSWORD_MATCH' })),
            }),
    })


    useEffect(() => {

        let queryString = `${apiUrl}/valid-reset-password`;
        axios.post(queryString, {
            code: token
        })
            .then(response => {
                setData(response.data);
             
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            });
    }, []);


    const formik = useFormik<IUpdatePassword>({
        initialValues,
        validationSchema: forgotPasswordSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            setSubmitting(true);
            setLoadingButton(true)
            setTimeout(async () => {


                setSubmitting(true);
                const json = {
                    code: token,
                    password: values.password,
                    cpassword: values.confirmPassword
                }
                let queryString = `${apiUrl}/reset-password`;
                axios
                    .post(queryString, json)
                    .then(response => {

                        window.location.href = '/auth';
                        const status = response.data.status;

                        setStatusCode(status);
                        setLoadingButton(false)

                    })
                    .catch(error => {

                        setSubmitting(false);
                        setStatus("Error sending password reset email");
                        setLoadingButton(false)
                    });

            }, 1000)
        },
    })

    return (
        <Suspense fallback={<div>Loading...</div>}>
            {loading ? (
                <div className="text-center">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            ) : data && data.data && data.data.message === 'ok' ? (
                <div >
                    <form
                        onSubmit={formik.handleSubmit}
                        id='kt_signin_change_password'
                        className='form'
                        noValidate
                    >
                        <div className='row mb-1'>

                            <div className="row">
                                <h1>INGRESA CONTRASEÑA</h1>
                                <span>Por favor, ingresa  tu nueva contraseña y confírmala</span>
                                <br></br>
                                <div className='col-lg-12 mt-8'>
                                    <div className='fv-row mb-0'>
                                        <label htmlFor='newpassword' className='form-label fs-6 fw-bolder mb-3 text-right'>
                                            Nueva contraseña
                                        </label>
                                        <input
                                            type='password'
                                            className={'form-control bg-transparent'}
                                            id='newpassword'
                                            {...formik.getFieldProps('password')}
                                        />
                                        {formik.touched.password && formik.errors.password && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.password}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>



                            <div className="row">
                                <div className='col-lg-12'>
                                    <div className='fv-row mb-0'>
                                        <label htmlFor='confirmpassword' className='form-label fs-6 fw-bolder mb-3 text-left'>
                                            Confirmar nueva contraseña
                                        </label>
                                        <input
                                            type='password'
                                            className={'form-control bg-transparent'}
                                            id='confirmpassword'
                                            {...formik.getFieldProps('confirmPassword')}
                                        />
                                        {formik.touched.confirmPassword && formik.errors.confirmPassword && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.confirmPassword}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className='form-text mb-5'>
                            La contraseña debe tener al menos 8 caracteres y contener símbolos
                        </div>

                        <div className='d-flex'>
                            <button
                                id='kt_password_submit'
                                type='submit'
                                className='btn btn-primary me-2 px-6 rounded-pill'
                            >
                                {!loadingButton && 'Actualizar contraseña'}
                                {loadingButton && (
                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                        Espere por favor...{' '}
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                )}
                            </button>

                            <Link
                                id='kt_password_cancel'
                                type='button'
                                className='btn btn-color-gray-400 btn-active-light-primary px-6 rounded-pill'
                                to={"/users/list"}  >Cancelar</Link>

                        </div>
                    </form>
                </div>
            ) : data && data.data && data.data.message === 'invalid token' ? (

                <div className="text-center">
                    <h1>Token no válido</h1>
                    <p>Por favor, intente enviar una solicitud de recuperación de contraseña de nuevo.</p>
                </div>
            )
                : (
                    <div className="text-center">
                        <h1>Token no válido</h1>
                        <p>Por favor, intente enviar una solicitud de recuperación de contraseña de nuevo.</p>
                    </div>
                )}
        </Suspense>
    );

}
