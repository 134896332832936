import React from 'react';

interface Props {
    currentPage: number;
    setCurrentPage: (page: number) => void;
    totalPages: number;
    limit: number;
    lengthData: number;
}

const Pagination: React.FC<Props> = ({
    currentPage,
    setCurrentPage,
    totalPages,
    limit,
    lengthData,
}) => {
    // Calcula el rango de páginas a mostrar
    const maxPages = 5;
    const halfMaxPages = Math.floor(maxPages / 2);
    let pageRangeStart = Math.max(currentPage - halfMaxPages, 1);
    let pageRangeEnd = Math.min(pageRangeStart + maxPages - 1, totalPages);
    if (pageRangeEnd === totalPages) {
        pageRangeStart = Math.max(pageRangeEnd - maxPages + 1, 1);
    }

    return (
        <ul className="pagination">
            <li
                className={`page-item previous ${currentPage === 1 ? 'disabled' : ''}`}
                onClick={
                    currentPage === 1 ? undefined : () => setCurrentPage(currentPage - 1)
                }
            >
                <a href="#" className="page-link">
                    Anterior
                </a>
            </li>
            <li
                className={`page-item first-page ${currentPage === 1 ? 'custom-active' : ''}`}
                onClick={currentPage === 1 ? undefined : () => setCurrentPage(1)}
            >
                <a href="#" className="page-link">
                    1
                </a>
            </li>
            {pageRangeStart > 2 && (
                <li className="page-item disabled">
                    <a href="#" className="page-link">
                        ...
                    </a>
                </li>
            )}
            {Array.from({ length: pageRangeEnd - pageRangeStart + 1 }, (_, i) => {
                const page = i + pageRangeStart;
                const isCurrent = page === currentPage;
                const isDisabled = lengthData < limit && page === totalPages;
                if(page >1 && page!=totalPages ){
                    
                return (
                    <li
                        key={page}
                        className={`page-item ${isCurrent ? 'custom-active' : ''} ${isDisabled ? 'disabled' : ''
                            }`}
                        onClick={isDisabled ? undefined : () => setCurrentPage(page)}
                    >
                        <a href="#" className="page-link">
                            {page}
                        </a>
                    </li>
                )
                }
;
            })}
            {pageRangeEnd < totalPages - 1 && (
                <li className="page-item disabled">
                    <a href="#" className="page-link">
                        ...
                    </a>
                </li>
            )}
            {totalPages > 1 && (
                <li
                    className={`page-item last-page ${currentPage === totalPages ? 'custom-active' : ''
                        }`}
                    onClick={
                        currentPage === totalPages
                            ? undefined
                            : () => setCurrentPage(totalPages)
                    }
                >
                    <a href="#" className="page-link">
                        {totalPages}
                    </a>
                </li>
            )}
            <li
                className={`page-item next ${currentPage === totalPages ? 'disabled' : ''
                    }`}
                onClick={
                    currentPage === totalPages
                        ? undefined
                        : () => setCurrentPage(currentPage + 1)
                }
            >
                <a href="#" className="page-link">
                    Siguiente
                </a>
            </li>
        </ul>
    );
};

export default Pagination;
