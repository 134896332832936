import { useEffect, useCallback } from 'react'
//import { Navigate, Route, Routes } from 'react-router-dom'
import Pool from "../../../../src/UserPool";
export function Logout() {
  const user = Pool.getCurrentUser();


  const handleLogout = useCallback(() => {
    if (user) {
      user.signOut();
      localStorage.removeItem("cognito_token");
      localStorage.removeItem('userPermissions');
      console.log("Cerrada con éxito");
      window.location.href = 'auth/login';
    }
  }, [user]);

  useEffect(() => {
    handleLogout();
  }, [handleLogout]);

  return null;
}
