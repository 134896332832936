import React from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { IGroup } from './core/Group.Model'
import { useIntl } from 'react-intl'

type Props = {
  data: IGroup[],
  hasGroupEditPermission: boolean
}

const ListBody: React.FC<Props> = React.memo(({ data, hasGroupEditPermission }) => {
  const intl = useIntl()
 
  return (
    <tbody>
      {data.map((item) => {
        return (
          <tr key={item.id}>
            <td style={{ width: '14ch' }}>{item.id}</td>
            <td style={{ width: '14ch' }}>{item.name}</td>

            <td style={{ width: '14ch' }}>
              {item.created === null ? '' : moment(item.created).format('DD-MM-YYYY')}
            </td>
            {hasGroupEditPermission && (
              <td style={{ width: '14ch' }}>
                <div className='btn-group '>
                  <button
                    type='button'
                    className='dropdown-toggle icon-dropdown'
                    data-bs-config='{"popperConfig":{"strategy":"fixed"}}'
                    data-bs-toggle='dropdown'
                    aria-haspopup='true'
                    aria-expanded='false'
                    data-bs-boundary='viewport'
                  >
                    <i className='bi bi-filter-left'></i>
                  </button>
                  <ul className='dropdown-menu'>
                    <li>
                      <Link to={`/groups/edit/${item.id}`} className='dropdown-item '>
                        {intl.formatMessage({ id: 'GROUPS.EDIT' })}
                      </Link>
                    </li>
                  </ul>
                </div>
              </td>

            )}
          </tr>
        )
      })}
    </tbody>
  )
})

export default ListBody
