import  { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Navigate, Routes, Route, Outlet } from 'react-router-dom';
import { DashboardWrapper } from './DashboardWrapper';
import PermissionsService from '../../modules/auth/PermissionsService';

interface AllPermissions {
  hasDashboardPermission: boolean;
}

const DashboardPage = () => {

  const [permissions, setPermissions] = useState<AllPermissions | null>(null);
  const [isLoading, setLoading] = useState(true);


  useEffect(() => {
    let isMounted = true; // Para manejar el desmontaje del componente
    let retryCount = 0; // Contador de intentos de reintento

    const fetchPermissions = async () => {
      try {
        const fetchedPermissions = await new Promise((resolve, reject) => {
          setTimeout(async () => {
            const result = await PermissionsService.getPermissions(); // Asumiendo que esto es una función asíncrona
            resolve(result);
          }, 4000); // Simular un retraso de 4 segundos
        });

        if (isMounted && fetchedPermissions !== null) {
          setPermissions(fetchedPermissions as AllPermissions);
          setLoading(false);
        } else if (isMounted && retryCount < 5) {
          // Si no se obtuvieron los permisos y aún no se ha alcanzado el límite de intentos, reintenta
          retryCount += 1;
          fetchPermissions();
        }
      } catch (error) {
        console.error('Error al obtener los permisos:', error);

        // Reintenta si el contador de intentos es menor que 5
        if (isMounted && retryCount < 5) {
          retryCount += 1;
          fetchPermissions();
        }
      } finally {
        if (isMounted && retryCount >= 5) {
          setLoading(false); // Esto se ejecutará si se alcanza el límite de intentos de reintento
        }
      }
    };

    fetchPermissions();

    // Limpiar el efecto
    return () => {
      isMounted = false;
    };
  }, []);


  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!permissions) {
    return <div>Error al cargar los permisos</div>;
  }

  const { hasDashboardPermission } = permissions;

  return (
    <Routes>
      <Route element={<Outlet />}>
        {hasDashboardPermission ? (
          <Route
            path="/"
            element={
              <>
                <DashboardWrapper />
              </>
            }
          />
        ) : (
          <Route path="dashboard" element={<Navigate to="/blank" replace />} />
        )}
      </Route>
    </Routes>
  );
};

export default DashboardPage;
