import axios from 'axios';


export const fetchLastLogin = async (cognito_user_id: string, token: string): Promise<any> => {


    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
    const queryString = `${process.env.REACT_APP_API_URL}/lastLogin`;

    try {
        const response = await axios.post(
            queryString,
            { cognito_user_id },
            config
        );

        // console.log("Respuesta recibida:", response);
        return response.data;
    } catch (error) {
        console.error("Error en la petición:", error);
        return [];
    }
};


export const fetchPermission = async (cognito_user_id: string, token: string): Promise<any> => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
    const encodedUserId = encodeURIComponent(cognito_user_id);
    const queryString = `${process.env.REACT_APP_API_URL}/get-user-permission?cognito_user_id=${encodedUserId}`;
    
    try {
        
        const response = await axios.get(queryString, config);

        // console.log("Respuesta recibida:", response);
        return response.data;
    } catch (error) {
        console.error("Error en la petición:", error);
        return [];
    }
};