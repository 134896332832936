import React from 'react'

import {IProduct} from './core/Product.Model'

type Props = {
  data: IProduct[]
  handleUpdateStatus: (id: number, statusId: number) => void
  handleDelete: (id: number, statusId: number) => void
  hasProductsDeactivatePermission: boolean
  hasProductsActivatePermission: boolean
  hasProductsDeletePermission: boolean
}

const ProductListBody: React.FC<Props> = React.memo(
  ({
    data,
    handleUpdateStatus,
    handleDelete,
    hasProductsDeactivatePermission,
    hasProductsActivatePermission,
    hasProductsDeletePermission,
  }) => {
    return (
      <tbody>
        {data.map((item) => (
          <tr key={item.id}>
            <td>{item.id}</td>
            <td>{item.providers.name}</td>
            <td>{item.categories.name}</td>
            <td>{item.name}</td>
            <td>
              {' '}
              {item.status.id === 2 ? (
                hasProductsActivatePermission ? (
                  <button
                    id={`button-${item.id}`}
                    className='btn toggle-status btn-light'
                    onClick={() => handleUpdateStatus(item.id, item.status.id)}
                  >
                    Inactivo
                  </button>
                ) : (
                  <button className='btn toggle-status btn-light' disabled>
                    Inactivo
                  </button>
                )
              ) : hasProductsDeactivatePermission ? (
                <button
                  id={`button-${item.id}`}
                  className='btn toggle-status btn-success'
                  onClick={() => handleUpdateStatus(item.id, item.status.id)}
                >
                  Activo
                </button>
              ) : (
                <button className='btn toggle-status btn-success' disabled>
                  Activo
                </button>
              )}
            </td>

            {hasProductsDeletePermission && (
              <td>
                <div className='btn-group '>
                  <button
                    type='button'
                    className='dropdown-toggle icon-dropdown'
                    data-bs-config='{"popperConfig":{"strategy":"fixed"}}'
                    data-bs-toggle='dropdown'
                    aria-haspopup='true'
                    aria-expanded='false'
                    data-bs-boundary='viewport'
                  >
                    <i className='bi bi-filter-left'></i>
                  </button>
                  <ul className='dropdown-menu'>
                    <li>
                      <button
                        onClick={() => handleDelete(item.id, item.status.id)}
                        className='dropdown-item '
                      >
                        Eliminar
                      </button>
                    </li>
                  </ul>
                </div>
              </td>
            )}
          </tr>
        ))}
      </tbody>
    )
  }
)

export default ProductListBody
