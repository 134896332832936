import React, { useState, useEffect, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { getTokenConfig } from '../../../../src/auth'

import Pagination from '../../components/Pagination'
import ListBody from './ListBody'
import { fetchRequests } from './api/requestAPI'
import { IGroup } from './core/Group.Model'


import PermissionsService from '../auth/PermissionsService'

const List: React.FC = () => {
  const intl = useIntl()
  const [data, setData] = useState<IGroup[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [totalPages, setTotalPages] = useState(0)

  const [searchValue, setSearchValue] = useState('')
  const [searchStatus, setSearchStatus] = useState('')
  const [orderType, setOrderType] = useState<string>('status,name,asc')
  const [searchInitiated, setSearchInitiated] = useState(false)

  const [orderDirection, setOrderDirection] = useState<'asc' | 'desc'>('asc')


  const permissions = PermissionsService.getPermissions();

  const {
    hasGroupAddPermission,
    hasGroupEditPermission,
   
  } = permissions || {};


  const handleReset = async () => {
    setCurrentPage(1)
    await new Promise<void>((resolve) => {
      setSearchValue('')
      setSearchStatus('')

      resolve()
    })
  }
  useEffect(() => {
    if (searchValue === '' && searchStatus === '') {
      handleSearch()
    }
  }, [searchValue, searchStatus])

  const handleOrderChange = (newOrderType: string) => {
    setOrderType(newOrderType)

    setOrderDirection((prevDirection) => (prevDirection === 'asc' ? 'desc' : 'asc'))
  }

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      setError(null)

      try {
        const config = await getTokenConfig()

        const { requests, totalPages } = await fetchRequests(
          config,
          limit,
          currentPage,
          searchValue,
          orderType,
          orderDirection,
          searchStatus // incluir el filtro por estatus
        )

        setData(requests)

        //console.log(requests)
        setIsLoading(false)
        setTotalPages(totalPages)
      } catch (err: any) {
        setError(err)
        setIsLoading(false)
      }
    }

    fetchData()
  }, [currentPage, limit, orderType, orderDirection])

  const memoizedData = useMemo(() => data, [data])
  const handleSearch = async () => {
    setCurrentPage(1)
    setSearchInitiated(true)
    setIsLoading(true)
    setError(null)

    try {
      const config = await getTokenConfig()

      const { requests, totalPages } = await fetchRequests(
        config,
        limit,
        currentPage,
        searchValue,
        orderType,
        orderDirection,
        searchStatus // incluir el filtro por estatus
      )
      setData(requests)

      setIsLoading(false)
      setTotalPages(totalPages)
    } catch (err: any) {
      setError(err)
      setIsLoading(false)
    }
  }

  if (isLoading) {
    return <div>{intl.formatMessage({ id: 'PLEASE.WAIT' })}</div>
  }

  if (error) {
    return <div>Ocurrió un error</div>
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-body py-3 '>

        {hasGroupAddPermission && (
          <Link
            to={'/groups/create'}
            className='btn btn-outline btn-outline-primary btn-active-light-primary add-button'
          >
            {intl.formatMessage({ id: 'GROUPS.ADD' })}
          </Link>
        )}
        <div className='row mb-5'>
          <div className='col-lg-4 d-flex align-items-start flex-wrap'>
            <div className='w-100'>
              <label className='form-label'>Buscar</label>
              <input
                type='text'
                data-kt-user-table-filter='search'
                onChange={(event) => setSearchValue(event.target.value)}
                className='form-control form-control'
                value={searchValue}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    handleSearch()
                  }
                }}
              />
            </div>
          </div>

          <div className='col-lg-5 align-bottom'>
            <div className='split-button'>
              <button onClick={handleSearch} className='btn btn-primary rounded-pill'>
                {intl.formatMessage({ id: 'ACTION.SEARCH' })}
              </button>
              <button onClick={handleReset} className='btn btn-secondary rounded-pill'>
                {intl.formatMessage({ id: 'ACTION.EREASE' })}
              </button>
            </div>
          </div>
        </div>

        <div className='table-responsive mb-20'>
          {data.length !== 0 && (
            <table className='table table-striped' id='table'>
              <thead>
                <tr className='fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200'>
                  <th className='min-w-50px'>#</th>
                  <th onClick={() => handleOrderChange('name,asc')} className='min-w-550px'>
                    {intl.formatMessage({ id: 'GROUPS.NAME' })}
                  </th>

                  <th onClick={() => handleOrderChange('createdAt,asc')} className='min-w-450px'>
                    {intl.formatMessage({ id: 'GROUPS.DATE' })}
                  </th>

                  {hasGroupEditPermission && (

                  <th onClick={() => handleOrderChange('userName,asc')} className='min-w-450px'>
                    {intl.formatMessage({ id: 'GROUPS.ACTIONS' })}
                  </th>
                   )}
                </tr>
              </thead>
              <ListBody 
              data={data}
               hasGroupEditPermission={hasGroupEditPermission || false} />
            </table>
          )}

          <div className='d-flex justify-content-center'>
            {data.length === 0 && (
              <p className='mb-0'>
                <strong>No hay resultados disponibles</strong>
              </p>
            )}
          </div>
        </div>
        <div className='d-flex justify-content mb-4 same-col'>
          <div>
            <select
              value={limit}
              onChange={(event) => setLimit(Number(event.target.value))}
              className='form-select form-select-sm custom-select-page'
            >
              <option value={10}>10</option>
              <option value={15}>15</option>
              <option value={30}>30</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
          </div>
          <div>
            {memoizedData.length !== 0 && (
              <Pagination
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalPages={totalPages}
                limit={limit}
                lengthData={memoizedData.length}
               
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export { List }
