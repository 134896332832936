import React from 'react';

interface Props {
  currentPage: number;
  setCurrentPage: (page: number) => void;
  totalPages: number;
  limit: number;
  lengthData: number;
}

const Pagination: React.FC<Props> = ({ currentPage, setCurrentPage, totalPages, limit, lengthData }) => {

  return (
    <ul className="pagination">
      <li className={`page-item previous ${currentPage === 1 ? 'disabled' : ''}`} onClick={currentPage === 1 ? undefined : () => setCurrentPage(currentPage - 1)}>
        <a href="#" className="page-link">
          <i className="previous"></i>
        </a>
      </li>
      {Array.from({ length: totalPages }, (_, i) => (
        <li key={i} className={`page-item  ${i + 1 === currentPage ? 'custom-active' : ''} ${lengthData < limit && i + 1 === totalPages ? 'disabled' : ''}`} onClick={lengthData < limit && i + 1 === totalPages ? undefined : () => setCurrentPage(i + 1)}>
          <a href="#" className="page-link">
            {i + 1}
          </a>
        </li>
      ))}

      <li className={`page-item next ${lengthData < limit ? 'disabled' : ''}`} onClick={lengthData < limit ? undefined : () => setCurrentPage(currentPage + 1)}>
        <a href="#" className="page-link">
          <i className="next"></i>
        </a>
      </li>
    </ul>
  );
};

export default Pagination;
