import React, { useState, useEffect, useMemo } from 'react'

import { useIntl } from 'react-intl'
import { getTokenConfig } from '../../../../src/auth'

import {
  ITransactionsMoneybagCustomer,
  ITypesMoneybagCustomers,ITransaction
} from './core/Report.Model'


import { downloadImage, fetchCatalogs, fetchCustomers, fetchExcelReport, fetchRequests, } from './apis/requestsApi'

import Swal, { SweetAlertOptions } from 'sweetalert2'
import { ICustomer } from '../reportsValidateIdentity/core/Report.Model'

import SearchFilters from './SearchFilters';
import ResultsTable from './ResultsTable';


const ReportList: React.FC = () => {
  const intl = useIntl()

  const [data, setData] = useState<ITransaction[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [totalPages, setTotalPages] = useState(0)

  const [searchValue, setSearchValue] = useState('')
  const [searchCustomer, setSearchCustomer] = useState('')
  const [searchStatus, setSearchStatus] = useState('')
  const [orderType, setOrderType] = useState<string>('status,name,asc')

  const [orderDirection, setOrderDirection] = useState<'asc' | 'desc'>('asc')

  const [transactions, setTransactions] = useState<ITransactionsMoneybagCustomer[]>([]);
  const [types, setTypes] = useState<ITypesMoneybagCustomers[]>([]);

  const [saldoAbonos, setSaldoAbonos] = useState<number | 0>(0);
  const [saldoCargos, setSaldoCargos] = useState<number | 0>(0);
  const [totalConsumed, setTotalConsumed] = useState<number | 0>(0);
  const [totalAmount, setTotalAmount] = useState<number | 0>(0);


  const [customers, setCustomer] = useState<ICustomer[]>([])
  const [datePaymentInit, setDatePaymentInit] = useState('')
  const [datePaymentEnd, setDatePaymentEnd] = useState('')

  const [createdAtDateInit, setcreatedAtDateInit] = useState('')
  const [createdAtDateEnd, setcreatedAtDateEnd] = useState('')

  const [searchTypesTransactions, setTypesTransactions] = useState('')
  const [searchTypesPayments, seTypesPayments] = useState('')
  const [timezone, setTimezone] = useState('');

  interface CustomSweetAlertOptions extends SweetAlertOptions {
    onOpen?: () => void
  }

  const handleReset = async () => {
    setCurrentPage(1)
    await new Promise<void>((resolve) => {
      setSearchValue('')

      setcreatedAtDateInit('')
      setcreatedAtDateEnd('')

      setDatePaymentInit('')
      setDatePaymentEnd('')
      setTypesTransactions('')
      seTypesPayments('')
      setSearchCustomer('')
      resolve()
    })
  }
  useEffect(() => {
    if (
      searchValue === '' &&
      searchStatus === '' &&
      createdAtDateInit === '' &&
      createdAtDateEnd === '' &&
      searchTypesTransactions === '' &&
      searchTypesPayments === '' &&
      searchCustomer === ''
    ) {
      handleSearch()
    }
  }, [searchValue, searchStatus, createdAtDateInit, createdAtDateEnd,
    searchTypesTransactions, searchTypesPayments,
    searchCustomer])

  const handleOrderChange = (newOrderType: string) => {
    setOrderType(newOrderType)

    setOrderDirection((prevDirection) => (prevDirection === 'asc' ? 'desc' : 'asc'))
  }




  useEffect(() => {


    const fetchData = async () => {
      setIsLoading(true); // Establecer isLoading como true al iniciar la carga de datos
      setError(null)


      try {


        const config = await getTokenConfig()
        const { transactions, totalPages,totalAbonos,totalCargos, totalAmount, totalConsumed,
        timezone
        } = await fetchRequests(
          config,
          limit,
          currentPage,
          searchValue,
          searchCustomer,
          createdAtDateInit,
          createdAtDateEnd,
          datePaymentInit,
          datePaymentEnd,
          searchTypesTransactions,
          searchTypesPayments,
          orderType,
          orderDirection,
      
        )

        setData(transactions)
        setSaldoAbonos(totalAbonos);
        setSaldoCargos(totalCargos);
        setTotalAmount(totalAmount)
        setTotalConsumed(totalConsumed)
        setTotalPages(totalPages)
        setTimezone(timezone)
        setIsLoading(false)


      } catch (err: any) {
        setError(err)
        setIsLoading(false)
      }
    }

    fetchData()
  }, [currentPage, limit, orderType, orderDirection])

  useEffect(() => {

    const fetchData = async () => {

      setError(null)
      try {
        const config = await getTokenConfig()

        const { customers } = await fetchCustomers(config);

        const { transactions, types, } = await fetchCatalogs()


        setTransactions(transactions);
        setTypes(types);

        setCustomer(customers);


      } catch (err: any) {
        setError(err)

      }
    }

    fetchData()
  }, []) // Arreglo de dependencias vacío

  const handleDownload = async (image: string) => {
    try {
      Swal.fire({
        html: `
        <div class="spinner-container" style="overflow: hidden;">
          <i class="fas fa-spinner fa-spin"></i>
          <span>Descargando archivo...</span>
        </div>
        `,
        showConfirmButton: false,
        allowOutsideClick: false,
      } as CustomSweetAlertOptions);

      const config = await getTokenConfig();
      const url = await downloadImage(config, image);

      if (url) {
        const link = document.createElement('a');
        link.href = url;

        const fileName = url.split('/').pop() || 'downloaded_file';
        link.download = fileName;

        document.body.appendChild(link); // Agregar temporalmente al DOM
        link.click();
        document.body.removeChild(link); // Remover del DOM después de hacer clic
      } else {
        console.error("No se pudo obtener la URL del archivo o el archivo aún no está listo.");
      }


      Swal.close();
    } catch (err: any) {
      Swal.close();
      console.error(err);
    }
  };

  const handleDownloadExcel = async () => {
    try {

      Swal.fire({
        html: `
        <div class="spinner-container" style="overflow: hidden;>
            <i class="fas fa-spinner fa-spin"></i>
            <span>Generando reporte...</span>
          </div>
        `,
        showConfirmButton: false,
        allowOutsideClick: false,
      } as CustomSweetAlertOptions)

      const config = await getTokenConfig()
      const url = await fetchExcelReport(
        config,
        searchValue,
        searchCustomer,
        createdAtDateInit,
        createdAtDateEnd,
        datePaymentInit,
        datePaymentEnd,
        searchTypesTransactions,
        searchTypesPayments,
        orderType,
  

      )


      if (url !== null) {
        const link = document.createElement('a');
        link.href = url;
        link.download = 'customer_score_report.csv';
        link.click();
      } else {
        console.error("No se pudo obtener la URL del reporte o el reporte aún no está listo.");
      }


      Swal.close()
    } catch (err: any) {
      Swal.close()
      console.error(err)
    }
  }





  const memoizedData = useMemo(() => data, [data])
  const handleSearch = async () => {
    setCurrentPage(1)

    setIsLoading(true)
    setError(null)

    try {
      const config = await getTokenConfig()
      const { transactions, totalPages, totalAbonos, totalCargos, totalAmount, totalConsumed,
      timezone  } = await fetchRequests(
        config,
        limit,
        currentPage,
        searchValue,
        searchCustomer,
        createdAtDateInit,
        createdAtDateEnd,
        datePaymentInit,
        datePaymentEnd,
        searchTypesTransactions,
        searchTypesPayments,
        orderType,
        orderDirection,

      )
      setData(transactions)
      setIsLoading(false)
      setTotalPages(totalPages)

      setTimezone(timezone)
      setSaldoAbonos(totalAbonos);
      setSaldoCargos(totalCargos);
      setTotalAmount(totalAmount)
      setTotalConsumed(totalConsumed)
    } catch (err: any) {
      setError(err)
      setIsLoading(false)
    }
  }


  if (error) {
    return <div>Ocurrió un error</div>
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-body py-3 '>
        <SearchFilters
          searchValue={searchValue}
          setSearchValue={setSearchValue}

          searchCustomer={searchCustomer}
          setSearchCustomer={setSearchCustomer}

          searchTypesTransactions={searchTypesTransactions}
          setTypesTransactions={setTypesTransactions}

          searchTypesPayments={searchTypesPayments}
          seTypesPayments={seTypesPayments}

          datePaymentInit={datePaymentInit}
          setDatePaymentInit={setDatePaymentInit}

          datePaymentEnd={datePaymentEnd}
          setDatePaymentEnd={setDatePaymentEnd}


          createdAtDateInit={createdAtDateInit}
          setCreatedAtDateInit={setcreatedAtDateInit}

          createdAtDateEnd={createdAtDateEnd}
          setCreatedAtDateEnd={setcreatedAtDateEnd}

          saldoAbonos={saldoAbonos}
          setSaldoAbonos={setSaldoAbonos}


          saldoCargos={saldoCargos}
          setSaldoCargos={setSaldoCargos}


          totalAmount= {totalAmount}
          setTotalAmount={setTotalAmount}
          
          totalConsumed={totalConsumed}
          setTotalConsumed={setTotalConsumed}
    

          typesTransactions={transactions}
          typesMoneybagCustomers={types}
          customers={customers}

          handleSearch={handleSearch}
          handleReset={handleReset}
          handleDownload={handleDownload}
          handleDownloadExcel={handleDownloadExcel}
        />

        <ResultsTable
          data={data}
          handleOrderChange={handleOrderChange}
          isLoading={isLoading}
          currentPage={currentPage}
          limit={limit}
          setLimit={setLimit}
          totalPages={totalPages}
          memoizedData={memoizedData}
          setCurrentPage={setCurrentPage}
          handleDownload={handleDownload}
          handleSearch={handleSearch}

          timezone={timezone}

        />


      </div>
    </div>
  )
}

export { ReportList }
