import axios, {  AxiosResponse } from 'axios';
import { getTokenConfig } from '../../../../../src/auth'



export const configCommission = async () => {
  try {
    let queryString = `${process.env.REACT_APP_API_URL}/payment-services/config`;

    const config = await getTokenConfig(); // Assuming getTokenConfig is a function that returns a config object for axios
    const response = await axios.get(queryString, config);


    console.log("paymnent", response.data.data.PaymentServices)
  

 


    const paymentServices = response.data.data.PaymentServices;
 

    return { paymentServices };
  } catch (err) {
    throw err;
  }
};



export const updateComissionPaymentServiceConfig = async (value: any) => {
  try {
      let queryString = `${process.env.REACT_APP_API_URL}/payment-services/config`;


      console.log(value)
      const config = await getTokenConfig(); 
      const response = await axios.put(queryString, value, config);

      if (response.status !== 200) {
          throw new Error('Failed to update recharge configuration');
      }

      return response.data;
  } catch (err) {
      throw err;
  }
};