import React, { useCallback } from 'react';
import moment from 'moment';

import { IReport } from './core/Report.Model';



type Props = {
    data: IReport[];
    currentPage: number;
    limit: number;
    timezone:string;

};

const ReportListBody: React.FC<Props> = React.memo(({ data, currentPage, limit, timezone }) => {

    const calculateIndex = useCallback((index: number) => {
        return (currentPage - 1) * limit + index + 1;
    }, [currentPage, limit]);


    if (data.length === 0) {
        return <div>Cargando...</div>;
    }

    return (
        <tbody>
            {data.map((item, index) => {

                const score = item.providerResponse ? JSON.parse(item.providerResponse)?.scores?.[0]?.value : null;
                return (
                    <tr key={item.id}>
                        <td style={{ width: '14ch' }}>{calculateIndex(index)}</td>
                        <td style={{ width: '14ch' }}>Credolab</td>
                        <td style={{ width: '14ch' }}>{item.customerName}</td>
                        <td style={{ width: '14ch' }}>{item.reference}</td>
                        <td style={{ width: '14ch' }}>{item.productName}</td>

             
                        
                        <td style={{ width: '14ch' }}>           {moment(item.createdAt).tz(timezone).format('DD-MM-YYYY')}</td>
                        <td style={{ width: '14ch' }}>{item.statusCode === '200' ? 'Aceptado' : 'Rechazado'}</td>
                        <td style={{ width: '14ch' }}>{item.authorizedCredit ? 'Otorgado' : 'No otorgado'}</td>
                        <td style={{ width: '14ch' }}>{score}</td>
                        <td style={{ width: '14ch' }}>{item.deviceId}</td>
                        <td style={{ width: '14ch' }}>{item.userName}</td>
                        <td className="centered-cell">{item.preValidatedDevice ? 'Sí' : 'No'}</td>
                        <td>
                            <button
                                type='button'
                                className='dropdown-toggle icon-dropdown'
                                data-bs-toggle='modal'
                                data-bs-target={`#requestModal-${item.id}`}
                            >
                                <i className='bi bi-eye-fill'></i>
                            </button>

                            <div
                                className='modal fade '
                                id={`requestModal-${item.id}`}
                                tabIndex={-1}
                                aria-labelledby='exampleModalLabel'
                                aria-hidden='true'
                            >
                                <div className='modal-dialog modal-dialog-centered modal-lg'>
                                    <div className='modal-content'>
                                        <div className='modal-header'>
                                            <h1 className='modal-title fs-5' id='staticBackdropLabel'>
                                                Detalle
                                            </h1>
                                            <button
                                                type='button'
                                                className='btn-close'
                                                data-bs-dismiss='modal'
                                                aria-label='Close'
                                            ></button>

                                        </div>
                                        <div className='modal-body'>
                                            <p className='text-start ms-5'><strong> Request</strong></p>
                                            <div className='text-start ms-5'>
                                                <pre>
                                                    {JSON.stringify(JSON.parse(item.providerRequest), null, 4)}
                                                </pre>
                                            </div>

                                            <p className='text-start ms-5'><strong> Response</strong></p>
                                            <div className='text-start ms-5'>
                                                <pre>
                                                    {JSON.stringify(JSON.parse(item.providerResponse), null, 4)}
                                                </pre>
                                            </div>
                                        </div>
                                        <div className='modal-footer'>
                                            <button type='button' className='btn btn-secondary' data-bs-dismiss='modal'>
                                                Cerrar
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </td>


                    </tr>
                );
            })}
        </tbody>
    );
});

export default ReportListBody;



