import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { toAbsoluteUrl } from '../../../helpers'
import { HeaderUserMenu } from '../../../partials'
import userPool from "../../../../UserPool";
import moment from 'moment-timezone';


import { CognitoUserSession } from "amazon-cognito-identity-js";
import axios from 'axios';

interface User {
  id: string;
  email: string;
  first_name: string;
  first_surname: string;
  second_surname: string;
  phone: string;
  photo: string;
  timezone: string;
  group: {
    id: string;
    name: string;
  };

}


// aquí especificamos que el efecto se ejecute solo una vez


const Navbar = () => {
  const itemClass = 'ms-1 ms-lg-3'
  let apiUrl = process.env.REACT_APP_API_URL;  //return axios.put(`${process.env.REACT_APP_API_URL}/groups/${id}`, data, config)
  const userAvatarClass = 'symbol-35px symbol-md-40px'

  const [currentTime, setCurrentTime] = useState('');
  const [timezoneOffset, setTimezoneOffset] = useState('');

  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    try {
      const user = userPool.getCurrentUser();

      if (!user) {
        throw new Error("No hay usuario logueado");
      }

      user.getSession((err: any, session: any) => {
        if (err) {
          throw err;
        }

        const accessToken = (session as CognitoUserSession).getIdToken().getJwtToken();


        const userEmail = user.getUsername();

        const config = {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };

        let queryString = `${apiUrl}/view-profile`;
        axios
          .get<{ user: User }>(
            queryString,
            {
              params: { email: userEmail },
              ...config
            }
          )
          .then((response) => {



            setUser(response.data.user)



          })
          .catch((error) => {
            console.log(error)
          });
      });
    } catch (err: any) {
      console.log(err)
    }

    

  }, []);

    // useEffect para actualizar la hora
    useEffect(() => {
      // Definimos una función para actualizar la hora y el offset
      const updateTime = () => {
        const userTimezone = user?.timezone || moment.tz.guess(); // Usa la zona horaria del usuario o la del sistema
        const now = moment().tz(userTimezone);
        setCurrentTime(now.format('HH:mm:ss'));
        setTimezoneOffset(now.format('Z'));
      };
  
      // Actualizamos la hora inmediatamente y luego cada segundo
      updateTime();
      const intervalId = setInterval(updateTime, 1000);
  
      // Limpiamos el intervalo cuando el componente se desmonte
      return () => clearInterval(intervalId);
    }, [user]); // Este efecto depende del estado del usuario
  


  return (
    <div className='app-navbar flex-shrink-0'>
      <div className='fw-bolder d-flex align-items-center fs-5'>
        <span className='badge profile-title fw-bolder fs-8 px-2 py-1 ms-2'>
        {user?.timezone || ''} UTC{timezoneOffset} {currentTime}
        </span>

        <span className='badge profile-title fw-bolder fs-8 px-2 py-1 ms-2'>Mi perfil </span>
      </div>
      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          className={clsx('cursor-pointer symbol', userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          <img alt='Logo'
            src={user?.photo || toAbsoluteUrl('/media/avatars/blank.png')}
            style={{ maxWidth: '800px', height: 'auto', display: 'block', margin: '0 auto' }}
            onError={(e) => {
              e.currentTarget.onerror = null;
              e.currentTarget.src = toAbsoluteUrl('/media/avatars/blank.png');
            }} />
        </div>

        <HeaderUserMenu user={user} timezoneOffset={timezoneOffset} currentTime={currentTime}  />

      </div>

    </div>
  )
}

export { Navbar }
