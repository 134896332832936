import React, {useState, useEffect, useMemo} from 'react'
import {Link} from 'react-router-dom'
import {useIntl} from 'react-intl'
import {ITimelineItem} from './core/TimelineItem.Model'
import moment from 'moment'
import {KTSVG} from '../../../_metronic/helpers'

type Props = {
  data: ITimelineItem
  handleUpdateData: any
  timezone: string

}

const Timeline: React.FC<Props> = ({data, handleUpdateData, timezone}) => {


  const icons: any = {
    icon_user: '/media/icons/duotune/communication/com006.svg',
    icon_clients: '/media/icons/duotune/finance/fin006.svg',
    icon_products: '/media/icons/duotune/ecommerce/ecm009.svg',
    icon_reports: '/media/icons/duotune/general/gen032.svg',
    icon_groups: '/media/icons/duotune/communication/com014.svg',
    icon_status: '/media/icons/duotune/general/gen025.svg',
    icon_default: '/media/icons/duotune/art/art002.svg',
  }

  return (
    <div className='timeline-item'>
      <div className='timeline-line w-40px'></div>

      <div className='timeline-icon symbol symbol-circle symbol-40px me-4'>
        <div className='symbol-label bg-light'>
          <i className='ki-duotone ki-message-text-2 fs-2 text-gray-500'>
            <span className='path1'></span>
            <span className='path2'></span>
            <span className='path3'></span>
          </i>
          <KTSVG path={icons[data.iconModule]} className='svg-icon-2' />
        </div>
      </div>

      <div className='timeline-content mb-10 mt-n1'>
        <div className='pe-3 mb-5'>
          <div className='fs-5 fw-semibold mb-2'>{data.username}</div>

          <div className='d-flex align-items-center mt-1 fs-6'>
            <div className='text-muted me-2 fs-7'>
             {moment(data.date).tz(timezone).format('DD-MM-YYYY')} 
             
              
              </div>

            <div
              className='symbol symbol-circle symbol-25px'
              data-bs-toggle='tooltip'
              data-bs-boundary='window'
              data-bs-placement='top'
              aria-label='Nina Nilson'
              data-bs-original-title='Nina Nilson'
              data-kt-initialized='1'
            >
              {data.userPhoto ? (
                <img src={data.userPhoto} alt='img' />
              ) : (
                <img src='/media/avatars/blank.png' alt='img' />
              )}
            </div>
          </div>
        </div>

        <div className='overflow-auto pb-5'>
          <div className='d-flex align-items-center border border-dashed border-gray-300 rounded min-w-750px px-7 py-3 mb-5'>
            <div className='fs-5 text-dark text-hover-primary fw-semibold w-375px min-w-200px'>
              {data.action}
            </div>

            <div className='symbol-group symbol-hover flex-nowrap flex-grow-1 min-w-100px pe-2'>
              <div className='symbol symbol-circle symbol-25px'></div>
            </div>

            <button
              data-bs-toggle='modal'
              data-bs-target={`#requestModal`}
              className='btn btn-sm btn-light btn-active-light-primary rounded-pill'
              onClick={() => {
                handleUpdateData(data!)
              }}
            >
              Ver detalle
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export {Timeline}
