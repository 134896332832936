import React, { useCallback, useState } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { IReport } from './core/Report.Model';

type Props = {
    data: IReport[];
    currentPage: number;
    limit: number;
    timezone: string;
};

const ReportListBody: React.FC<Props> = React.memo(({ data, currentPage, limit, timezone }) => {
    const calculateIndex = useCallback((index: number) => {
        return (currentPage - 1) * limit + index + 1;
    }, [currentPage, limit]);




    return (
        <tbody>
            {data.map((item, index) => {
                return (
                    <tr key={item.id}>
                        <td style={{ width: '14ch' }}>{calculateIndex(index)}</td>
                        <td style={{ width: '14ch' }}>{item.id}</td>   
                        <td style={{ width: '14ch' }}>{item.name}</td>                   {/* Cliente */}
                        <td style={{ width: '14ch' }}>{item.provider}</td>               {/* Carrier */}
                        <td style={{ width: '14ch' }}>{item.sku}</td>                    {/* Producto */}
                        <td style={{ width: '14ch' }}>{item.service}</td>                {/* Denominación */}
                        <td style={{ width: '14ch' }}>******{item.reference}</td>
                        {/* Teléfono */}
                        <td style={{ width: '14ch', color: item.status === 200 ? 'green' : 'red' }}>
                            ${item.amountPayment}</td>            {/* Monto */}
                        <td style={{ width: '14ch' }}>
                            {item.status === 200 ? 'Exitosa' : 'Fallida'}
                        </td>
                        {/* Estatus */}
                        <td style={{ width: '14ch' }}>{item.noAut}</td>                  {/* No. Aut. */}
                        <td style={{ width: '14ch' }}>
                        {moment(item.createdAt).tz(timezone).format('DD-MM-YYYY HH:mm:ss')}
                    

                        </td>        {/* Fecha de transacción */}
                    </tr>
                );
            })}
        </tbody>


    );
});

export default ReportListBody;
