import React, { useState, useEffect, useMemo } from 'react'

import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { getTokenConfig } from '../../../../src/auth'
import axios from 'axios'
import { IProduct } from './core/Product.Model'
import Swal from 'sweetalert2'
import ProductListBody from './ProductListBody'
import Pagination from './Pagination'
import PermissionsService from '../auth/PermissionsService'


const ProductList: React.FC = () => {
  const intl = useIntl()
  let apiUrl = process.env.REACT_APP_API_URL;  //return axios.put(`${process.env.REACT_APP_API_URL}/groups/${id}`, data, config)
  const [data, setData] = useState<IProduct[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [totalPages, setTotalPages] = useState(0)

  const [searchValue, setSearchValue] = useState('')
  const [searchStatus, setSearchStatus] = useState('')
  const [orderType, setOrderType] = useState<string>('status,name,asc')
  const [searchInitiated, setSearchInitiated] = useState(false)

  const [orderDirection, setOrderDirection] = useState<'asc' | 'desc'>('asc')


  const permissions = PermissionsService.getPermissions();

  const {
    hasProductsDeactivatePermission,
    hasProductsActivatePermission,
    hasProductsDeletePermission,

  } = permissions || {};



  const handleUpdateStatus = async (productId: number, status: number) => {
    try {
      let statusId = Number(status)

      let title, confirmButtonText
      if (statusId === 1) {
        title = '¿Está seguro de que desea desactivar este producto?'
        confirmButtonText = 'Sí, desactivar'
      } else if (statusId === 2) {
        title = '¿Está seguro de que desea activar este producto?'
        confirmButtonText = 'Sí, activar'
      }

      const body = { customerId: productId }
      const config = await getTokenConfig()
      Swal.fire({
        title: title,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: confirmButtonText,
        cancelButtonText: 'Cancelar',
      }).then(async (result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: 'Espere por favor',
            text: 'Estamos actualizando el estado del producto...',
          })

          if (statusId === 1) {

            let queryString = `${apiUrl}/products/${productId}/unactive`;
            const response = await axios.post(
             queryString,
              body,
              config
            )
       
          } else {
            let queryString = `${apiUrl}/products/${productId}/active`;
            const response = await axios.post(
              queryString,
              body,
              config
            )
       
          }
          Swal.fire({
            title: 'Estado actualizado',
            icon: 'success',
          })

          handleSearch()
        }
      })
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: 'Ha ocurrido un error al actualizar el estado del producto.',
        icon: 'error',
      })
      return null
    }
  }

  const handleDelete = async (productId: number, status: number) => {
    try {
      const config = await getTokenConfig()
      let statusId = Number(status)

      if (statusId === 1) {
        Swal.fire({
          icon: 'warning',
          title: 'No se puede eliminar un producto activo',

          confirmButtonText: 'Aceptar',
        })

        return
      }
      Swal.fire({
        title: '¿Está seguro de que desea eliminar este producto?',
        text: 'Esta acción no se puede deshacer',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si, eliminar',
        cancelButtonText: 'Cancelar',
      }).then(async (result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: 'Espere por favor',
            text: 'Estamos eliminado el producto...',
          })

          const id = productId // objeto JSON que se enviará en el cuerpo de la solicitud

          let queryString = `${apiUrl}/products/${id}`;
          const response = await axios.delete(
            queryString,
            config
          )

          console.log(response)
          Swal.fire({
            title: 'Eliminado con éxito',
          })
          handleSearch()
          return response
        }
      })
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: 'Ha ocurrido un error al eliminar el producto.',
        icon: 'error',
      })

      return null
    }
  }

  const handleReset = async () => {
    setCurrentPage(1)
    await new Promise<void>((resolve) => {
      setSearchValue('')
      setSearchStatus('')
      resolve()
    })
  }

  useEffect(() => {
    if (searchValue === '' && searchStatus === '') {
      handleSearch()
    }
  }, [searchValue, searchStatus])

  const handleOrderChange = (newOrderType: string) => {
    setOrderType(newOrderType)

    setOrderDirection((prevDirection) => (prevDirection === 'asc' ? 'desc' : 'asc'))
  }

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      setError(null)

      try {
        const config = await getTokenConfig()
        let queryString = `${apiUrl}/products?limit=${limit}&page=${currentPage}`;
        
        if (orderType === 'status,name,asc') {
          queryString += `&order=status,name,${orderDirection}`
        } else if (orderType === 'id,asc') {
          queryString += `&order=id,${orderDirection}`
        } else if (orderType === 'providers,name,asc') {
          queryString += `&order=providers,name,${orderDirection}`
        } else if (orderType === 'categories,name,asc') {
          queryString += `&order=categories,name,${orderDirection}`
        } else if (orderType === 'name,asc') {
          queryString += `&order=name,${orderDirection}`
        }

        axios
          .get(queryString, config)
          .then((response) => {
            console.log(response)
            setData(response.data.data.products)
            setIsLoading(false)
            setTotalPages(Math.ceil(response.data.data.total / limit))
          })
          .catch((error) => {
            setError(error)
            setIsLoading(false)
          })
      } catch (err: any) {
        setError(err)
        setIsLoading(false)
      }
    }

    fetchData()
  }, [currentPage, limit, orderType, orderDirection, searchInitiated])

  const memoizedData = useMemo(() => data, [data])

  const handleSearch = async () => {
    setCurrentPage(1)
    setSearchInitiated(true)
    setIsLoading(true)
    setError(null)

    try {
      const config = await getTokenConfig()
      let queryString = `${apiUrl}//products?limit=${limit}&page=${currentPage}&search=${searchValue}&service_status_id=${searchStatus}&order=name,asc`;
      axios
        .get(
          queryString,
          config
        )
        .then((response) => {
          setData(response.data.data.products)
          setIsLoading(false)

          setTotalPages(Math.ceil(response.data.data.total / limit))
        })
        .catch((error) => {
          setError(error)
          setIsLoading(false)
        })
    } catch (err: any) {
      setError(err)
      setIsLoading(false)
    }
  }

  if (isLoading) {
    return <div>{intl.formatMessage({ id: 'PLEASE.WAIT' })}</div>
  }

  if (error) {
    return <div>Ocurrió un error</div>
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-body py-3 '>
        <div className='row mb-5'>
          <div className='col-lg-4 d-flex align-items-start flex-wrap'>
            <div className='w-100'>
              <label className='form-label'>Buscar</label>
              <input
                type='text'
                data-kt-user-table-filter='search'
                onChange={(event) => setSearchValue(event.target.value)}
                className='form-control form-control'
                value={searchValue}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    handleSearch()
                  }
                }}
              />
            </div>
          </div>
          <div className='col-lg-3'>
            <label className='form-label'>Estatus</label>
            <select
              value={searchStatus}
              onChange={(event) => setSearchStatus(event.target.value)}
              className='form-select form-select'
            >
              <option value=''>Selecciona un estatus</option>
              <option value={1}>Activo</option>
              <option value={2}>Inactivo</option>
            </select>
          </div>
          <div className='col-lg-5 align-bottom'>
            <div className='split-button'>
              <button onClick={handleSearch} className='btn btn-primary rounded-pill'>
                Buscar
              </button>
              <button onClick={handleReset} className='btn btn-secondary rounded-pill'>
                Borrar
              </button>
            </div>
          </div>
        </div>

        <div className='table-responsive mb-20'>
          {data.length !== 0 && (
            <table className='table table-striped' id='table'>
              <thead>
                <tr className='fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200'>
                  <th onClick={() => handleOrderChange('id,asc')} className='min-w-50px'>
                    #
                  </th>
                  <th
                    onClick={() => handleOrderChange('providers,name,asc')}
                    className='min-w-100px'
                  >
                    {intl.formatMessage({ id: 'PRODUCTS.SOCIAL.PARTNER' })}
                  </th>
                  <th
                    onClick={() => handleOrderChange('categories,name,asc')}
                    className='min-w-150px'
                  >
                    {intl.formatMessage({ id: 'PRODUCTS.PARENT.PRODUCT' })}
                  </th>
                  <th onClick={() => handleOrderChange('name,asc')} className='min-w-150px'>
                    {intl.formatMessage({ id: 'PRODUCTS.SUBPRODUCT' })}
                  </th>
                  <th onClick={() => handleOrderChange('status,name,asc')} className='min-w-150px'>
                    {intl.formatMessage({ id: 'PRODUCTS.STATUS' })}
                  </th>

                  {hasProductsDeletePermission && (
                    <th className='min-w-100px'>{intl.formatMessage({ id: 'PRODUCTS.ACTIONS' })}</th>
                  )}
                </tr>
              </thead>
              <ProductListBody
                data={data}
                handleUpdateStatus={handleUpdateStatus}
                handleDelete={handleDelete}

                hasProductsDeactivatePermission={hasProductsDeactivatePermission || false}
                hasProductsActivatePermission={hasProductsActivatePermission || false}
                hasProductsDeletePermission={hasProductsDeletePermission || false}

              />
            </table>
          )}
          <div className='d-flex justify-content-center'>
            {data.length === 0 && (
              <p className='mb-0'>
                <strong>No hay resultados disponibles</strong>
              </p>
            )}
          </div>
        </div>
        <div className='d-flex justify-content mb-4 same-col'>
          <div>
            <select
              value={limit}
              onChange={(event) => setLimit(Number(event.target.value))}
              className='form-select form-select-sm custom-select-page'
            >
              <option value={10}>10</option>
              <option value={15}>15</option>
              <option value={30}>30</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
          </div>
          <div>
            {memoizedData.length !== 0 && (
              <Pagination
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalPages={totalPages}
                limit={limit}
                lengthData={memoizedData.length}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export { ProductList }
