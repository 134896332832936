export interface IMoneyBag {
  typesMoneybagCustomersId: number;
  typesTransactionsMoneybagCustomerId: number;
  accountBank: string;
  typesPaymentsMoneybagCustomerId: number;
  amount: string;
  noAut: string;
  datePayment: Date;
  vaucher: string;
  note?: string; 
}

export const moneyBagInitValues: IMoneyBag = {
  typesMoneybagCustomersId: 0,
  typesTransactionsMoneybagCustomerId: 0,
  accountBank: '',
  typesPaymentsMoneybagCustomerId: 0,
  amount: '',
  noAut: '',
  datePayment: new Date(), // You might want to provide a default date if necessary
  vaucher: '',
  note: '' // Optional field, you can remove it if you don't want an initial value
}


export interface TransactionsMoneybagCustomer {
  id: number;
  name: string;
}

export interface ITypesMoneybagCustomers {
  id: number;
  name: string;
}

export interface ITypesPaymentsMoneybagCustomer {
  id: number;
  name: string;
}