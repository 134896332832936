import {Navigate, Routes, Route, Outlet, useParams,  useLocation, useNavigate } from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {useIntl} from 'react-intl'
import {ReferenceList} from './ReferenceList'
import {ReferenceDetail} from './ReferenceDetail'

const ServicesPage = () => {
  const intl = useIntl()

  const location = useLocation();


  const userBreadCrumbs: Array<PageLink> = [
    {
      title: 'Referencias',
      path: 'references/list/',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='list/:customerId'
          element={
            <>
              <ReferenceList />
              <PageTitle breadcrumbs={userBreadCrumbs}>
                {intl.formatMessage({id: 'SERVICES.REFERENCES'})}
              </PageTitle>
            </>
          }
        />

        <Route
          path='detail/:reference/:customerId'
          element={
            <>
              <ReferenceDetail />
              <PageTitle breadcrumbs={userBreadCrumbs}>
                {intl.formatMessage({id: 'SERVICES.REFERENCE_DETAILS'})}
              </PageTitle>
            </>
          }
        />
      </Route>

      <Route index element={<Navigate to='list' />} />
    </Routes>
  )
}

export default ServicesPage
