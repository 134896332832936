import React, { useState, useContext } from "react";
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";

import UserPool from "../../../../UserPool";
import { Link, Navigate, Route } from 'react-router-dom'
import { PrivateRoutes } from "../../../routing/PrivateRoutes";


import { useIntl } from 'react-intl'
import { fetchLastLogin } from "./../core/apis"


interface IProps {
  onSuccess: (data: any) => void;
  onFailure: (err: any) => void;
}

const Login: React.FC<IProps> = (props) => {
  const intl = useIntl()
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {

      setIsLoading(true);

      const user = new CognitoUser({
        Username: username,
        Pool: UserPool,
      });

      const authDetails = new AuthenticationDetails({
        Username: username,
        Password: password,
      });

      user.authenticateUser(authDetails, {
        onSuccess: async (data) => {

          const cognito_user_id = authDetails.getUsername();
          const token = data.getIdToken().getJwtToken();
          await fetchLastLogin(cognito_user_id, token);

          props.onSuccess(data);
          setIsLoading(false);
          return (
            <>
              <Route path='/*' element={<PrivateRoutes />} />
              <Route index element={<Navigate to='/blank' />} />
            </>

          )

        },
        onFailure: (err) => {
          props.onFailure(err);
          console.log(err.code)
          if (err.message === 'Incorrect username or password.') {
            setError(intl.formatMessage({ id: 'AUTH.VALIDATION.INVALID_LOGIN' }));
          } else {
            setError(intl.formatMessage({ id: 'AUTH.VALIDATION.INVALID_LOGIN_OTHER' }));
          }

          setIsLoading(false);
          <Link to='/error' />
        },
        newPasswordRequired: (data) => {
          console.log("New Password required, ", data);
        },
      });


    } catch (err) {
      props.onFailure(err);
      //setError(err.message);
    }
  };

  return (
    <form
      className='form w-100'
      onSubmit={handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='mb-11'>
        <h1 className='text-dark fw-bolder mb-3'>{intl.formatMessage({ id: 'AUTH.GENERAL.SUBMIT_LOGIN' })}</h1>
        <p>Ingresa la siguiente información para poder iniciar sesión</p>

      </div>

      {error && (
        <div className='text-center mb-11'>
          <h4 className='text-danger'>{error}</h4>
        </div>
      )}
      {/* begin::Heading */}


      {/* end::Login options */}


      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-label fs-6 fw-bolder text-dark'>{intl.formatMessage({ id: 'AUTH.INPUT.EMAIL' })}</label>
        <input
          placeholder={intl.formatMessage({ id: 'AUTH.INPUT.EMAIL' })}

          className={'form-control bg-transparent'}
          type='email'
          name='username'
          id="username"
          value={username}
          onChange={(event) => setUsername(event.target.value)}
          autoComplete='off'
        />

        <div className='fv-plugins-message-container'>
          <span role='alert'></span>
        </div>

      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-3'>
        <label className='form-label fw-bolder text-dark fs-6 mb-0'> {intl.formatMessage({ id: 'AUTH.INPUT.PASSWORD' })}</label>
        <input
          type='password'
          autoComplete='off'
          id="password"
          value={password}
          onChange={(event) => setPassword(event.target.value)}
          className={'form-control bg-transparent'}
        />

        <div className='fv-plugins-message-container'>
          <div className='fv-help-block'>
            <span role='alert'></span>
          </div>
        </div>

      </div>
      {/* end::Form group */}

      {/* begin::Wrapper */}
      <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
        <div />

        {/* begin::Link */}
        <Link to='/auth/forgot-password' className='link-primary'>
          {intl.formatMessage({ id: 'AUTH.FORGOT.TITLE' })}
        </Link>
        {/* end::Link */}
      </div>
      {/* end::Wrapper */}

      {/* begin::Action */}
      <div className='d-grid mb-10'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-primary rounded-pill'
          disabled={isLoading}
        >
          <span className='indicator-label'>
            {isLoading ? 'Cargando...' : intl.formatMessage({ id: 'AUTH.GENERAL.SUBMIT_CONTINUE' })}
          </span>
        </button>

      </div>
      {/* end::Action */}


    </form>
  );
};




export { Login };