// SearchFilters.tsx

import React from 'react';
import { useIntl } from 'react-intl';

import { ICustomer, IOperator } from './core/Report.Model'



interface Props {
    searchValue: string;
    setSearchValue: (value: string) => void;

    searchCustomer: string;
    setSearchCustomer: (value: string) => void;


    searchStatus: string;
    setSearchStatus: (value: string) => void;


    searchOperator: string;
    setSearchOperator: (value: string) => void;



    datePaymentInit: string;
    setDatePaymentInit: (value: string) => void;

    datePaymentEnd: string;
    setDatePaymentEnd: (value: string) => void;


    operators: IOperator[];

    customers: ICustomer[];

    handleSearch: () => void;
    handleReset: () => void;

    handleDownloadExcel: () => void;
    handleDownloadConsolidado: () => void;
}

const SearchFilters: React.FC<Props> = (props) => {
    const intl = useIntl();
    // De-structure props for better readability
    const {
        searchValue, setSearchValue,
        searchCustomer, setSearchCustomer,

        searchStatus, setSearchStatus,

        datePaymentInit, setDatePaymentInit,
        datePaymentEnd, setDatePaymentEnd,
        customers,
        handleSearch, handleReset, handleDownloadExcel,
        handleDownloadConsolidado
    } = props;



    return (
        <>


            <div className='row flex-wrap mb-5'>
                <div className='col-lg-3 col-md-6 col-sm-12 mb-3'>
                    <div className='form-group form-label-group'>
                        <label className='form-label'>Buscar</label>
                        <input
                            type='text'
                            data-kt-user-table-filter='search'
                            onChange={(event) => setSearchValue(event.target.value)}
                            className='form-control form-control form-control-lg'
                            value={searchValue}
                            onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                    handleSearch()
                                }
                            }}
                        />
                    </div>
                </div>

                <div className='col-lg-3 col-md-6 col-sm-12 mb-3'>
                    <div className='form-group form-label-group'>
                        <label className='form-label'>
                            {intl.formatMessage({ id: 'REPORT.PARTNER.C' })}</label>
                        <select
                            value={searchCustomer}
                            onChange={(event) => setSearchCustomer(event.target.value)}
                            className='form-select form-select-solid form-control-lg'
                        >
                            <option value=''>Selecciona un socio comercial</option>
                            {customers.map((customer) => (
                                <option key={customer.id} value={customer.id}>
                                    {customer.name}
                                </option>
                            ))}
                        </select>

                    </div>
                </div>

              


                <div className='col-lg-3 col-md-6 col-sm-12 mb-3'>
                    <div className='form-group form-label-group'>
                        <label className='form-label'>Estatus</label>
                        <select
                            value={searchStatus}
                            onChange={(event) => setSearchStatus(event.target.value)}
                            className='form-select form-select form-control-lg'
                        >
                            <option value=''>Selecciona un estatus</option>
                            <option value='200'>Exitosa</option>
                        </select>
                    </div>
                </div>






                <div className='col-lg-3 col-md-6 col-sm-12 mb-3'>
                    <div className='form-group form-label-group'>
                        <label className='form-label'>Fecha de inicio</label>
                        <input
                            type='date'
                            value={datePaymentInit}
                            onChange={(event) => setDatePaymentInit(event.target.value)}
                            className='form-control form-control form-control-lg'
                        />
                    </div>
                </div>


                
                <div className='col-lg-3 col-md-6 col-sm-12 mb-3'>
                    <div className='form-group form-label-group'>
                        <label className='form-label'>Fecha fin</label>
                        <input
                            type='date'
                            value={datePaymentEnd}
                            onChange={(event) => setDatePaymentEnd(event.target.value)}
                            className='form-control form-control form-control-lg'
                        />
                    </div>

                </div>



            </div>
            <div className='row  mb-5'>


                <div className='col-lg-12 col-md-12 col-sm-12 align-bottom justify-content-end'>
                    <div className='form-group d-flex justify-content-end'>

                        <button onClick={handleSearch} className='btn btn-primary rounded-pill mx-1'>
                            Buscar
                        </button>
                        <button onClick={handleReset} className='btn btn-secondary rounded-pill mx-1'>
                            Borrar
                        </button>

                        <button onClick={handleDownloadExcel} className='btn btn-secondary rounded-pill mx-1'>
                            Descargar reporte
                        </button>

                        <button onClick={handleDownloadConsolidado} className='btn btn-secondary rounded-pill'>
                            Descargar reporte consolidado
                        </button>

                    </div>
                </div>
            </div>
        </>
    );
}

export default SearchFilters;
