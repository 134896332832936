import axios, { AxiosResponse } from 'axios';
import { getTokenConfig } from '../../../../../src/auth'
interface VerifierResponse {
    signedUrl: string;
    // Puedes agregar más propiedades aquí si el verificador devuelve más información relevante.
}



export const fetchRequests = async (
    config: any,
    limit: number,
    currentPage: number,
    searchValue: string,
    customerId?: string,
    createdAtDateInit?: string,
    createdAtDateEnd?: string,
    datePaymentInit?: string,
    datePaymentEnd?: string,
    typesMoneybagCustomersId?: string,
    typesTransactionsMoneybagCustomerId?: string,
    orderType?: string,
    orderDirection?: string,
 
) => {

    try {
        let queryString = `${process.env.REACT_APP_API_URL_RECHARGES}/passport/report?limit=${limit}&page=${currentPage}`;


        if (orderType === 'nameCustomer,asc') {
            queryString += `&order=nameCustomer,${orderDirection}`;
        } else if (orderType === 'typesTransactionsMoneybagCustomerId,asc') {
            queryString += `&order=typesTransactionsMoneybagCustomerId,${orderDirection}`;
        } else if (orderType === 'typesTransactionsMoneybagCustomerId,asc') {
            queryString += `&order=typesTransactionsMoneybagCustomerId,${orderDirection}`;
        } else if (orderType === 'accountBank,asc') {
            queryString += `&order=accountBank,${orderDirection}`;
        } else if (orderType === 'typesMoneybagCustomerId,asc') {
            queryString += `&order=typesMoneybagCustomerId,${orderDirection}`;
        } else if (orderType === 'amount,asc') {
            queryString += `&order=amount,${orderDirection}`;
        } else if (orderType === 'noAut,asc') {
            queryString += `&order=noAut,${orderDirection}`;
        } else if (orderType === 'datePayment,asc') {
            queryString += `&order=datePayment,${orderDirection}`;
        } else if (orderType === 'note,asc') {
            queryString += `&order=note,${orderDirection}`;
        } else if (orderType === 'createdBy,asc') {
            queryString += `&order=createdBy,${orderDirection}`;
        }



        if (searchValue) {
            queryString += `&search=${searchValue}`;
        }

        if (createdAtDateInit) {
            queryString += `&createdAtDateInit=${createdAtDateInit}`;
        }

        if (createdAtDateEnd) {
            queryString += `&createdAtDateEnd=${createdAtDateEnd}`;
        }

        if (datePaymentInit) {
            queryString += `&datePaymentInit=${datePaymentInit}`;
        }

        if (datePaymentEnd) {
            queryString += `&datePaymentEnd=${datePaymentEnd}`;
        }


        if (customerId) { // agregar el filtro por cadena
            queryString += `&customerId=${customerId}`;
        }

        if (typesMoneybagCustomersId) { // agregar el filtro por cadena
            queryString += `&typesMoneybagCustomersId=${typesMoneybagCustomersId}`;
        }



        if (typesTransactionsMoneybagCustomerId) { // agregar el filtro por cadena
            queryString += `&typesTransactionsMoneybagCustomerId=${typesTransactionsMoneybagCustomerId}`;
        }



        const response = await axios.get(queryString, config);


        const transactions = response.data.data.transactions;
        const totalAbonos = response.data.data.totalPayments;

        const totalCargos = response.data.data.totalCharges;
        const totalAmount= response.data.data.totalAmount;
        const totalConsumed= response.data.data.totalConsumed;
     
        const timezone = response.data.data.timezone;
        const totalPages = Math.ceil(response.data.data.total / limit);

        return { transactions, totalPages, totalAbonos, totalCargos,
        totalConsumed, totalAmount, timezone };
    } catch (err) {
        throw err;
    }
};


export const fetchCatalogs = async () => {
    try {
        let queryString = `${process.env.REACT_APP_API_URL_RECHARGES}/transactions`;

        const config = await getTokenConfig(); // Assuming getTokenConfig is a function that returns a config object for axios
        const response = await axios.get(queryString, config);



        const transactions = response.data.data.transactions;
        const types = response.data.data.types;
        const paymentTypes = response.data.data.paymentTypes;

        const totalCharges = response.data.data.totalCharges;
        const totalPayments = response.data.data.totalPayments;

        return { transactions, types, paymentTypes, totalCharges,totalPayments };
    } catch (err) {
        throw err;
    }
};

export const fetchCustomers = async (
    config: any
) => {
    try {
        let queryString = `${process.env.REACT_APP_API_URL}/admin/list-customers`;
        const response = await axios.get(queryString, config);

        const customers = response.data.data.customers;

        return { customers };
    } catch (err) {
        throw err;
    }
};


export const downloadImage = async (config: any, nameReport: string): Promise<string | null> => {

    try {
        const verifyUrl = `${process.env.REACT_APP_API_URL_RECHARGES}/download/image`;
        const response: AxiosResponse<VerifierResponse> = await axios.get(verifyUrl, {
            ...config,
            params: {
                filename: nameReport
            }
        });


        console.log(response.data.signedUrl); // Verifica el resultado del verificador en la consola

        const signedUrl = response.data.signedUrl;
        return signedUrl || null; // Devuelve el signedUrl si está presente, o null si no lo está.
    } catch (error) {
        console.error("Error al verificar si el archivo está listo:", error);
        return null;
    }
};


export const fetchExcelReport = async (config: any,
    searchValue: string,
    customerId: string,
    createdAtDateInit: string,
    createdAtDateEnd: string,
    datePaymentInit: string,
    datePaymentEnd: string,
    typesMoneybagCustomersId: string,
    typesTransactionsMoneybagCustomerId: string,
    orderType: string,
) => {

    try {
        const baseUrl = `${process.env.REACT_APP_API_URL_RECHARGES}/download/passport/report?`;

        // Definir los parámetros en un objeto para facilitar la construcción dinámica
        const queryParams = {
            search: searchValue,
            customerId: customerId,
            createdAtDateInit: createdAtDateInit,
            createdAtDateEnd: createdAtDateEnd,
            datePaymentInit: datePaymentInit,
            datePaymentEnd: datePaymentEnd,
            typesMoneybagCustomersId: typesMoneybagCustomersId,
            typesTransactionsMoneybagCustomerId: typesTransactionsMoneybagCustomerId,
            orderType: orderType,
            orderDirection: orderType,
          
        };

        // Filtrar y construir la cadena de consulta
        const queryString = baseUrl + Object.entries(queryParams)
            .filter(([key, value]) => value && value !== "")
            .map(([key, value]) => `${key}=${value}`)
            .join("&");

        const response = await axios.get(queryString, config);
        const nameReport = response.data.data.name;

        if (!nameReport) {
            console.error("Error al obtener el nombre del archivo.");
            return null;
        }

        let signedUrl: string | null = null;
        const interval = 5000; // Intervalo en milisegundos (en este caso, 5 segundos)
        const maxAttempts = 36; // Número máximo de intentos (12 x 5 segundos = 1 minuto)

        let attempts = 0;
        while (!signedUrl && attempts < maxAttempts) {
            signedUrl = await verifyFileReady(config, nameReport);
            if (!signedUrl) {
                await new Promise((resolve) => setTimeout(resolve, interval)); // Esperar el intervalo antes de verificar nuevamente.
                attempts++;
            }
        }

        return signedUrl;
    } catch (error) {
        console.error("Error al obtener el nombre del archivo:", error);
        return null;
    }
};


const verifyFileReady = async (config: any, nameReport: string): Promise<string | null> => {
    try {
        const verifyUrl = `${process.env.REACT_APP_API_URL_RECHARGES}/download/passport/report?mode=check&filename=${nameReport}`;

        console.log(config)
        const response: AxiosResponse<VerifierResponse> = await axios.get(verifyUrl, config);

        console.log(response.data); // Verifica el resultado del verificador en la consola

        const signedUrl = response.data.signedUrl;
        return signedUrl || null; // Devuelve el signedUrl si está presente, o null si no lo está.
    } catch (error) {
        console.error("Error al verificar si el archivo está listo:", error);
        return null;
    }
};


export const uploadDirectly = async (file: any, transactionId: number) => {
    const config = await getTokenConfig();

    const base64File = await toBase64(file);



    const url = `${process.env.REACT_APP_API_URL_RECHARGES}/upload/image`;
    try {
        const response = await axios({
            method: 'post',
            url: url,
            data: {
                transactionId: transactionId,
                fileName: file.name,
                fileType: file.type,
                fileContent: base64File
            },
            headers: {
                ...config.headers,
                'Content-Type': 'application/json'
            },
        });

        // Si estás esperando alguna respuesta específica desde Lambda, puedes procesarla aquí.
        return response.data.fileUrl;

    } catch (err: any) {
        throw err;
    }
};


function toBase64(file: any): Promise<string> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            if (typeof reader.result === 'string') {
                resolve(reader.result.split(',')[1]); // Remove the "data:" prefix
            } else {
                reject(new Error('Unexpected result format'));
            }
        };
        reader.onerror = error => reject(error);
    });
}

  