import React, { useState, useEffect, useMemo } from 'react'

import { useIntl } from 'react-intl'
import { getTokenConfig } from '../../../../src/auth'

import {
  IReport, ICategory
} from './core/Report.Model'


import { fetchCatalogs, fetchCustomers, fetchExcelConsolidate, fetchExcelReport, fetchRequests, } from './apis/requestsApi'

import Swal, { SweetAlertOptions } from 'sweetalert2'
import { ICustomer } from '../reportsValidateIdentity/core/Report.Model'

import SearchFilters from './SearchFilters';
import ResultsTable from './ResultsTable';


const ReportList: React.FC = () => {
  const intl = useIntl()

  const [data, setData] = useState<IReport[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [totalPages, setTotalPages] = useState(0)

  const [searchValue, setSearchValue] = useState('')
  const [searchCustomer, setSearchCustomer] = useState('')

  const [searchProvider, setSearchProvider] = useState('')

  const [searchStatus, setSearchStatus] = useState('')
  const [orderType, setOrderType] = useState<string>('status,name,asc')

  const [orderDirection, setOrderDirection] = useState<'asc' | 'desc'>('asc')

  const [categories, setCategories] = useState<ICategory[]>([]);




  const [customers, setCustomer] = useState<ICustomer[]>([])
  const [datePaymentInit, setDatePaymentInit] = useState('')
  const [datePaymentEnd, setDatePaymentEnd] = useState('')


  const [searchTypesTransactions, setTypesTransactions] = useState('')
  const [searchTypesPayments, seTypesPayments] = useState('')
  const [timezone, setTimezone] = useState('');

  interface CustomSweetAlertOptions extends SweetAlertOptions {
    onOpen?: () => void
  }

  const handleReset = async () => {
    setCurrentPage(1)
    await new Promise<void>((resolve) => {
      setSearchValue('')
      setSearchStatus('')
      setSearchProvider('')
      setDatePaymentInit('')
      setDatePaymentEnd('')
      setTypesTransactions('')
      seTypesPayments('')
      setSearchCustomer('')
      resolve()
    })
  }
  useEffect(() => {
    if (
      searchValue === '' &&
      searchStatus === '' &&
      datePaymentInit === '' &&
      datePaymentEnd === '' &&
      searchTypesTransactions === '' &&
      searchTypesPayments === '' &&
      searchProvider === '' &&
      searchCustomer === '' &&
      searchProvider === ''
    ) {
      handleSearch()
    }
  }, [searchValue, searchStatus, searchProvider,
    searchTypesTransactions, searchTypesPayments,
    searchCustomer, searchProvider])

  const handleOrderChange = (newOrderType: string) => {
    setOrderType(newOrderType)

    setOrderDirection((prevDirection) => (prevDirection === 'asc' ? 'desc' : 'asc'))
  }




  useEffect(() => {

    const fetchData = async () => {
      setIsLoading(true); // Establecer isLoading como true al iniciar la carga de datos
      setError(null)
      try {
        const config = await getTokenConfig()
      
        const { transactions, totalPages, timezone } = await fetchRequests(
          config,
          limit,
          currentPage,
          searchValue,
          searchStatus,
          searchCustomer,
          searchProvider,
          datePaymentInit,
          datePaymentEnd,
          orderType,
          orderDirection,

        )
    
        setData(transactions)
        setTimezone(timezone)
        setTotalPages(totalPages)
        setIsLoading(false)

      } catch (err: any) {
        setError(err)
        setIsLoading(false)
      }
    }

    fetchData()
  }, [currentPage, limit, orderType, orderDirection])

  useEffect(() => {

    const fetchData = async () => {

      setError(null)
      try {
        const config = await getTokenConfig()

        const { customers } = await fetchCustomers(config);
        const { listCategories } = await fetchCatalogs()


        // Mapea las categorías a la estructura deseada
        // Mapea y ordena las categorías alfabéticamente
        const mappedCategories: ICategory[] = listCategories
          .sort((a: string, b: string) => a.localeCompare(b))
          .map((categoryName: string) => {
            return { name: categoryName };
          });
        setCategories(mappedCategories)
        setCustomer(customers);

      } catch (err: any) {
        setError(err)

      }
    }

    fetchData()
  }, []) // Arreglo de dependencias vacío


  const handleDownloadExcel = async () => {
    try {

      Swal.fire({
        html: `
        <div class="spinner-container" style="overflow: hidden;>
            <i class="fas fa-spinner fa-spin"></i>
            <span>Generando reporte...</span>
          </div>
        `,
        showConfirmButton: false,
        allowOutsideClick: false,
      } as CustomSweetAlertOptions)

      const config = await getTokenConfig()
      const url = await fetchExcelReport(
        config,
        searchValue,
        searchStatus,
        searchCustomer,
        searchProvider,
        datePaymentInit,
        datePaymentEnd,
        orderType,
     

      )


      if (url !== null) {
        const link = document.createElement('a');
        link.href = url;
        link.download = 'customer_score_report.csv';
        link.click();
      } else {
        console.error("No se pudo obtener la URL del reporte o el reporte aún no está listo.");
      }


      Swal.close()
    } catch (err: any) {
      Swal.close()
      console.error(err)
    }
  }




  //Handle consolidado
  const handleDownloadConsolidado = async () => {
    try {

      Swal.fire({
        html: `
        <div class="spinner-container" style="overflow: hidden;>
            <i class="fas fa-spinner fa-spin"></i>
            <span>Generando reporte...</span>
          </div>
        `,
        showConfirmButton: false,
        allowOutsideClick: false,
      } as CustomSweetAlertOptions)

      const config = await getTokenConfig()
      const url = await fetchExcelConsolidate(
        config,
        searchValue,
        searchStatus,
        searchCustomer,
        searchProvider,
        datePaymentInit,
        datePaymentEnd,
        orderType,
     

      )


      if (url !== null) {
        const link = document.createElement('a');
        link.href = url;
        link.download = 'customer_score_report.csv';
        link.click();
      } else {
        console.error("No se pudo obtener la URL del reporte o el reporte aún no está listo.");
      }


      Swal.close()
    } catch (err: any) {
      Swal.close()
      console.error(err)
    }
  }



  const memoizedData = useMemo(() => data, [data])
  const handleSearch = async () => {
    setCurrentPage(1)

    setIsLoading(true)
    setError(null)

    try {
      const config = await getTokenConfig()
      const { transactions, totalPages, timezone } = await fetchRequests(
        config,
        limit,
        currentPage,
        searchValue,
        searchStatus,
        searchCustomer,
        searchProvider,
        datePaymentInit,
        datePaymentEnd,
        orderType,
        orderDirection,
     
      )
      setData(transactions)
      setTimezone(timezone)
      setIsLoading(false)
      setTotalPages(totalPages)



    } catch (err: any) {
      setError(err)
      setIsLoading(false)
    }
  }


  if (error) {
    return <div>Ocurrió un error</div>
  }
  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-body py-3 '>
        <SearchFilters
          searchValue={searchValue}
          setSearchValue={setSearchValue}

          searchCustomer={searchCustomer}
          setSearchCustomer={setSearchCustomer}

          setSearchStatus={setSearchStatus}
          searchStatus={searchStatus}


          searchProvider={searchProvider}
          setSearchProvider={setSearchProvider}


          datePaymentInit={datePaymentInit}
          setDatePaymentInit={setDatePaymentInit}

          datePaymentEnd={datePaymentEnd}
          setDatePaymentEnd={setDatePaymentEnd}


          categories={categories}
          customers={customers}

          handleSearch={handleSearch}
          handleReset={handleReset}

          handleDownloadExcel={handleDownloadExcel}
          handleDownloadConsolidado={handleDownloadConsolidado}

        />

        <ResultsTable
          data={data}
          handleOrderChange={handleOrderChange}
          isLoading={isLoading}
          currentPage={currentPage}
          limit={limit}
          setLimit={setLimit}
          totalPages={totalPages}
          memoizedData={memoizedData}
          setCurrentPage={setCurrentPage}
          timezone={timezone}
        />


      </div>
    </div>
  )
}

export { ReportList }
