import { Navigate, Routes, Route, Outlet } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import { ServiceConfig } from './ServiceConfig'
import PermissionsService from '../auth/PermissionsService';
import { ServiceConfigLimitSMS } from './ServiceConfigLimitSMS';



const customerBreadCrumbs: Array<PageLink> = [
  {
    title: 'Configuración de servicios ',
    path: '/services/config',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const permissions = PermissionsService.getPermissions();
const {
  hasProductsConfigureChainsPermission,
  hasConfigLimitSMS
} = permissions || {};



const ServicePage = () => (

  <Routes>
    <Route element={<Outlet />}>

      {hasProductsConfigureChainsPermission ? (

        <Route
          path='config'
          element={
            <>
              <ServiceConfig />
              <PageTitle breadcrumbs={customerBreadCrumbs}>Configuración de servicios  </PageTitle>
            </>
          }
        />

      ) : (
        <Route path='list' element={<Navigate to='/error/403' replace />} />
      )}

      {hasConfigLimitSMS ? (
        <Route
          path='SMS'
          element={
            <>
              <ServiceConfigLimitSMS />
              <PageTitle breadcrumbs={customerBreadCrumbs}>Configurar alertas de créditos SMS </PageTitle>
            </>
          }
        />
      ) : (
        <Route path='list' element={<Navigate to='/error/403' replace />} />
      )}

      
      <Route
        path='config'
        element={
          <>
            <ServiceConfig />
            <PageTitle breadcrumbs={customerBreadCrumbs}>Configuración de servicios  </PageTitle>
          </>
        }
      />

      <Route index element={<Navigate to='config' />} />


    </Route>
  </Routes>
)



export default ServicePage
