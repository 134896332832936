import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl'
import { configDesc, updateRechargeConfig } from './apis/requestsApi'
import Swal from 'sweetalert2';
import { useParams } from "react-router-dom";
type Recharge = {
    id: number;
    operator: string;
    brand: string;
    denomination: number;
    totalDisccount: number;
    totalDisccountProvider: number;
    totalDisccountCustomer: number;
    quickSelect: boolean;
    visible: boolean | null; // Añadido el tipo null
    operators: string;
};

interface ValidationResult {
    isValid: boolean;
    message?: string;
}


const RechargesDescConfig: React.FC = () => {


    const [recharges, setRecharges] = useState<Recharge[]>([]);
    const [uniqueBrands, setUniqueBrands] = useState<string[]>([]);
    const [filteredRecharges, setFilteredRecharges] = useState<Recharge[]>([]);
    const [error, setError] = useState<Error | null>(null);
    const [selectedBrand, setSelectedBrand] = useState<string>("");
    const [totalDisccountValues, setTotalDisccountValues] = useState<Record<number, string | null>>({});

    const [totalDisccountProviderValues, setTotalDisccountProviderValues] =
        useState<Record<string, string>>({});
    const [totalDisccountCustomerValues, setTotalDisccountCustomerValues] =
        useState<Record<string, string>>({});
    const [quickSelectValues, setQuickSelectValues] = useState<Record<number, boolean>>({});
    const [visibleValues, setVisibleValues] = useState<Record<number, boolean>>({});


    const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(false);


    useEffect(() => {
        const fetchData = async () => {
            setError(null)
            try {
                const { recharges } = await configDesc()
                setRecharges(recharges);
                const brandSet = new Set(recharges.map((item: { brand: any; }) => item.brand));
                const brands = Array.from(brandSet) as string[]; // Asumiendo que las marcas son strings
                setUniqueBrands(brands);
                setSelectedBrand(brands[0]); // Asume que quieres seleccionar la primera marca por defecto

            } catch (err) {
                setError(err as Error);
            }
        }
        fetchData();
    }, []);


    useEffect(() => {
        const filtered = recharges.filter(r => r.brand === selectedBrand);
        const sorted = filtered.sort((a, b) => a.denomination - b.denomination);
        setFilteredRecharges(sorted);
    }, [selectedBrand, recharges]);


    const handleSend = () => {

        if (!validateAllDiscounts()) {
            // Si la validación es falsa, se muestra una alerta y se detiene el proceso aquí.
            return;
        }
        const relevantFields = filteredRecharges.map(recharge => ({
            id: recharge.id,
            totalDisccount: parseFloat(totalDisccountValues[recharge.id] ?? recharge.totalDisccount.toString()),
            totalDisccountProvider: parseFloat(totalDisccountProviderValues[recharge.id] || recharge.totalDisccountProvider.toString()),
            totalDisccountCustomer: parseFloat(totalDisccountCustomerValues[recharge.id] || recharge.totalDisccountCustomer.toString()),
            quickSelect: quickSelectValues[recharge.id] ?? recharge.quickSelect,
            visible: visibleValues[recharge.id] ?? recharge.visible
        }));


        const formattedData = {
            recharges: relevantFields
        };

        // Muestra SweetAlert de carga
        Swal.fire({
            html: `
            <div class="spinner-container" style="overflow: hidden;">
                <i class="fas fa-spinner fa-spin"></i>
                <span>Actualizando configuración...</span>
            </div>
            `,
            showConfirmButton: false,
            allowOutsideClick: false,
        });

        updateRechargeConfig(formattedData)
            .then(response => {
                console.log('Updated successfully:', response);

                // Cierra el SweetAlert de carga
                Swal.close();

                // Muestra mensaje de éxito
                Swal.fire({
                    icon: 'success',
                    title: '¡Actualizado!',
                    text: 'La actualización se completó con éxito.'
                });
            })
            .catch(error => {
                console.error('Failed to update:', error);

                // Cierra el SweetAlert de carga y muestra mensaje de error
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Error al actualizar. Por favor intenta de nuevo.'
                });
            });
    }



    const isValidTotalDiscount = (
        recharge: Recharge,
        totalDisccountProvider: string,
        totalDisccountCustomer: string
    ): ValidationResult => {
        const providerValue = parseFloat(totalDisccountProvider) || 0;
        const customerValue = parseFloat(totalDisccountCustomer) || 0;
        const totalDiscount = (providerValue + customerValue).toFixed(2);

        // Asegúrate de que totalDisccount es un número antes de llamar a toFixed
        const totalDisccount = typeof recharge.totalDisccount === 'number'
            ? recharge.totalDisccount
            : parseFloat(recharge.totalDisccount);
        const expectedDiscount = totalDisccount.toFixed(2);

        if (totalDiscount === expectedDiscount) {
            return { isValid: true };
        } else {
            const discountDifference = Math.abs(parseFloat(expectedDiscount) - parseFloat(totalDiscount)).toFixed(2);

            const message = parseFloat(totalDiscount) > parseFloat(expectedDiscount)
                ? `La suma de los descuentos en la recarga de ${recharge.denomination} excede el descuento permitido por ${discountDifference}.`
                : `La suma de los descuentos en la recarga de ${recharge.denomination} es menor que el descuento permitido por ${discountDifference}.`;
            return { isValid: false, message };
        }
    };



    const validateAndSetTotalDisccount = (recharge: Recharge, value: string | null) => {
        if (value === "") {
            setTotalDisccountValues(prev => ({ ...prev, [recharge.id]: "" }));
            return;
        }

        // Aquí puedes agregar lógica adicional para validar el valor si es necesario

        setTotalDisccountValues(prev => ({ ...prev, [recharge.id]: value }));
    };



    const validateAndSet = (
        recharge: Recharge,
        type: 'totalDisccountProvider' | 'totalDisccountCustomer',
        value: string | null,
        setTotalDisccountProviderValues: React.Dispatch<React.SetStateAction<Record<string, string>>>,
        setTotalDisccountCustomerValues: React.Dispatch<React.SetStateAction<Record<string, string>>>,
        totalDisccountProviderValues: Record<string, string>,
        totalDisccountCustomerValues: Record<string, string>
    ): void => {
        const otherValue = type === 'totalDisccountProvider'
            ? totalDisccountCustomerValues[recharge.id] || recharge.totalDisccountCustomer.toString()
            : totalDisccountProviderValues[recharge.id] || recharge.totalDisccountProvider.toString();

        const adjustedValue = value || "0";

        const validationResult = isValidTotalDiscount(
            recharge,
            type === 'totalDisccountProvider' ? adjustedValue : otherValue,
            type === 'totalDisccountCustomer' ? adjustedValue : otherValue
        );

        if (!validationResult.isValid) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: validationResult.message, // Utiliza el mensaje de la validación
            });
            return;
        }

        if (type === 'totalDisccountProvider') {
            setTotalDisccountProviderValues(prev => ({ ...prev, [recharge.id]: adjustedValue }));
        } else {
            setTotalDisccountCustomerValues(prev => ({ ...prev, [recharge.id]: adjustedValue }));
        }
        validateAllDiscounts()
    };




    const handleBrandChange = (brand: string) => {
        setSelectedBrand(brand);
    }

    const validateAllDiscounts = (): boolean => {

        console.log(filteredRecharges, 'RECARGAS FILTRADAS')
        for (const recharge of filteredRecharges) {
            const totalDiscountProvider = totalDisccountProviderValues[recharge.id] || recharge.totalDisccountProvider.toString();
            const totalDiscountCustomer = totalDisccountCustomerValues[recharge.id] || recharge.totalDisccountCustomer.toString();

            const validationResult = isValidTotalDiscount(
                recharge,
                totalDiscountProvider,
                totalDiscountCustomer
            );

            if (!validationResult.isValid) {
                // Muestra el mensaje de error usando SweetAlert
                Swal.fire({
                    icon: 'error',
                    title: 'Error de validación',
                    text: validationResult.message,
                });

                setIsSaveButtonDisabled(true); // Deshabilita el botón de guardar
                return false; // Retorna falso para detener el proceso de guardado
            }
        }

        setIsSaveButtonDisabled(false); // Habilita el botón de guardar si todo es correcto
        return true; // Retorna verdadero si todas las validaciones son correctas
    };

    const handleTotalDiscountChange = (recharge: Recharge, type: keyof Pick<Recharge, 'totalDisccountProvider' | 'totalDisccountCustomer'>, value: string) => {
        // Solo actualizamos el valor, no hacemos la validación aquí.
        const setter = type === 'totalDisccountProvider'
            ? setTotalDisccountProviderValues
            : setTotalDisccountCustomerValues;

        setter(prev => ({ ...prev, [recharge.id.toString()]: value }));
    };

    const handleTotalDiscountBlur = (recharge: Recharge, type: keyof Pick<Recharge, 'totalDisccountProvider' | 'totalDisccountCustomer'>) => {
        // Al salir del input, hacemos la validación.
        const value = type === 'totalDisccountProvider'
            ? totalDisccountProviderValues[recharge.id.toString()]
            : totalDisccountCustomerValues[recharge.id.toString()];

        validateAndSet(
            recharge,
            type,
            value,
            setTotalDisccountProviderValues,
            setTotalDisccountCustomerValues,
            totalDisccountProviderValues,
            totalDisccountCustomerValues
        );
    };


    if (error) {
        return <div>Ocurrió un error</div>;
    }

    return (
        <div>
            <div className="card mb-5 mb-xl-10 mt-10" id="kt_profile_details_view">
                <div className="card-header">
                    <h3 className="card-title">Configurar Descuentos</h3>
                </div>
                <div className="card-body">
                    {/* Contenedor principal de las pestañas */}
                    <ul className="nav nav-tabs ml-3 mr-3" id="myTab" role="tablist">
                        {uniqueBrands.map((brand, index) => (
                            <li className="nav-item cursor-pointer" key={index}>
                                <a className={`nav-link ${selectedBrand === brand ? 'active bg-primary text-white' : ''}`}
                                    onClick={() => handleBrandChange(brand)}>
                                    {brand}
                                </a>
                            </li>
                        ))}
                    </ul>

                    {/* Contenido de las pestañas */}
                    <div className="tab-content ml-3 mr-3 mt-10" id="myTabContent">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Denominación</th>
                                    {/* <th>SKU</th>
                                    <th>Precio Público</th> */}
                                    <th>Porcentaje de descuento</th>
                                    <th>Porcentaje de descuento Equality</th>
                                    <th>Porcentaje de descuento cliente</th>
                                    <th>Selección rapida</th>
                                    <th>Es Visible</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredRecharges.map((recharge, index) => (
                                    <tr key={index}>
                                        <td>{recharge.denomination}</td>

                                        <td>

                                            <input
                                                type="number"
                                                name="totalDisccount"
                                                className="form-control"
                                                value={totalDisccountValues[recharge.id] ?? recharge.totalDisccount.toString()}
                                                onChange={(e) => validateAndSetTotalDisccount(recharge, e.target.value)}
                                            />
                                        </td>
                                        {/* <td><input type="text" name="totalDisccount" className="form-control" defaultValue={recharge.totalDisccount} /></td> */}
                                        <td>
                                            <input
                                                type="number"
                                                name="totalDisccountProvider"
                                                className="form-control"
                                                value={totalDisccountProviderValues[recharge.id.toString()] ?? recharge.totalDisccountProvider.toString()}
                                                onChange={(e) => handleTotalDiscountChange(
                                                    recharge,
                                                    'totalDisccountProvider',
                                                    e.target.value
                                                )}
                                                onBlur={() => handleTotalDiscountBlur(
                                                    recharge,
                                                    'totalDisccountProvider'
                                                )}
                                            />

                                        </td>
                                        <td>
                                            <input
                                                type="number"
                                                name="totalDisccountCustomer"
                                                className="form-control"
                                                value={totalDisccountCustomerValues[recharge.id.toString()] ?? recharge.totalDisccountCustomer.toString()}
                                                onChange={(e) => handleTotalDiscountChange(
                                                    recharge,
                                                    'totalDisccountCustomer',
                                                    e.target.value
                                                )}
                                                onBlur={() => handleTotalDiscountBlur(
                                                    recharge,
                                                    'totalDisccountCustomer'
                                                )}
                                            />
                                        </td>
                                        <td className="text-center">
                                            <div className="custom-control custom-checkbox d-inline-block">
                                                <input
                                                    type="checkbox"
                                                    id={`quickSelect-${recharge.id}`}
                                                    name="quickSelect"
                                                    className='form-check-input border border-2 rounded'
                                                    style={{ backgroundColor: 'var(--kt-primary)' }}
                                                    checked={quickSelectValues[recharge.id] ?? recharge.quickSelect}
                                                    onChange={(e) => setQuickSelectValues(prev => ({ ...prev, [recharge.id]: e.target.checked }))}
                                                />
                                                <label className="custom-control-label" htmlFor={`quickSelect-${recharge.id}`}></label>
                                            </div>
                                        </td>
                                        <td className="text-center">
                                            <div className="custom-control custom-checkbox d-inline-block">
                                                <input
                                                    type="checkbox"
                                                    id={`visible-${recharge.id}`}
                                                    name="visible"
                                                    className='form-check-input border border-2 rounded'
                                                    style={{ backgroundColor: 'var(--kt-primary)' }}
                                                    checked={visibleValues[recharge.id] ?? recharge.visible}
                                                    onChange={(e) => setVisibleValues(prev => ({ ...prev, [recharge.id]: e.target.checked }))}
                                                />
                                                <label className="custom-control-label" htmlFor={`visible-${recharge.id}`}></label>
                                            </div>
                                        </td>


                                    </tr>
                                ))}
                            </tbody>
                        </table>


                    </div>
                    <div className="row">
                        <div className="col-12 text-right">
                            <button style={{ float: 'right' }}
                             disabled={isSaveButtonDisabled}
                            onClick={handleSend} className="btn btn-primary mt-3">Guardar Configuración {selectedBrand}</button>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export { RechargesDescConfig }
