import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl'
import { configCommission, updateComissionPaymentServiceConfig } from './apis/requestsApi'
import Swal from 'sweetalert2';
import { PaymentService } from './core/CommissionPaymentService.Model';


const CommissionPaymentService: React.FC = () => {
    const intl = useIntl();

 

    const [paymentServices, setPaymentServices] = useState<PaymentService[]>([]);
    const [uniqueCategories, setUniqueCategories] = useState<string[]>([]);
    const [filteredPaymentServices, setFilteredPaymentServices] = useState<PaymentService[]>([]);
    const [error, setError] = useState<Error | null>(null);
    const [selectedCategory, setSelectedCategory] = useState<string>("");


    const [totalCommisionEqualityValues, setTotalCommisionEqualityValues] = useState<Record<number, string | null>>({});
    const [totalCustomerCommissionValues, setTotalCustomerCommissionValues] = useState<Record<number, string | null>>({});


    const [isFavoriteValues, setIsFavoriteValues] = useState<Record<number, boolean>>({});
    const [visibleValues, setVisibleValues] = useState<Record<number, boolean>>({});
    const [commissionError, setCommissionError] = useState(false);
    const CommissionComparison = {
        EQUAL: 'EQUAL',
        LESS_THAN: 'LESS_THAN',
        GREATER_THAN: 'GREATER_THAN',
    };


    const [loading, setLoading] = useState(false); // Estado para el control de carga

    useEffect(() => {
        const fetchData = async () => {
            setError(null);
            setLoading(true);  // Iniciar el indicador de carga

            try {
                const { paymentServices } = await configCommission();
                setPaymentServices(paymentServices);

                const cateogrySet = new Set<string>(paymentServices.map((item: { category: any; }) => item.category));
                const categories = Array.from(cateogrySet).sort((a, b) => a.localeCompare(b));

                setUniqueCategories(categories);
                setSelectedCategory(categories[0]);

            } catch (err) {
                setError(err as Error);
            } finally {
                setLoading(false);  // Detener el indicador de carga independientemente de si fue exitoso o hubo un error
            }
        }

        fetchData();
    }, []);

    const handleBrandChange = (brand: string) => {
        setSelectedCategory(brand);
    }

    useEffect(() => {
        const filtered = paymentServices.filter(service => service.category === selectedCategory);
        setFilteredPaymentServices(filtered);
    }, [selectedCategory, paymentServices]);

    const getCommissionComparison = (
        service: PaymentService,
        equalityCommissionValue: string,
        customerCommissionValue: string
    ) => {
        const dataLogicCommission = parseFloat(service.datalogicCommission.toString());
        const equalityCommission = parseFloat(equalityCommissionValue);
        const customerCommission = parseFloat(customerCommissionValue);
        const totalCommission = parseFloat(service.totalCommission.toString());


        console.log(dataLogicCommission )
        console.log(equalityCommission)
        console.log( customerCommission )
        console.log(totalCommission)

        const sumOfCommissions = dataLogicCommission + equalityCommission + customerCommission;

        if (sumOfCommissions < totalCommission) {
            return CommissionComparison.LESS_THAN;
        } else if (sumOfCommissions > totalCommission) {
            return CommissionComparison.GREATER_THAN;
        } else {
            return CommissionComparison.EQUAL;
        }
    };

    // Y ahora ajustamos la función validateAndSet para que utilice la nueva lógica:
    const isValidTotalCommission = (
        service: PaymentService,
        equalityCommissionValue: string,
        customerCommissionValue: string
    ) => {
        // Convert all values to floating-point numbers
        const dataLogicCommission = parseFloat(service.datalogicCommission.toString());
        const equalityCommission = parseFloat(equalityCommissionValue);
        const customerCommission = parseFloat(customerCommissionValue);
        const totalCommissionExpected = parseFloat(service.totalCommission.toString());
    
        // Calculate the total commission
        const totalCommissionCalculated = dataLogicCommission + equalityCommission + customerCommission;
    
        // Calculate the difference to two decimal places
        const difference = parseFloat((totalCommissionCalculated - totalCommissionExpected).toFixed(2));
    
        // Determine if the calculated commission is equal to the expected commission within two decimal places
        const isValid = Math.abs(difference) < 0.01;
    
        // Return the validation result and the difference
        return {
            isValid: isValid,
            difference: difference,
        };
    };
    
    const validateAndSet = (service: PaymentService, type: 'equalityCommission' | 'customerCommission', value: string) => {
        let otherValue = '';
    
        if (type === 'equalityCommission') {
            otherValue = totalCustomerCommissionValues[service.id]?.toString() ?? service.customerCommission.toString();
        } else if (type === 'customerCommission') {
            otherValue = totalCommisionEqualityValues[service.id]?.toString() ?? service.equalityCommission.toString();
        }
    
        // Asegúrate de que tanto value como otherValue sean números válidos
        const valueNumber = parseFloat(value);
        const otherValueNumber = parseFloat(otherValue);
    
        if (isNaN(valueNumber) || isNaN(otherValueNumber)) {
            // Manejo del error cuando los valores no son números
            Swal.fire({
                icon: 'error',
                title: '¡Atención!',
                text: 'Se ha introducido un valor no numérico.',
            });
            setCommissionError(true); // Establece el estado de error en las comisiones
            return; // Salir de la función temprano para evitar más ejecución
        }
    
        const validation = isValidTotalCommission(
            service,
            type === 'equalityCommission' ? valueNumber.toString() : otherValueNumber.toString(),
            type === 'customerCommission' ? valueNumber.toString() : otherValueNumber.toString()
        );
    
        if (!validation.isValid) {
            const differenceAmount = validation.difference.toFixed(2);
            const message = `La comisión total calculada para el servicio ${service.serviceProvider} es ` +
                `${validation.difference > 0 ? 'mayor' : 'menor'} que la permitida por ${Math.abs(validation.difference)}.`;
    
            Swal.fire({
                icon: 'error',
                title: '¡Atención!',
                text: message,
            });
            setCommissionError(true); // Establece el estado de error en las comisiones
        } else {
            setCommissionError(false); // Si es válido, reinicia el estado de error
        }
    };
    
    

    const handlePaymentServiceUpdate = () => {
        const relevantFields = filteredPaymentServices.map(service => ({
            id: service.id,
            equalityCommission: totalCommisionEqualityValues[service.id] || 0,
            customerCommission: totalCustomerCommissionValues[service.id] || 0,
            isFavorite: isFavoriteValues[service.id] ?? service.isFavorite,
            visible: visibleValues[service.id] ?? service.visible
        }));
        

        const formattedData = {
            PaymentServices: relevantFields
        };


        // Muestra SweetAlert de carga
        Swal.fire({
            html: `
            <div class="spinner-container" style="overflow: hidden;">
                <i class="fas fa-spinner fa-spin"></i>
                <span>Actualizando configuración...</span>
            </div>
            `,
            showConfirmButton: false,
            allowOutsideClick: false,
        });

        // Asumo que tienes una función API similar para actualizar PaymentServices
        updateComissionPaymentServiceConfig(formattedData)
            .then(response => {
                console.log('PaymentServices updated successfully:', response);

                // Cierra el SweetAlert de carga
                Swal.close();

                // Muestra mensaje de éxito
                Swal.fire({
                    icon: 'success',
                    title: '¡Actualizado!',
                    text: 'La configuración de  se completó con éxito.'
                });
            })
            .catch(error => {
                console.error('Failed to update PaymentServices:', error);

                // Cierra el SweetAlert de carga y muestra mensaje de error
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Error al actualizar. Por favor intenta de nuevo.'
                });
            });
    }



    const allCommissionsAreValid = () => {
        return filteredPaymentServices.every(service => {
            // Usamos encadenamiento opcional y coalescencia nula para manejar valores nulos o indefinidos
            const equalityCommissionValue = totalCommisionEqualityValues[service.id]?.toString() ?? service.equalityCommission?.toString() ?? "0";
            const customerCommissionValue = totalCustomerCommissionValues[service.id]?.toString() ?? service.customerCommission?.toString() ?? "0";

            // Ahora puedes usar estos valores de manera segura en tu lógica de validación...
            return isValidTotalCommission(service, equalityCommissionValue, customerCommissionValue).isValid;
        });
    };

    


    if (error) {
        return <div>Ocurrió un error</div>;
    }
    if (loading) return <p>Cargando...</p>;

    return (
        <div>
            <div className="card mb-5 mb-xl-10 mt-10" id="kt_profile_details_view">
                <div className="card-header">
                    <h3 className="card-title">Configurar comisiones de Pago de servicios</h3>
                </div>
                <div className="card-body">
                    {/* Contenedor principal de las pestañas */}
                    <ul className="nav nav-tabs ml-3 mr-3" id="myTab" role="tablist">
                        {uniqueCategories.map((category, index) => (
                            <li className="nav-item" key={index}>
                                <a className={`nav-link cursor-pointer ${selectedCategory === category ? 'active bg-primary text-white' : ''}`}
                                    onClick={() => handleBrandChange(category)}>
                                    {category}
                                </a>
                            </li>
                        ))}
                    </ul>

                    {/* Contenido de las pestañas */}
                    <div className="tab-content ml-3 mr-3 mt-10" id="myTabContent">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>{intl.formatMessage({ id: 'CUSTOMER_CONFIG_COMMISSION_PROVIDER' })}</th>
                                    <th>{intl.formatMessage({ id: 'CUSTOMER_CONFIG_COMMISSION_SKU' })}</th>
                                    <th>{intl.formatMessage({ id: 'CUSTOMER_CONFIG_COMMISSION_COVERAGE' })}</th>
                                    <th>{intl.formatMessage({ id: 'CUSTOMER_CONFIG_COMMISSION_TOTALCOMMISSION' })}</th>
                                    <th>{intl.formatMessage({ id: 'CUSTOMER_CONFIG_COMMISSION_DATALOGICCOMMISSION' })}</th>
                                    <th>{intl.formatMessage({ id: 'CUSTOMER_CONFIG_COMMISSION_EQUALITYCOMMISSION' })}</th>
                                    <th>{intl.formatMessage({ id: 'CUSTOMER_CONFIG_COMMISSION_PARTNERCOMMISSION' })}</th>
                                    <th>{intl.formatMessage({ id: 'CUSTOMER_CONFIG_COMMISSION_ISFAVORITE' })}</th>
                                    <th>{intl.formatMessage({ id: 'CUSTOMER_CONFIG_COMMISSION_ISVISIBLE' })}</th>

                                </tr>
                            </thead>
                            <tbody>
                                {filteredPaymentServices.map((service, index) => (
                                    <tr key={index}>
                                        <td>{service.serviceProvider}</td>
                                        <td>{service.sku}</td>
                                        <td>{service.coverageArea}</td>
                                        <td>{service.totalCommission}</td>
                                        <td>{service.datalogicCommission}</td>
                                        <td>
                                            <input
                                                type="number"
                                                name="equalityCommission"
                                                className="form-control"
                                                min={0}

                                                value={totalCommisionEqualityValues[service.id] ?? (service.equalityCommission ? service.equalityCommission.toString() : '0.00')}
                                                onChange={(e) => setTotalCommisionEqualityValues(prev => ({ ...prev, [service.id]: e.target.value }))}
                                                onBlur={(e) => validateAndSet(service, 'equalityCommission', e.target.value)}
                                            />


                                        </td>
                                        <td>

                                            <input
                                                type="number"
                                                name="customerCommission"
                                                className="form-control"
                                                min={0}

                                                value={totalCustomerCommissionValues[service.id] ?? (service.customerCommission ? service.customerCommission.toString() : '0.00')}
                                                onChange={(e) => setTotalCustomerCommissionValues(prev => ({ ...prev, [service.id]: e.target.value }))}
                                                onBlur={(e) => validateAndSet(service, 'customerCommission', e.target.value)}

                                            />
                                        </td>
                                        <td className="text-center">



                                            <div className="custom-control custom-checkbox d-inline-block">
                                                <input
                                                    type="checkbox"
                                                    id={`isFavorite-${service.id}`}
                                                    name="isFavorite"
                                                    className='form-check-input border border-2 rounded'
                                                    style={{ backgroundColor: 'var(--kt-primary)' }}
                                                    checked={isFavoriteValues[service.id] ?? service.isFavorite}
                                                    onChange={(e) => setIsFavoriteValues(prev => ({ ...prev, [service.id]: e.target.checked }))}
                                                />
                                                <label className="custom-control-label" htmlFor={`isFavorite-${service.id}`}></label>
                                            </div>
                                        </td>
                                        <td>

                                            <div className="custom-control custom-checkbox d-inline-block">
                                                <input
                                                    type="checkbox"
                                                    id={`visible-${service.id}`}
                                                    name="visible"
                                                    className='form-check-input border border-2 rounded'
                                                    style={{ backgroundColor: 'var(--kt-primary)' }}
                                                    checked={visibleValues[service.id] ?? service.visible}
                                                    onChange={(e) => setVisibleValues(prev => ({ ...prev, [service.id]: e.target.checked }))}
                                                />
                                                <label className="custom-control-label" htmlFor={`visible-${service.id}`}></label>
                                            </div>

                                        </td>
                                    </tr>
                                ))}
                            </tbody>

                        </table>



                    </div>
                    <div className="row">
                        <div className="col-12 text-right">
                            <button
                                style={{ float: 'right' }}
                                className="btn btn-primary mt-3"
                                onClick={handlePaymentServiceUpdate}
                                disabled={commissionError || !allCommissionsAreValid()} // Deshabilita el botón si hay error
                            >
                                Guardar Configuración {selectedCategory}
                            </button>


                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export { CommissionPaymentService }
