import React, { useState, useEffect } from 'react';
import { Navigate, Routes, Route, Outlet } from 'react-router-dom';
import { PageLink, PageTitle } from '../../../_metronic/layout/core';
import { ProductList } from './ProductList';
import CryptoJS from 'crypto-js';

interface Permission {
  id: number;
  flag: boolean;
  GroupId: number;
  ResourceId: number;
  Resource: {
    id: number;
    name: string;
    label: string;
  };
}

const customerBreadCrumbs: Array<PageLink> = [
  {
    title: 'Productos',
    path: '/products/list',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
];

const fetchPermissions = async (): Promise<Permission[] | null> => {
  const cachedPermissions = localStorage.getItem('userPermissions');
  const encryptionKey = process.env.REACT_APP_ENCRIPTION_KEY;

  if (cachedPermissions && encryptionKey) {
    const decryptedPermissions = CryptoJS.AES.decrypt(cachedPermissions, encryptionKey).toString(CryptoJS.enc.Utf8);
    const permissions = JSON.parse(decryptedPermissions) as Permission[];

    return permissions;
  }
  return null;
};

const ProductPage = () => {
  const [permissions, setPermissions] = useState<Permission[] | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getPermissions = async () => {
      const perms = await fetchPermissions();
      setPermissions(perms);
      setLoading(false);
    };

    getPermissions();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  const hasProductsListPermission = permissions?.some(
    (p) => p.Resource && p.Resource.name === 'products.list' && p.flag
  );

  return (
    <Routes>
      <Route element={<Outlet />}>
        {hasProductsListPermission ? (
          <Route
            path='list'
            element={
              <>
                <PageTitle breadcrumbs={customerBreadCrumbs}>Listado de productos</PageTitle>
                <ProductList />
              </>
            }
          />
        ) : (
          <Route path='list' element={<Navigate to='/error/403' replace />} />
        )}
        <Route index element={<Navigate to='list' />} />
      </Route>
    </Routes>
  );
};

export default ProductPage;
