import axios from 'axios'

export const fetchRequests = async (
  config: any,
  limit: number,
  currentPage: number,
  searchValue: string,
  rolId: string,
  userId: string,
  startDate: string,
  endDate: string
) => {
  try {
    let queryString = `${process.env.REACT_APP_API_URL}/logs?limit=${limit}&page=${currentPage}&order=createdAt,desc`

    if (searchValue) {
      queryString += `&search=${searchValue}`
    }

    if (rolId) {
      queryString += `&rol_id=${rolId}`
    }

    if (userId) {
      queryString += `&user_id=${userId}`
    }

    if (startDate) {
      queryString += `&createdAtDateInit=${startDate}`
    }

    if (endDate) {
      queryString += `&createdAtDateEnd=${endDate}`
    }

    const response = await axios.get(queryString, config)

    console.log(response.data.data.timezone)

    const requests = response.data.data.records
    const roles = response.data.data.filters.roles
    const users = response.data.data.filters.users
    const timezone= response.data.data.timezone;

    const totalPages = Math.ceil(response.data.data.total / limit)

    return {requests, totalPages, roles, users, timezone}
  } catch (err) {
    throw err
  }
}
