import { Navigate, Routes, Route, Outlet, useLocation } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import { useIntl } from 'react-intl'
import { Passport } from './Passport'
import PermissionsService from '../auth/PermissionsService'

const PassportPage = () => {


  const userBreadCrumbs: Array<PageLink> = [
    {
      title: 'Passport',
      path: '',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  const permissions = PermissionsService.getPermissions()
  const {
    hasClientPassportPermission

  } = permissions || {}


  return (
    <Routes>
      <Route element={<Outlet />}>

        {hasClientPassportPermission ? (
          <Route
            path='config/:customerId'
            element={
              <>
                <Passport />
                <PageTitle breadcrumbs={userBreadCrumbs}>
                  Passport
                </PageTitle>
              </>
            }
          />
        ) : (
          <Route path='config/:customerId' element={<Navigate to='/error/403' replace />} />
        )}



      </Route>

      <Route index element={<Navigate to='list' />} />
    </Routes>
  )
}

export default PassportPage
