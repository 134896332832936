import{ useCallback } from 'react';
import UserPool from "../../../../../src/UserPool";

const Logout = () => {
  const handleLogout = useCallback(() => {
    const user = UserPool.getCurrentUser();
    if (user) {
      user.signOut();
      localStorage.removeItem("cognito_token");
      localStorage.removeItem('userPermissions');
      console.log("Sesión cerrada con éxito");
      window.location.href = '/auth';
    }
  }, []);

  return handleLogout;
};

export default Logout;