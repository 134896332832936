import axios, {  AxiosResponse } from 'axios';
import { getTokenConfig } from '../../../auth';
import { SmsConfig } from './models';



export const configAlertsSMS = async () => {
  try {
    let queryString = `${process.env.REACT_APP_API_URL}/sms/config`;

    const config = await getTokenConfig();
    const response = await axios.get(queryString, config);

    console.log(response.data.data);

    // Comprobamos si smsconfig es nulo antes de desestructurarlo.
    if (response.data.data.smsconfig) {
      const { id, emails, firstAlert, secondAlert, thirdAlert, createdBy, createdAt, updatedAt } = response.data.data.smsconfig;

      // Tipamos el objeto smsConfig usando la interfaz SmsConfig
      const smsConfig: SmsConfig = {
        id,
        emails,
        firstAlert,
        secondAlert,
        thirdAlert,
        createdBy,
        createdAt,
        updatedAt
      };

      return { smsConfig };
    } else {
      // Devolvemos un objeto con propiedades nulas o valores predeterminados.
      return {
        smsConfig: {
          id: 0,
          emails: '',
          firstAlert: 0,
          secondAlert: 0,
          thirdAlert: 0,
          createdBy: null,
          createdAt: null,
          updatedAt: null
        }
      };
    }
  } catch (err) {
    console.error('Error fetching SMS configuration:', err);
    throw err;
  }
};


export const updateSMSLimitConfig = async (id: number, value: any) => {
  try {
      let queryString = `${process.env.REACT_APP_API_URL}/sms/config/${id}`;


      console.log(value)
      const config = await getTokenConfig(); 
      const response = await axios.put(queryString, value, config);

      if (response.status !== 200) {
          throw new Error('Failed to update recharge configuration');
      }

      return response.data;
  } catch (err) {
      throw err;
  }
};


export const createSMSLimitConfig = async (value: any) => {
    try {
        let queryString = `${process.env.REACT_APP_API_URL}/sms/config`;
  
  
        console.log(value)
        const config = await getTokenConfig(); 
        const response = await axios.post(queryString, value, config);
  
        if (response.status !== 200) {
            throw new Error('Failed to update recharge configuration');
        }
  
        return response.data;
    } catch (err) {
        throw err;
    }
  };