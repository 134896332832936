import { CognitoUserSession } from "amazon-cognito-identity-js";
import userPool from "../src/UserPool";

export const getTokenConfig = async () => {
    const user = userPool.getCurrentUser();
  
    if (!user) {
      throw new Error("No hay usuario logueado");
    }
  
    const session = await new Promise((resolve, reject) => {
      user.getSession((err: any, session: any) => {
        if (err) {
          reject(err);
        } else {
          resolve(session);
        }
      });
    });
  
    if (session instanceof CognitoUserSession) {
      const accessToken = session.getIdToken().getJwtToken();
  
      return {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };
    } else {
      throw new Error("La sesión no es una instancia de CognitoUserSession");
    }
  };
  