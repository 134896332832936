import React, {useState, useEffect, useMemo} from 'react'
import {Link} from 'react-router-dom'
import {getTokenConfig} from '../../../../src/auth'
import {fetchRequests} from './api/requestAPI'
import {useIntl} from 'react-intl'
import {Timeline} from './Timeline'
import {ITimelineItem} from './core/TimelineItem.Model'
import {DetailModal} from './DetailModal'

const Activity: React.FC = () => {
  const intl = useIntl()
  const [data, setData] = useState<ITimelineItem[]>([])
  const [roles, setRoles] = useState<any[]>([])
  const [users, setUsers] = useState<any[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [totalPages, setTotalPages] = useState(0)
  const [dataModal, setDataModal] = useState<ITimelineItem>()

  const [searchValue, setSearchValue] = useState('')

  const [timezone, setTimezone] = useState('')

  const [roleId, setRoleId] = useState('')
  const [userId, setUserId] = useState('')
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')

  const handleScroll = () => {
    const endOfPage = window.innerHeight + window.pageYOffset >= document.body.offsetHeight
    const {scrollTop, scrollHeight, clientHeight} = document.documentElement

    if (scrollTop + clientHeight >= scrollHeight - 5) {
      if (currentPage < totalPages && !isLoading) {
        setCurrentPage(currentPage + 1)
        handleFromInfinityScroll()
      }
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [isLoading])

  useEffect(() => {
    handleSearch()
  }, [])

  const handleReset = async () => {
    setCurrentPage(1)
    await new Promise<void>((resolve) => {
      setSearchValue('')
      setRoleId('')
      setUserId('')
      setStartDate('')
      setEndDate('')

      resolve()
    })
  }

  const memoizedData = useMemo(() => data, [data])

  const handleFromInfinityScroll = async () => {
    setIsLoading(true)
    setError(null)

    try {
      const config = await getTokenConfig()

      const {requests, totalPages, roles, users } = await fetchRequests(
        config,
        limit,
        currentPage,
        searchValue,
        roleId,
        userId,
        startDate,
        endDate
      )
      const newData = [...data, ...requests]
      setData(newData)
      setRoles(roles)
      setUsers(users)
     setTimezone(timezone)

      setIsLoading(false)
      setTotalPages(totalPages)
    } catch (err: any) {
      setError(err)
      setIsLoading(false)
    }
  }

  const handleSearch = async () => {
    setCurrentPage(1)
    setIsLoading(true)
    setError(null)

    try {
      const config = await getTokenConfig()

      const {requests, totalPages, roles, users, timezone} = await fetchRequests(
        config,
        limit,
        currentPage,
        searchValue,
        roleId,
        userId,
        startDate,
        endDate
      )
      setData(requests)
      setRoles(roles)
      setUsers(users)
      setTimezone(timezone)
   


      setIsLoading(false)
      setTotalPages(totalPages)
    } catch (err: any) {
      setError(err)
      setIsLoading(false)
    }
  }

  const handleUpdateData = (event: any, item: ITimelineItem) => {
    setDataModal(item)
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-body py-3 '>
        <div className='row mb-5'>
          <div className='col-lg-4 d-flex align-items-start flex-wrap'>
            <div className='w-100'>
              <label className='form-label'>Buscar</label>
              <input
                type='text'
                data-kt-user-table-filter='search'
                onChange={(event) => setSearchValue(event.target.value)}
                className='form-control form-control'
                value={searchValue}
              />
            </div>
          </div>

          <div className='col-lg-3 d-flex align-items-start flex-wrap'>
            <div className='w-100'>
              <label className='form-label'>Roles</label>
              <select
                className='form-select form-select form-control-lg'
                value={roleId}
                onChange={(event) => {
                  setRoleId(event.target.value)
                }}
              >
                <option value=''>Todos</option>
                {roles.map((rol) => (
                  <option key={`roles${rol.id}`} value={rol.id}>
                    {rol.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className='col-lg-3 d-flex align-items-start flex-wrap'>
            <div className='w-100'>
              <label className='form-label'>Usuarios</label>
              <select
                className='form-select form-select form-control-lg'
                value={userId}
                onChange={(event) => {
                  setUserId(event.target.value)
                }}
              >
                <option value=''>Todos</option>
                {users.map((user) => (
                  <option key={`roles${user.id}`} value={user.id}>
                    {user.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <div className='row mb-5'>
          <div className='col-lg-3 col-md-6 col-sm-12 mb-3'>
            <div className='form-group form-label-group'>
              <label className='form-label'>Fecha de inicio</label>
              <input
                type='date'
                className='form-control form-control form-control-lg'
                value={startDate}
                onChange={(event) => setStartDate(event.target.value)}
              />
            </div>
          </div>
          <div className='col-lg-3 col-md-6 col-sm-12 mb-3'>
            <div className='form-group form-label-group'>
              <label className='form-label'>Fecha fin</label>
              <input
                type='date'
                className='form-control form-control form-control-lg'
                value={endDate}
                onChange={(event) => setEndDate(event.target.value)}
              />
            </div>
          </div>
        </div>

        <div className='row mb-5'>
          <div className='col-lg-7'></div>
          <div className='col-lg-5 align-bottom'>
            <div className='split-button rounded-pill'>
              <button
                onClick={() => {
                  handleSearch()
                  setData([])
                }}
                className='btn btn-primary'
              >
                Buscar
              </button>
              <button onClick={handleReset} className='btn btn-secondary rounded-pill'>
                Borrar
              </button>
            </div>
          </div>
        </div>

        <div className='row mb-5' id='containerListLogs'>
          <div className='col-lg-12'>
            <div className='timeline'>
              {data.map((item, index) => (
                <Timeline
                  key={index}
                  data={item}
                  handleUpdateData={(event: any) => handleUpdateData(event, item)}
                  timezone={timezone}
                
                />
              ))}
            </div>
          </div>
        </div>

        <div className='table-responsive mb-20'>
          <div className='d-flex justify-content-center'>
            <DetailModal data={dataModal} />
            {data.length === 0 && !isLoading && !error && (
              <p className='mb-0'>
                <strong>No hay resultados disponibles</strong>
              </p>
            )}
            {isLoading && (
              <div className='spinner-border' role='status'>
                <span className='sr-only'>Loading...</span>
              </div>
            )}
            {error && <p>Ocurrió un error</p>}
          </div>
        </div>
      </div>
    </div>
  )
}

export {Activity}
