import React, { useCallback, useState } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { IReport } from './core/Report.Model';

type Props = {
    data: IReport[];
    currentPage: number;
    limit: number;
    timezone: string;
};

const ReportListBody: React.FC<Props> = React.memo(({ data, currentPage, limit, timezone }) => {
    const calculateIndex = useCallback((index: number) => {
        return (currentPage - 1) * limit + index + 1;
    }, [currentPage, limit]);




    return (
        <tbody>
            {data.map((item, index) => {

                return (
                    <tr key={item.id}>
                        <td style={{ width: '14ch' }}>{calculateIndex(index)}</td>
                        <td style={{ width: '14ch' }}>{item.providerName}</td>
                        <td style={{ width: '14ch' }}>{item.customerName}</td>
                        <td>Pagos</td>
                        <td style={{ width: '14ch' }}>
                            {moment(item.createdAt).tz(timezone).format('DD-MM-YYYY')}
                        </td>
                        <td style={{ width: '14ch' }}>
                            {moment(item.createdAt).tz(timezone).format('HH:mm:ss')}
                        </td>
                        <td style={{ width: '14ch' }}>{item.id}</td>
                        <td style={{ width: '14ch' }}>{item.reference}</td>
                        <td style={{ width: '14ch' }}>{item.transaction}</td>
                        <td style={{ width: '14ch' }}>

                            {(() => {
                                switch (item.typeRequest) {
                                    case 1:
                                        return 'Referencia generada';
                                    case 2:
                                        return 'Consulta';
                                    case 3:
                                        return 'Pago';
                                    case 4:
                                        return 'Reverso';
                                    default:
                                        return 'Tipo de solicitud desconocido';
                                }
                            })()}


                        </td>
                        <td style={{ width: '14ch' }}>{item.amount}</td>
                        <td style={{ width: '14ch' }}>{item.authorization}</td>
                        <td style={{ width: '14ch' }}>{item.integratorName}</td>
                        <td style={{ width: '14ch' }}>{item.center}</td>
                        <td style={{ width: '14ch' }}>{item.center}</td>
                        <td style={{ width: '14ch' }}></td>
                        <td style={{ width: '14ch' }}>{item.statusCode === '200' ? 'Aceptado' : 'Rechazado'}</td>
                        <td style={{ width: '14ch' }}>{item.userName}</td>

                        <td style={{ width: '14ch' }}>{item.webhookCustomer ? 'Webhook cliente' : 'HUB'}</td>
                    </tr>
                );
            })}
        </tbody>
    );
});

export default ReportListBody;
