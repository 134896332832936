// SearchFilters.tsx

import React from 'react';
import { useIntl } from 'react-intl';

import { ITransactionsMoneybagCustomer, ITypesMoneybagCustomers, ITransaction, ICustomer } from './core/Report.Model'



interface Props {
    searchValue: string;
    setSearchValue: (value: string) => void;

    searchCustomer: string;
    setSearchCustomer: (value: string) => void;

    searchTypesTransactions: string;
    setTypesTransactions: (value: string) => void;

    searchTypesPayments: string;
    seTypesPayments: (value: string) => void;

    datePaymentInit: string;
    setDatePaymentInit: (value: string) => void;

    datePaymentEnd: string;
    setDatePaymentEnd: (value: string) => void;


    createdAtDateInit: string;
    setCreatedAtDateInit: (value: string) => void;
    createdAtDateEnd: string;
    setCreatedAtDateEnd: (value: string) => void;


    saldoAbonos: number;
    setSaldoAbonos: (value: number) => void;
  


    saldoCargos: number;
    setSaldoCargos: (value: number) => void;

    totalAmount: number;
    setTotalAmount: (value: number) => void;

    totalConsumed: number;
    setTotalConsumed: (value: number) => void;


    typesTransactions: ITransactionsMoneybagCustomer[];
    typesMoneybagCustomers: ITypesMoneybagCustomers[];
    customers: ICustomer[];
    handleSearch: () => void;
    handleReset: () => void;
    handleDownload: (image: string) => Promise<void>;
    handleDownloadExcel: () => void;
}

const SearchFilters: React.FC<Props> = (props) => {
    const intl = useIntl();
    // De-structure props for better readability
    const {
        searchValue, setSearchValue,
        searchCustomer, setSearchCustomer,
        searchTypesTransactions, setTypesTransactions,
        searchTypesPayments, seTypesPayments,

        datePaymentInit, setDatePaymentInit,
        datePaymentEnd, setDatePaymentEnd,
        createdAtDateInit, setCreatedAtDateInit,
        createdAtDateEnd, setCreatedAtDateEnd,
        totalAmount, setTotalAmount,
        totalConsumed, setTotalConsumed,




        saldoAbonos,
        saldoCargos,
        typesTransactions, typesMoneybagCustomers, customers,
        handleSearch, handleReset, handleDownloadExcel
    } = props;

    return (
        <>
            <div className="row">

                <div className="col-md-12 col-xs-12 mr-md-2 mb-3 d-flex flex-column justify-content-end align-items-end">

                    <div className="row">

                        {/* Primera Card */}
                        <div className="col">
                            <div className="card mb-3  my-custom-cardIndicador" > {/* Reducir el tamaño de la tarjeta */}
                                <div className="card-body d-flex flex-column justify-content-center">
                                    <p className="card-text text-center text-primary mb-1" style={{ fontSize: '1.5rem' }}><strong>
                                        ${
                                            isNaN(totalAmount)
                                                ? '0.00'
                                                : parseFloat(totalAmount.toString()).toLocaleString('en-US', {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2
                                                })
                                        }

                                    </strong></p> {/* Reducir el margen inferior */}
                                    <p className="card-text text-center" style={{ fontSize: '1rem' }}><strong>Disponible</strong></p>
                                </div>
                            </div>
                        </div>


                        <div className="col">
                            <div className="card mb-3  my-custom-cardIndicador" > {/* Reducir el tamaño de la tarjeta */}
                                <div className="card-body d-flex flex-column justify-content-center">
                                    <p className="card-text text-center text-primary mb-1" style={{ fontSize: '1.5rem' }}><strong>
                                        ${
                                            isNaN(saldoAbonos)
                                                ? '0.00'
                                                : parseFloat(saldoAbonos.toString()).toLocaleString('en-US', {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2
                                                })
                                        }

                                    </strong></p> {/* Reducir el margen inferior */}
                                    <p className="card-text text-center" style={{ fontSize: '1rem' }}><strong>Abonos</strong></p>
                                </div>
                            </div>
                        </div>

                        {/* Segunda Card */}
                        <div className="col">
                            <div className="card mb-3  my-custom-cardIndicador" > {/* Reducir el tamaño de la tarjeta */}
                                <div className="card-body d-flex flex-column justify-content-center">
                                    <p className="card-text text-center text-primary mb-1" style={{ fontSize: '1.5rem' }}><strong>
                                        ${
                                            isNaN(saldoCargos)
                                                ? '0.00'
                                                : parseFloat(saldoCargos.toString()).toLocaleString('en-US', {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2
                                                })
                                        }
                                    </strong></p> {/* Reducir el margen inferior */}
                                    <p className="card-text text-center" style={{ fontSize: '1rem' }}><strong>Cargos</strong></p>
                                </div>
                            </div>
                        </div>



                        <div className="col">
                            <div className="card mb-3  my-custom-cardIndicador" > {/* Reducir el tamaño de la tarjeta */}
                                <div className="card-body d-flex flex-column justify-content-center">
                                    <p className="card-text text-center text-primary mb-1" style={{ fontSize: '1.5rem' }}><strong>
                                        ${
                                            isNaN(totalConsumed)
                                                ? '0.00'
                                                : parseFloat(totalConsumed.toString()).toLocaleString('en-US', {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2
                                                })
                                        }

                                    </strong></p> {/* Reducir el margen inferior */}
                                    <p className="card-text text-center" style={{ fontSize: '1rem' }}><strong>Consumido</strong></p>
                                </div>
                            </div>
                        </div>

                    </div>


                </div>

            </div>

            <div className='row flex-wrap mb-5'>
                <div className='col-lg-3 col-md-6 col-sm-12 mb-3'>
                    <div className='form-group form-label-group'>
                        <label className='form-label'>Buscar</label>
                        <input
                            type='text'
                            data-kt-user-table-filter='search'
                            onChange={(event) => setSearchValue(event.target.value)}
                            className='form-control form-control form-control-lg'
                            value={searchValue}
                            onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                    handleSearch()
                                }
                            }}
                        />
                    </div>
                </div>

                <div className='col-lg-3 col-md-6 col-sm-12 mb-3'>
                    <div className='form-group form-label-group'>
                        <label className='form-label'>      {intl.formatMessage({ id: 'REPORT.PARTNER.C' })}</label>
                        <select
                            value={searchCustomer}
                            onChange={(event) => setSearchCustomer(event.target.value)}
                            className='form-select form-select-solid form-control-lg'
                        >
                            <option value=''>Selecciona un socio comercial</option>
                            {customers.map((customer) => (
                                <option key={customer.id} value={customer.id}>
                                    {customer.name}
                                </option>
                            ))}
                        </select>

                    </div>
                </div>


                <div className='col-lg-3 col-md-6 col-sm-12 mb-3'>
                    <div className='form-group form-label-group'>
                        <label className='form-label'>Bolsa</label>
                        <select
                            value={searchTypesTransactions}
                            onChange={(event) => setTypesTransactions(event.target.value)}
                            className='form-select form-select form-control-lg'
                        >
                            <option value=''>Selecciona el tipo de bolsa</option>
                            {typesMoneybagCustomers.map((typeMoney) => (
                                <option key={typeMoney.id} value={typeMoney.id}>
                                    {typeMoney.name}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className='col-lg-3 col-md-6 col-sm-12 mb-3'>
                    <div className='form-group form-label-group'>
                        <label className='form-label'>Tipo de movimiento</label>
                        <select
                            value={searchTypesPayments}
                            onChange={(event) => seTypesPayments(event.target.value)}
                            className='form-select form-select form-control-lg'
                        >
                            <option value=''>Selecciona el tipo de movimiento</option>
                            {typesTransactions.map((typeTransaction) => (
                                <option key={typeTransaction.id} value={typeTransaction.id}>
                                    {typeTransaction.name}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>

                <div className='col-lg-3 col-md-6 col-sm-12 mb-3'>
                    <div className='form-group form-label-group'>
                        <label className='form-label'>Fecha de inicio (Pago)</label>
                        <input
                            type='date'
                            value={datePaymentInit}
                            onChange={(event) => setDatePaymentInit(event.target.value)}
                            className='form-control form-control form-control-lg'
                        />
                    </div>
                </div>
                <div className='col-lg-3 col-md-6 col-sm-12 mb-3'>
                    <div className='form-group form-label-group'>
                        <label className='form-label'>Fecha fin(Pago)</label>
                        <input
                            type='date'
                            value={datePaymentEnd}
                            onChange={(event) => setDatePaymentEnd(event.target.value)}
                            className='form-control form-control form-control-lg'
                        />
                    </div>

                </div>

                <div className='col-lg-3 col-md-6 col-sm-12 mb-3'>
                    <div className='form-group form-label-group'>
                        <label className='form-label'>Fecha de inicio (registro)</label>
                        <input
                            type='date'
                            value={createdAtDateInit}
                            onChange={(event) => setCreatedAtDateInit(event.target.value)}
                            className='form-control form-control form-control-lg'
                        />
                    </div>
                </div>
                <div className='col-lg-3 col-md-6 col-sm-12 mb-3'>
                    <div className='form-group form-label-group'>
                        <label className='form-label'>Fecha fin(registro)</label>
                        <input
                            type='date'
                            value={createdAtDateEnd}
                            onChange={(event) => setCreatedAtDateEnd(event.target.value)}
                            className='form-control form-control form-control-lg'
                        />
                    </div>
                </div>


            </div>
            <div className='row  mb-5'>


                <div className='col-lg-12 col-md-12 col-sm-12 align-bottom justify-content-end'>
                    <div className='form-group d-flex justify-content-end'>
                        <div className='split-button'>
                            <button onClick={handleSearch} className='btn btn-primary rounded-pill'>
                                Buscar
                            </button>
                            <button onClick={handleReset} className='btn btn-secondary rounded-pill'>
                                Borrar
                            </button>

                            <button onClick={handleDownloadExcel} className='btn btn-secondary rounded-pill'>
                                Descargar reporte
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SearchFilters;
