import React, { useEffect,  useState } from 'react';

import { IReport } from './core/Report.Model';
import { fetchInfoService } from './apis/requestsApi';
import { useIntl } from 'react-intl';

type Props = {
  report: IReport;
  visible: boolean; // Agregar la propiedad visible al tipo Props
  onClose: () => void;
};



const ReportModal: React.FC<Props> = ({ report, visible, onClose }) => {

  const intl = useIntl();

  const [responseValidationItems, setResponseValidationItems] = useState<any>([])
  function renderValue(value: any) {

    if (Array.isArray(value)) {
      return (
        <div className="row">
          {value.map((val, i) => (
            <div className="col-12" key={i}>
              <div className="d-flex flex-column">

                {renderValue(val)}
              </div>
            </div>
          ))}
        </div>
      );
    } else if (typeof value === "object" && value !== null) {
      if (value.hasOwnProperty("address")) {
        return (
          <div className="row">
            {Object.entries(value).map(([key, val]) => {
              if (key === "address") {
                return (
                  <div className="col-12" key={key}>
                    <div className="d-flex flex-column">
                      <label className="form-label">
                        {intl.formatMessage({ id: key })}
                      </label>
                      <div className="col-md-12">
                        <div className="row">{renderValue(val)}</div>
                      </div>
                    </div>
                  </div>
                );
              } else {
                return (
                  <div className="col-md-3" key={key}>
                    <div className="d-flex flex-column">
                      <label className="form-label">
                        {intl.formatMessage({ id: key })}
                      </label>
                      <div className="col-md-12">
                        <div className="row">{renderValue(val)}</div>
                      </div>
                    </div>
                  </div>
                );
              }
            })}
          </div>
        );
      } else {
        return (
          <div className="row">
            {Object.entries(value).map(([key, val]) => (
              <div className="col-md-3" key={key}>
                <div className="d-flex flex-column">
                  <label className="form-label">
                    {intl.formatMessage({ id: key })}
                  </label>
                  <div className="col-md-12">
                    <div className="row">{renderValue(val)}</div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        );
      }
    } else {
      if (typeof value === 'boolean') {
        if (value) {
          return <span>Si</span>;
        } else {
          return <span>No</span>;
        }
      } else if (value == null) {
        return <span>&nbsp;</span>;
      } else {
        return <label>{value}</label>;
      }

    }
  }


  useEffect(() => {

    setResponseValidationItems([])
    const fetchDataAsync = async () => {
      if (report.customerRequest) {
        const customerRequestJson = JSON.parse(report.customerRequest);
        const id = report.id;
        const type = customerRequestJson.type;

        await fetchInfoService(id, type).then
          ((servicesRequest) => {
            console.log(servicesRequest)
            const optionsArray = Object.entries(servicesRequest.data.data).map(([label, value]) => ({
              label,
              value,
            }));
            setResponseValidationItems(optionsArray);


          });

      }
    };
    fetchDataAsync();
  }, [report]);




  return (
    <div className='modal fade ' id={`requestModal`} tabIndex={-1} aria-labelledby='requestModal' aria-hidden={!visible}>
      <div className='modal-dialog modal-dialog-centered modal-lg'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h1 className='modal-title fs-5' >
              Detalle
            </h1>
            <button type='button' className='btn-close' data-bs-dismiss='modal' aria-label='Close'></button>
          </div>
          <div className='modal-body'>

            <div className="row">

              {responseValidationItems.length > 0 ? (

                responseValidationItems.map((item: any) => (
                  <>
                    {item.label.includes("DOCUMENT") ? (
                      <>
                        <div className='col-12' key={item.label}>
                          <label className='form-label'>{intl.formatMessage({ id: item.label })}</label>
                          <div>
                            <a href={`data:application/pdf;base64,${item.value}`} download="documento.pdf">
                              {intl.formatMessage({ id: 'LBL_DOWNLOAD_DOCUMENT' })}
                            </a>
                          </div>
                        </div>
                      </>

                    ) : (
                      <>

                        {Array.isArray(item.value) ?
                          <div className="col-md-12">
                            <div className="row">
                              {renderValue(item.value)}
                            </div>

                          </div>
                          :
                          (typeof item.value === 'object' && item.value !== null) ?
                            <>
                              <div className="col-md-12">
                                {renderValue(item.value)}
                              </div>

                            </>
                            :
                            <div className="col-md-3">
                              <label className='form-label'>{intl.formatMessage({ id: item.label })}</label>
                              {typeof item.value === 'boolean' ? (
                                <p>{item.value ? 'Si' : 'No'}</p>
                              ) : (
                                <p>{item.value}</p>
                              )}


                            </div>
                        }


                      </>
                    )}


                  </>
                ))

              ) : (
                <>Cargando...</>
              )}
            </div >
          </div>
        </div>

      </div>
      <div className='modal-footer'>
        <button type='button' className='btn btn-secondary rounded-pill' data-bs-dismiss='modal'>
          Cerrar
        </button>
      </div>
    </div>

  );
};

export default ReportModal;
