import { Navigate, Routes, Route, Outlet, useParams, useLocation, useNavigate } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import { useIntl } from 'react-intl'
import { RechargesDescConfig } from './RechargeDescConfig'
import PermissionsService from '../auth/PermissionsService';

const RechargeDescPage = () => {
  const intl = useIntl()

  const userBreadCrumbs: Array<PageLink> = [
    {
      title: 'Passport',
      path: 'recharges',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]



  const permissions = PermissionsService.getPermissions();
  const {
    hasProductConfigCommissionPermission,

  } = permissions || {};


  return (
    <Routes>
      <Route element={<Outlet />}>

        {hasProductConfigCommissionPermission ? (
          <Route
            path='config/desc'
            element={
              <>
                <RechargesDescConfig />
                <PageTitle breadcrumbs={userBreadCrumbs}>
                  {intl.formatMessage({ id: 'SERVICES.REFERENCE_DETAILS' })}
                </PageTitle>
              </>
            }
          />

        ) : (
          <Route path='config/desc' element={<Navigate to='/error/403' replace />} />
        )}
      </Route>

      <Route index element={<Navigate to='list' />} />
    </Routes>
  )
}

export default RechargeDescPage
