
import axios, { AxiosResponse } from 'axios';

import { getTokenConfig } from '../../../../../src/auth'



export const fetchCatalogs = async (customerId: number) => {
  try {
    let queryString = `${process.env.REACT_APP_API_URL_RECHARGES}/transactions?customerId=${customerId}`;

    const config = await getTokenConfig(); // Assuming getTokenConfig is a function that returns a config object for axios
    const response = await axios.get(queryString, config);



    const transactions = response.data.data.transactions;
    const types = response.data.data.types;
    const paymentTypes = response.data.data.paymentTypes;

    const saldoRecargas = response.data.data.saldoRecargas;
    const saldoPagos = response.data.data.saldoPagos;
    const saldoCommission = response.data.data.commissionTotal;



    return { transactions, types, paymentTypes, saldoRecargas, saldoPagos, saldoCommission };
  } catch (err) {
    throw err;
  }
};

export const saveTransaction = async (data: any) => {
  const config = await getTokenConfig(); // Assuming getTokenConfig is a function that returns a config object for axios
  try {
    const response: AxiosResponse = await axios.post(
      `${process.env.REACT_APP_API_URL_RECHARGES}/transactions`,
      data,
      config
    );

    console.log("save", response.data.data);
    return response.data.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response && error.response.status === 400) {
      // Manejar el error 400 aquí
      console.error("Error 400:", error.response.data.message);
      // Puedes lanzar tu propia excepción personalizada si lo deseas
      throw new Error("Saldo insuficiente en la bolsa para realizar este movimiento.");
    }
    throw error; // Lanza otros errores que no sean el 400
  }
}






export const uploadDirectly = async (file: any) => {
  const config = await getTokenConfig();

  const base64File = await toBase64(file);

  const url = `${process.env.REACT_APP_API_URL_RECHARGES}/upload/image`;
  try {
    const response = await axios({
      method: 'post',
      url: url,
      data: {
        fileName: file.name,
        fileType: file.type,
        fileContent: base64File
      },
      headers: {
        ...config.headers,
        'Content-Type': 'application/json'
      },
    });

    // Si estás esperando alguna respuesta específica desde Lambda, puedes procesarla aquí.
    return response.data.fileUrl;

  } catch (err: any) {
    throw err;
  }
};

function toBase64(file: any): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      if (typeof reader.result === 'string') {
        resolve(reader.result.split(',')[1]); // Remove the "data:" prefix
      } else {
        reject(new Error('Unexpected result format'));
      }
    };
    reader.onerror = error => reject(error);
  });
}




export const fetchRequests = async (
 customerId: number,
 limit: number,
 currentPage:number
) => {

  try {
    const config = await getTokenConfig();
    let queryString = `${process.env.REACT_APP_API_URL_RECHARGES}/passport/report/thirtyDaysAgo?customerId=${customerId}&limit=${limit}&page=${currentPage}`;


    const response = await axios.get(queryString, config);

    const transactionsRequest = response.data.data.transactions;

    const timezone = response.data.data.timezone;

    const totalPages = Math.ceil(response.data.data.total / limit);

    return { transactionsRequest, totalPages, timezone };
  } catch (err) {
    throw err;
  }
};


export const uploadDirectlyById = async (file: any, transactionId: number) => {
  const config = await getTokenConfig();

  const base64File = await toBase64(file);



  const url = `${process.env.REACT_APP_API_URL_RECHARGES}/upload/image`;
  try {
      const response = await axios({
          method: 'post',
          url: url,
          data: {
              transactionId: transactionId,
              fileName: file.name,
              fileType: file.type,
              fileContent: base64File
          },
          headers: {
              ...config.headers,
              'Content-Type': 'application/json'
          },
      });

      // Si estás esperando alguna respuesta específica desde Lambda, puedes procesarla aquí.
      return response.data.fileUrl;

  } catch (err: any) {
      throw err;
  }
};
