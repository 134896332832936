import axios, { AxiosResponse } from 'axios';
import { getTokenConfig } from '../../../../../src/auth'
interface VerifierResponse {
    signedUrl: string;
    // Puedes agregar más propiedades aquí si el verificador devuelve más información relevante.
}



export const fetchRequests = async (
    config: any,
    limit: number,
    currentPage: number,
    searchValue: string,
    searchStatus: string,
    customerId?: string,
    provider?: string,
    datePaymentInit?: string,
    datePaymentEnd?: string,
    orderType?: string,
    orderDirection?: string,
   
) => {


    try {
        let queryString = `${process.env.REACT_APP_API_URL_RECHARGES}/payment-service/report?limit=${limit}&page=${currentPage}`;


        if (orderType === 'provider,asc') {
            queryString += `&order=provider,${orderDirection}`;
        } else if (orderType === 'id,asc') {
            queryString += `&order=id,${orderDirection}`;
        } else if (orderType === 'name,asc') {
            queryString += `&order=name,${orderDirection}`;
        } else if (orderType === 'category,asc') {
            queryString += `&order=category,${orderDirection}`;
        } else if (orderType === 'reference,asc') {
            queryString += `&order=reference,${orderDirection}`;
        } else if (orderType === 'amountTotal,asc') {
            queryString += `&order=amountTotal,${orderDirection}`;
        } else if (orderType === 'comision,asc') {
            queryString += `&order=comision,${orderDirection}`;
        } else if (orderType === 'balanceProvider,asc') {
            queryString += `&order=balanceProvider,${orderDirection}`;
        } else if (orderType === 'balanceCustomer,asc') {
            queryString += `&order=balanceCustomer,${orderDirection}`;
        } else if (orderType === 'status,asc') {
            queryString += `&order=status,${orderDirection}`;
        } else if (orderType === 'noAut,asc') {
            queryString += `&order=noAut,${orderDirection}`;
        } else if (orderType === 'datetimePayment,asc') {
            queryString += `&order=datetimePayment,${orderDirection}`;
        }





        if (searchValue) {
            queryString += `&search=${searchValue}`;
        }

        if (searchStatus) {
            queryString += `&status=${searchStatus}`;
        }


        if (datePaymentInit) {
            queryString += `&datePaymentInit=${datePaymentInit}`;
        }

        if (datePaymentEnd) {
            queryString += `&datePaymentEnd=${datePaymentEnd}`;
        }


        if (customerId) { // agregar el filtro por cadena
            queryString += `&customerId=${customerId}`;
        }

        if (provider) { // agregar el filtro por cadena
            queryString += `&provider=${provider}`;
        }


        const response = await axios.get(queryString, config);


        const transactions = response.data.data.transactions;

        const timezone = response.data.data.timezone;

        const totalPages = Math.ceil(response.data.data.total / limit);

        return { transactions, totalPages, timezone };
    } catch (err) {
        throw err;
    }
};


export const fetchCatalogs = async () => {
    try {
        let queryString = `${process.env.REACT_APP_API_URL_RECHARGES}/list/categories/payment-services`;

        const config = await getTokenConfig(); // Assuming getTokenConfig is a function that returns a config object for axios
        const response = await axios.get(queryString, config);
        const listCategories = response.data.data.categories;

       

        return { listCategories };
    } catch (err) {
        throw err;
    }
};

export const fetchCustomers = async (
    config: any
) => {
    try {
        let queryString = `${process.env.REACT_APP_API_URL}/admin/list-customers`;
        const response = await axios.get(queryString, config);

        const customers = response.data.data.customers;

        return { customers };
    } catch (err) {
        throw err;
    }
};


export const downloadImage = async (config: any, nameReport: string): Promise<string | null> => {

    try {
        const verifyUrl = `${process.env.REACT_APP_API_URL_RECHARGES}/download/image`;
        const response: AxiosResponse<VerifierResponse> = await axios.get(verifyUrl, {
            ...config,
            params: {
                filename: nameReport
            }
        });


        console.log(response.data.signedUrl); // Verifica el resultado del verificador en la consola

        const signedUrl = response.data.signedUrl;
        return signedUrl || null; // Devuelve el signedUrl si está presente, o null si no lo está.
    } catch (error) {
        console.error("Error al verificar si el archivo está listo:", error);
        return null;
    }
};


export const fetchExcelReport = async (config: any,
    searchValue: string,
    searchStatus: string,
    customerId: string,
    provider: string,
    datePaymentInit: string,
    datePaymentEnd: string,
    orderType: string,
    ) => {

    try {

   
        const baseUrl = `${process.env.REACT_APP_API_URL_RECHARGES}/download/payment-service/report?`;

        // Definir los parámetros en un objeto para facilitar la construcción dinámica
        const queryParams = {
            search: searchValue,
            status: searchStatus,
            customerId: customerId,
            datePaymentInit: datePaymentInit,
            datePaymentEnd: datePaymentEnd,
            provider: provider,
            orderType: orderType,
            orderDirection: orderType,
         
        };

        // Filtrar y construir la cadena de consulta
        const queryString = baseUrl + Object.entries(queryParams)
            .filter(([key, value]) => value && value !== "")
            .map(([key, value]) => `${key}=${value}`)
            .join("&");

        const response = await axios.get(queryString, config);
        const nameReport = response.data.data.name;

        if (!nameReport) {
            console.error("Error al obtener el nombre del archivo.");
            return null;
        }

        let signedUrl: string | null = null;
        const interval = 5000; // Intervalo en milisegundos (en este caso, 5 segundos)
        const maxAttempts = 36; // Número máximo de intentos (12 x 5 segundos = 1 minuto)

        let attempts = 0;
        while (!signedUrl && attempts < maxAttempts) {
            signedUrl = await verifyFileReady(config, nameReport);
            if (!signedUrl) {
                await new Promise((resolve) => setTimeout(resolve, interval)); // Esperar el intervalo antes de verificar nuevamente.
                attempts++;
            }
        }

        return signedUrl;
    } catch (error) {
        console.error("Error al obtener el nombre del archivo:", error);
        return null;
    }
};


export const fetchExcelConsolidate = async (config: any,
    searchValue: string,
    searchStatus: string,
    customerId: string,
    provider: string,
    datePaymentInit: string,
    datePaymentEnd: string,
    orderType: string,
  ) => {

    try {
        const baseUrl = `${process.env.REACT_APP_API_URL_RECHARGES}/download/payment-service/consolidate?`;

        // Definir los parámetros en un objeto para facilitar la construcción dinámica
        const queryParams = {
            search: searchValue,
            status: searchStatus,
            customerId: customerId,
            datePaymentInit: datePaymentInit,
            datePaymentEnd: datePaymentEnd,
            provider: provider,
            orderType: orderType,
            orderDirection: orderType,
          
        };

        // Filtrar y construir la cadena de consulta
        const queryString = baseUrl + Object.entries(queryParams)
            .filter(([key, value]) => value && value !== "")
            .map(([key, value]) => `${key}=${value}`)
            .join("&");

        const response = await axios.get(queryString, config);
        const nameReport = response.data.data.name;

        if (!nameReport) {
            console.error("Error al obtener el nombre del archivo.");
            return null;
        }

        let signedUrl: string | null = null;
        const interval = 5000; // Intervalo en milisegundos (en este caso, 5 segundos)
        const maxAttempts = 36; // Número máximo de intentos (12 x 5 segundos = 1 minuto)

        let attempts = 0;
        while (!signedUrl && attempts < maxAttempts) {
            signedUrl = await verifyFileReady(config, nameReport);
            if (!signedUrl) {
                await new Promise((resolve) => setTimeout(resolve, interval)); // Esperar el intervalo antes de verificar nuevamente.
                attempts++;
            }
        }

        return signedUrl;
    } catch (error) {
        console.error("Error al obtener el nombre del archivo:", error);
        return null;
    }
};



const verifyFileReady = async (config: any, nameReport: string): Promise<string | null> => {
    try {
        const verifyUrl = `${process.env.REACT_APP_API_URL_RECHARGES}/download/payment-service/report?mode=check&filename=${nameReport}`;

        console.log(config)
        const response: AxiosResponse<VerifierResponse> = await axios.get(verifyUrl, config);

        console.log(response.data); // Verifica el resultado del verificador en la consola

        const signedUrl = response.data.signedUrl;
        return signedUrl || null; // Devuelve el signedUrl si está presente, o null si no lo está.
    } catch (error) {
        console.error("Error al verificar si el archivo está listo:", error);
        return null;
    }
};