import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { useIntl } from 'react-intl'
import moment from 'moment';
import 'moment-timezone';

import Pagination from './Pagination'
import { fetchCustomers, fetchExcelLogsByEventReport, fetchExcelReport, fetchRequests } from './apis/requestsApi'

import Swal, { SweetAlertOptions } from 'sweetalert2'
import { JourneyEvent } from './core/Report.Model';
import { getTokenConfig } from '../../../auth';
import { ICustomer } from '../reportsValidateIdentity/core/Report.Model';


const ReportList: React.FC = () => {
  const intl = useIntl()

  const [data, setData] = useState<JourneyEvent[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [totalPages, setTotalPages] = useState(0)

  const [searchValue, setSearchValue] = useState('')
  const [searchCustomer, setSearchCustomer] = useState('')
  const [searchAuthorize, setSearchAuthorize] = useState('')
  const [searchStatus, setSearchStatus] = useState('')
  const [orderType, setOrderType] = useState<string>('status,name,asc')

  const [orderDirection, setOrderDirection] = useState<'asc' | 'desc'>('asc')

  const [customers, setCustomer] = useState<ICustomer[]>([])
  const [createdAtDateInit, setcreatedAtDateInit] = useState('')
  const [createdAtDateEnd, setcreatedAtDateEnd] = useState('')
  const [searchChain, setSearchChain] = useState('')
  const [timezone, setTimezone] = useState('');

  interface CustomSweetAlertOptions extends SweetAlertOptions {
    onOpen?: () => void
  }

  const handleReset = async () => {
    setCurrentPage(1)
    await new Promise<void>((resolve) => {
      setSearchValue('')
      setSearchStatus('')
      setcreatedAtDateInit('')
      setcreatedAtDateEnd('')
      setSearchChain('')
      setSearchCustomer('')
      setSearchAuthorize('')
      resolve()
    })
  }
  useEffect(() => {
    if (
      searchValue === '' &&
      searchStatus === '' &&
      createdAtDateInit === '' &&
      createdAtDateEnd === '' &&
      searchChain === '' &&
      searchCustomer === '' &&
      searchAuthorize === ''
  
    ) {
      handleSearch()
    }
  }, [searchValue, searchStatus, createdAtDateInit, createdAtDateEnd, searchChain, searchCustomer, searchAuthorize])

  const handleOrderChange = (newOrderType: string) => {
    setOrderType(newOrderType)

    setOrderDirection((prevDirection) => (prevDirection === 'asc' ? 'desc' : 'asc'))
  }




  useEffect(() => {


    const fetchData = async () => {
      setIsLoading(true); // Establecer isLoading como true al iniciar la carga de datos
      setError(null)


      try {


        const config = await getTokenConfig()
        const { requests, totalPages, timezone } = await fetchRequests(
          config,
          limit,
          currentPage,
          searchValue,
          createdAtDateInit,
          createdAtDateEnd,
          orderType,
          orderDirection,
        
          searchCustomer, // incluir el filtro por cadena
        
        searchAuthorize

        )

        setData(requests)
        setTimezone(timezone)

        setTotalPages(totalPages)
        setIsLoading(false)

      } catch (err: any) {
        setError(err)
        setIsLoading(false)
      }
    }

    fetchData()
  }, [currentPage, limit, orderType, orderDirection])

  useEffect(() => {

    const fetchData = async () => {

      setError(null)
      try {
        const config = await getTokenConfig()
     
        const { customers } = await fetchCustomers(config);


        setCustomer(customers);



      } catch (err: any) {
        setError(err)

      }
    }

    fetchData()
  }, []) // Arreglo de dependencias vacío

  const handleDownload = async () => {
    try {
      Swal.fire({
        html: `
        <div class="spinner-container" style="overflow: hidden;">
          <i class="fas fa-spinner fa-spin"></i>
          <span>Generando reporte...</span>
        </div>
        `,
        showConfirmButton: false,
        allowOutsideClick: false,
      } as CustomSweetAlertOptions);

      const config = await getTokenConfig();
      const url = await fetchExcelReport(
        config,
        searchValue,
        createdAtDateInit,
        createdAtDateEnd,
        orderType,
        orderDirection,
     
      );

      if (url !== null) {
        const link = document.createElement('a');
        link.href = url;
        link.download = 'customer_payment_report.csv';
        link.click();
      } else {
        console.error("No se pudo obtener la URL del reporte o el reporte aún no está listo.");
      }

      Swal.close();
    } catch (err: any) {
      Swal.close();
      console.error(err);
    }
  };

  const handleDownloadLogs = async (itemId: number) => {
    try {
      Swal.fire({
        html: `
          <div class="spinner-container" style="overflow: hidden;">
            <i class="fas fa-spinner fa-spin"></i>
            <span>Generando reporte...</span>
          </div>
        `,
        showConfirmButton: false,
        allowOutsideClick: false,
      } as CustomSweetAlertOptions)
  
      const url = await fetchExcelLogsByEventReport(

        itemId
      )
  
      if (url !== null) {
        const link = document.createElement('a');
        link.href = url;
        link.download = 'customer_validate_identity_report.csv';
        link.click();
      } else {
        console.error("No se pudo obtener la URL del reporte o el reporte aún no está listo.");
      }
  
      Swal.close()
    } catch (err: any) {
      console.error(err)
    }
  }



  const memoizedData = useMemo(() => data, [data])
  const handleSearch = async () => {
    setCurrentPage(1)

    setIsLoading(true)
    setError(null)

    try {
      const config = await getTokenConfig()
      const { requests, totalPages, timezone } = await fetchRequests(
        config,
        limit,
        currentPage,
        searchValue,
        createdAtDateInit,
        createdAtDateEnd,
        orderType,
        orderDirection,
        searchCustomer, // incluir el filtro por cadena
      )
      setData(requests)
      setIsLoading(false)
      setTotalPages(totalPages)
      setTimezone(timezone)
    } catch (err: any) {
      setError(err)
      setIsLoading(false)
    }
  }


  if (error) {
    return <div>Ocurrió un error</div>
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-header cursor-pointer'>
        <div className='card-title m-5'>
          <h3 className='fw-bolder m-0'>
          Reporte de eventos generados
          </h3>
        </div>
      </div>
      <div className='card-body py-3 '>
        <div className='row flex-wrap mb-5'>
          <div className='col-lg-3 col-md-6 col-sm-12 mb-3'>
            <div className='form-group form-label-group'>
              <label className='form-label'>Buscar</label>
              <input
                type='text'
                onChange={(event) => setSearchValue(event.target.value)}
                className='form-control form-control form-control-lg'
                value={searchValue}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    handleSearch()
                  }
                }}
              />
            </div>
          </div>
          <div className='col-lg-3 col-md-6 col-sm-12 mb-3'>
            <div className='form-group form-label-group'>
              <label className='form-label'>      {intl.formatMessage({ id: 'REPORT.PARTNER.C' })}</label>
              <select
                value={searchCustomer}
                onChange={(event) => setSearchCustomer(event.target.value)}
                className='form-select form-select-solid form-control-lg'
              >
                <option value=''>Selecciona un socio comercial</option>
                {customers.map((customer) => (
                  <option key={customer.name} value={customer.name}>
                    {customer.name}
                  </option>
                ))}
              </select>

            </div>
          </div>

          <div className='col-lg-3 col-md-6 col-sm-12 mb-3'>
            <div className='form-group form-label-group'>
              <label className='form-label'>Fecha de inicio</label>
              <input
                type='date'
                value={createdAtDateInit}
                onChange={(event) => setcreatedAtDateInit(event.target.value)}
                className='form-control form-control form-control-lg'
              />
            </div>
          </div>
          <div className='col-lg-3 col-md-6 col-sm-12 mb-3'>
            <div className='form-group form-label-group'>
              <label className='form-label'>Fecha fin</label>
              <input
                type='date'
                value={createdAtDateEnd}
                onChange={(event) => setcreatedAtDateEnd(event.target.value)}
                className='form-control form-control form-control-lg'
              />
            </div>
          </div>
         
        </div>
        <div className='row  flex-wrap mb-5'>
          <div className='col-lg-12 col-md-12 col-sm-12 align-bottom justify-content-end'>
            <div className='form-group d-flex justify-content-end'>
              <div className='split-button'>
                <button onClick={handleSearch} className='btn btn-primary rounded-pill'>
                  Buscar
                </button>
                <button onClick={handleReset} className='btn btn-secondary rounded-pill'>
                  Borrar
                </button>
                <button onClick={handleDownload} className='btn btn-secondary rounded-pill'>
                  Descargar reporte
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className='table-responsive mb-20'>
          {isLoading ? (
            <p className='mb-0'>
              <strong>Cargando...</strong>
            </p>
          ) : data.length === 0 ? (
            <p className='mb-0'>
              <strong>No hay resultados disponibles</strong>
            </p>
          ) : (
            <table className='table table-striped' id='table'>
              <thead>
                <tr className='fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200'>
                  <th onClick={() => handleOrderChange('id,asc')} className='min-w-50px'>
                    Id del evento
                  </th>
                  <th  onClick={() => handleOrderChange('id,uuid')} className='min-w-50px'>
                    UUID
                  </th>
                  <th onClick={() => handleOrderChange('journeyName,asc')} className='min-w-150px'>
                    Nombre del journey
                  </th>
                  <th onClick={() => handleOrderChange('createdAt,asc')} className='min-w-150px'>
                    Fecha de creación 
                  </th>
                
                  <th onClick={() => handleOrderChange('urlEntries,asc')} className='min-w-150px'>
                    Cantidad de ingresos a la URL
                  </th>
                  <th onClick={() => handleOrderChange('finishTime,asc')} className='min-w-150px'>
                    Hora de finalización 
                  </th>
                
                  <th onClick={() => handleOrderChange('userGenerator,asc')} className='min-w-150px'>
                    Usuario que lo generó
                  </th>
                  <th onClick={() => handleOrderChange('actions,asc')} className='min-w-150px'>
                    Acciones
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => {
                  const count = ((currentPage - 1) * limit) + index + 1;
                  return (
                    <tr key={item.id}>
                      <td style={{ width: '14ch' }}>{item.id}</td>
                      <td style={{ width: '14ch' }}>{item.uuid}</td>
                      <td style={{ width: '14ch' }}>{item.journeyName}</td>
                      <td style={{ width: '14ch' }}>
                            {moment(item.createdAt).tz(timezone).format('DD-MM-YYYY')}
                        </td>
                      <td style={{ width: '14ch' }}>{item.countAccess}</td>
                      <td style={{ width: '14ch' }}>
                            {moment(item.endTime).tz(timezone).format('HH:mm:ss')}
                        </td>
                      
                      <td style={{ width: '14ch' }}>{item.userName}</td>
                      <td style={{ width: '14ch' }}>
                      <button
                          type='button'
                          className='btn btn-primary btn-icon btn-sm rounded-pill'
                          onClick={() => handleDownloadLogs(item.id)} // Pasamos el ID al hacer clic
                        >
                          <i className='bi bi-eye'></i>
                        </button>
                      </td>
                    </tr>
                  )
                })}
              </tbody>

            </table>
          )}

        </div>
        <div className='d-flex justify-content mb-4 same-col'>
          <div>
            <select
              value={limit}
              onChange={(event) => setLimit(Number(event.target.value))}
              className='form-select form-select-sm custom-select-page'
            >
              <option value={10}>10</option>
              <option value={15}>15</option>
              <option value={30}>30</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
          </div>
          <div>
            {data.length !== 0 && (
              <Pagination
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalPages={totalPages}
                limit={limit}
                lengthData={data.length}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export { ReportList }
