import { Navigate, Routes, Route, Outlet, useLocation} from 'react-router-dom'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import { useIntl } from 'react-intl'
import { CommissionPaymentService } from './CommissionPaymentService'
import PermissionsService from '../auth/PermissionsService';

const CommissionPaymentServicePage = () => {

  const userBreadCrumbs: Array<PageLink> = [
    {
      title: 'Passport',
      path: 'recharges',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]



  const permissions = PermissionsService.getPermissions();
  const {
    hasProductConfigCommissionPermission,

  } = permissions || {};


  return (
    <Routes>
      <Route element={<Outlet />}>

        {hasProductConfigCommissionPermission ? (
          <Route
            path='config/commission'
            element={
              <>
                <CommissionPaymentService />
                <PageTitle breadcrumbs={userBreadCrumbs}>
                Configuración de comisiones
                </PageTitle>
              </>
            }
          />

        ) : (
          <Route path='config/desc' element={<Navigate to='/error/403' replace />} />
        )}
      </Route>

      <Route index element={<Navigate to='list' />} />
    </Routes>
  )
}

export default CommissionPaymentServicePage
