import axios from 'axios'

export const saveGroup = async (config: any, data: any) => {
  try {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/groups/add`,

      data,
      config
    )
  } catch (err: any) {
    throw err
  }
}

export const updateGroup = async (config: any, data: any, id: number) => {
  try {
    return axios.put(`${process.env.REACT_APP_API_URL}/groups/${id}`, data, config)
  } catch (err: any) {
    throw err
  }
}

export const fetchSectionsByGroup = async (config: any, id: number) => {
  try {
    let queryString = `${process.env.REACT_APP_API_URL}/groups/${id}`
    const response = await axios.get(queryString, config)

    const requests = response.data.data.resources
    const {name} = response.data.data.group

    return {requests, name}
  } catch (err: any) {
    throw err
  }
}

export const fetchSections = async (config: any) => {
  try {
    let queryString = `${process.env.REACT_APP_API_URL}/groups/add`
    const response = await axios.get(queryString, config)

    const requests = response.data.data.resources

    return {requests}
  } catch (err: any) {
    throw err
  }
}

export const fetchRequests = async (
  config: any,
  limit: number,
  currentPage: number,
  searchValue: string,
  orderType: string,
  orderDirection: string,
  chainId?: string, // incluir el filtro por cadena como un parámetro opcional
  status?: string // incluir el filtro por estatus como un parámetro opcional
) => {
  try {
    let queryString = `${process.env.REACT_APP_API_URL}/groups?limit=${limit}&page=${currentPage}`

    if (orderType === 'status,asc') {
      queryString += `&order=statusCode,${orderDirection}`
    } else if (orderType === 'providerName,asc') {
      queryString += `&order=providerName,${orderDirection}`
    } else if (orderType === 'customerName,asc') {
      queryString += `&order=customerName,${orderDirection}`
    } else if (orderType === 'userName,asc') {
      queryString += `&order=userName,${orderDirection}`
    } else if (orderType === 'id,asc') {
      queryString += `&order=id,${orderDirection}`
    } else if (orderType === 'reference,asc') {
      queryString += `&order=reference,${orderDirection}`
    } else if (orderType === 'authorization,asc') {
      queryString += `&order=authorization,${orderDirection}`
    } else if (orderType === 'center,asc') {
      queryString += `&order=center,${orderDirection}`
    } else if (orderType === 'amount,asc') {
      queryString += `&order=amount,${orderDirection}`
    } else if (orderType === 'transaction,asc') {
      queryString += `&order=transaction,${orderDirection}`
    }

    if (searchValue) {
      queryString += `&search=${searchValue}`
    }

    const response = await axios.get(queryString, config)

    const requests = response.data.data.groups

    const totalPages = Math.ceil(response.data.data.total / limit)

    return {requests, totalPages}
  } catch (err) {
    throw err
  }
}
