import React, {useState, useEffect} from 'react'

import {ITimelineItem} from './core/TimelineItem.Model'

type Props = {
  data?: ITimelineItem
}

const DetailModal: React.FC<Props> = ({data}) => {
  const [dataModal, setDataModal] = useState<ITimelineItem>()
  useEffect(() => {
    setDataModal(data)
  }, [data])

  return (
    <div
      className='modal fade '
      id={`requestModal`}
      tabIndex={-1}
      aria-labelledby='exampleModalLabel'
      aria-hidden='true'
    >
      <div className='modal-dialog modal-dialog-centered modal-lg'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h1 className='modal-title fs-5' id='staticBackdropLabel'>
              Detalle
            </h1>
            <button
              type='button'
              className='btn-close rounded-pill'
              data-bs-dismiss='modal'
              aria-label='Close'
            ></button>
          </div>
          <div className='modal-body'>
            <p className='text-start ms-5'>
              <strong> Request</strong>
            </p>
            <div className='text-start ms-5'>
              {dataModal?.request && (
                <pre>{JSON.stringify(JSON.parse(dataModal?.request as string), null, 4)}</pre>
              )}
            </div>

            <p className='text-start ms-5'>
              <strong> Response</strong>
            </p>
            <div className='text-start ms-5'>
              {dataModal?.response && (
                <pre>{JSON.stringify(JSON.parse(dataModal?.response as string), null, 4)}</pre>
              )}
            </div>
          </div>
          <div className='modal-footer'>
            <button type='button' className='btn btn-secondary rounded-pill' data-bs-dismiss='modal'>
              Cerrar
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export {DetailModal}
