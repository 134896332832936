import React,{useEffect, useState} from 'react';
//import React, { useEffect, useState } from 'react';
import {  CognitoUserSession } from 'amazon-cognito-identity-js';
import UserPool from '../../../../UserPool'

const SessionExpired = () => {
  const [expired, setExpired] = useState(false);

  useEffect(() => {
    const checkSession = async () => {
      try {
        const cognitoUser = UserPool.getCurrentUser();
        if (cognitoUser) {
          await cognitoUser.getSession((err: any, session: CognitoUserSession) => {
            if (!err && session.isValid()) {
              setExpired(false);
            } else {
              setExpired(true);
            }
          });
        } else {
          setExpired(true);
        }
      } catch (error) {
        setExpired(true);
      }
    };
    checkSession();
  }, []);

  return expired;
};

export {SessionExpired}




