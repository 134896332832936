import { Navigate, Routes, Route, Outlet } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import { useIntl } from 'react-intl'

import { List } from './List'
import { AddGroupForm } from './add/AddGroupForm'
import { EditGroupForm } from './edit/EditGroupForm'
import PermissionsService from '../auth/PermissionsService';


const RolesPage = () => {
  const intl = useIntl()
  const userBreadCrumbs: Array<PageLink> = [
    {
      title: intl.formatMessage({ id: 'GROUPS.HEADER' }),
      path: '/groups',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  const permissions = PermissionsService.getPermissions();
  const {
    hasGroupAddPermission,
    hasGroupEditPermission,
    hasGroupListPermission,


  } = permissions || {};


  return (
    <Routes>
      <Route element={<Outlet />}>

        {hasGroupListPermission ? (
          <Route
            path='list'
            element={
              <>
                <List />
                <PageTitle breadcrumbs={userBreadCrumbs}>
                  {intl.formatMessage({ id: 'GROUPS.HEADER' })}
                </PageTitle>
              </>
            }
          />

        ) : (
          <Route path='list' element={<Navigate to='/error/403' replace />} />
        )}

        {hasGroupAddPermission ? (
          <Route
            path='create'
            element={
              <>
                <AddGroupForm />
                <PageTitle breadcrumbs={userBreadCrumbs}>
                  {intl.formatMessage({ id: 'GROUPS.CREATE' })}
                </PageTitle>
              </>
            }
          />
        ) : (
          <Route path='create' element={<Navigate to='/error/403' replace />} />
        )}

        {hasGroupEditPermission ? (
          <Route
            path='edit/:id'
            element={
              <>
                <EditGroupForm />
                <PageTitle breadcrumbs={userBreadCrumbs}>
                  {intl.formatMessage({ id: 'GROUPS.EDIT' })}
                </PageTitle>
              </>
            }
          />

        ) : (
          <Route path='edit' element={<Navigate to='/error/403' replace />} />
        )}

      </Route>

      <Route index element={<Navigate to='list' />} />
    </Routes>
  )
}

export default RolesPage
