// ResultsTable.tsx

import React from 'react';
import { useIntl } from 'react-intl';

import { IReport } from './core/Report.Model'

import ReportListBody from './ReportListBody'
import Pagination from './Pagination'

interface Props {
    data: IReport[];
    handleOrderChange: (orderType: string) => void;
    isLoading: boolean;
    currentPage: number;
    limit: number;
    setLimit: (value: number) => void;
    totalPages: number;
    memoizedData: IReport[];
    setCurrentPage: (value: number) => void;
    timezone: string;
}


const ResultsTable: React.FC<Props> = (props) => {
    const intl = useIntl();

    const { data, handleOrderChange, isLoading, currentPage, limit, setLimit,
         totalPages, memoizedData, setCurrentPage, timezone } = props;


    return (
        <>

            <div className='table-responsive mb-20'>
                {isLoading ? (
                    <p className='mb-0'>
                        <strong>Cargando...</strong>
                    </p>
                ) :(
                    <table className='table table-striped' id='table'>
                        <thead>
                            <tr className='fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200'>
                                <th className='min-w-50px'>#</th>
                                <th onClick={() => handleOrderChange('folio,asc')} className='min-w-150px'>
                                    Folio
                                </th>
                                <th onClick={() => handleOrderChange('name,asc')} className='min-w-150px'>
                                    {intl.formatMessage({ id: 'REPORT_RECHARGE_CLIENT' })}
                                </th>
                                <th onClick={() => handleOrderChange('provider,asc')} className='min-w-150px'>
                                    {intl.formatMessage({ id: 'REPORT_RECHARGE_CARRIER' })}
                                </th>
                                <th onClick={() => handleOrderChange('sku,asc')} className='min-w-150px'>
                                    {intl.formatMessage({ id: 'REPORT_RECHARGE_PRODUCT' })}
                                </th>
                                <th onClick={() => handleOrderChange('amountTotal,asc')} className='min-w-150px'>
                                    {intl.formatMessage({ id: 'REPORT_RECHARGE_DENOMINATION' })}
                                </th>
                                <th onClick={() => handleOrderChange('reference,asc')} className='min-w-150px'>
                                    {intl.formatMessage({ id: 'REPORT_RECHARGE_PHONE' })}
                                </th>
                                <th onClick={() => handleOrderChange('amountPayment,asc')} className='min-w-150px'>
                                    {intl.formatMessage({ id: 'REPORT_RECHARGE_AMOUNT' })}
                                </th>
                                <th onClick={() => handleOrderChange('status,asc')} className='min-w-150px'>
                                    {intl.formatMessage({ id: 'REPORT_RECHARGE_STATUS' })}
                                </th>
                                <th onClick={() => handleOrderChange('noAut,asc')} className='min-w-150px'>
                                    {intl.formatMessage({ id: 'REPORT_RECHARGE_AUTHORIZATION_NUMBER' })}
                                </th>
                                <th onClick={() => handleOrderChange('createdAt,asc')} className='min-w-150px'>
                                    {intl.formatMessage({ id: 'REPORT_RECHARGE_TRANSACTION_DATE' })}
                                </th>
                            </tr>
                        </thead>


                        <ReportListBody data={data} currentPage={currentPage} limit={limit} timezone={timezone} />
                    </table>
                )}

            </div>

            <div className='d-flex justify-content mb-4 same-col'>
                <div>
                    <select
                        value={limit}
                        onChange={(event) => setLimit(Number(event.target.value))}
                        className='form-select form-select-sm custom-select-page'
                    >
                        <option value={10}>10</option>
                        <option value={15}>15</option>
                        <option value={30}>30</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                    </select>
                </div>
                <div>
                    {memoizedData.length !== 0 && (
                        <Pagination
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            totalPages={totalPages}
                            limit={limit}
                            lengthData={memoizedData.length}
                        />
                    )}
                </div>
            </div>
        </>
    );
}

export default ResultsTable;
