import React, { useState, useEffect, useMemo, useCallback } from 'react'

import { useIntl } from 'react-intl'
import { getTokenConfig } from '../../../../src/auth'

import { IReport, ICustomer } from './core/Report.Model'
import moment from 'moment';

import Pagination from './Pagination'
import { fetchCustomers, fetchExcelReport, fetchModalData, fetchRequests } from './apis/requestsApi'

import Swal, { SweetAlertOptions } from 'sweetalert2'





const ReportList: React.FC = () => {
  const intl = useIntl()

  const [data, setData] = useState<IReport[]>([])
  const [customers, setCustomer] = useState<ICustomer[]>([])
  const [isLoading, setIsLoading] = useState(true)//false para carga
  const [error, setError] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [totalPages, setTotalPages] = useState(0)

  const [modalData, setModalData] = useState<any>(null);

  const [searchValue, setSearchValue] = useState('')
  const [searchCustomer, setSearchCustomer] = useState('')
  const [searchStatus, setSearchStatus] = useState('')
  const [orderType, setOrderType] = useState<string>('status,name,asc')
  const [timezone, setTimezone] = useState('');

  const [orderDirection, setOrderDirection] = useState<'asc' | 'desc'>('asc')
  const [createdAtDateInit, setcreatedAtDateInit] = useState('');
  const [createdAtDateEnd, setcreatedAtDateEnd] = useState('');


  const [customerRequestObj, setCustomerRequestObj] = useState<any>({});
  const [customerResponseObj, setCustomerResponseObj] = useState<any>({});
  const [providerResponseObj, setProviderResponseObj] = useState<any>({});
  const [providerRequestObj, setProviderRequestObj] = useState<any>({});


  const [firstClick, setFirstClick] = useState(true);
  const [selectedProduct, setSelectedProduct] = useState('');


  interface CustomSweetAlertOptions extends SweetAlertOptions {
    onOpen?: () => void
  }

  useEffect(() => {
    if (firstClick && data.length > 0) {

      setFirstClick(false);
    }
  }, [data, firstClick]);





  const handleReset = async () => {

    setCurrentPage(1)
    await new Promise<void>((resolve) => {
      setSearchValue('')
      setSearchStatus('')
      setcreatedAtDateInit('');
      setcreatedAtDateEnd('');
      setSearchCustomer('')
      setSelectedProduct('')

      resolve()
    })
  }

  useEffect(() => {
    if (searchValue === '' && searchStatus === '' && createdAtDateInit === '' && createdAtDateEnd === '' && searchCustomer === ''
      && selectedProduct === '') {
      handleSearch()
    }
  }, [searchValue, searchStatus, createdAtDateInit, createdAtDateEnd, searchCustomer, selectedProduct])






  const handleOrderChange = (newOrderType: string) => {
    setOrderType(newOrderType);

    setOrderDirection(prevDirection => prevDirection === 'asc' ? 'desc' : 'asc');
  };
  


  const handleDownload = async () => {
    try {

      Swal.fire({
        html: `
        <div class="spinner-container" style="overflow: hidden;>
            <i class="fas fa-spinner fa-spin"></i>
            <span>Generando reporte...</span>
          </div>
        `,
        showConfirmButton: false,
        allowOutsideClick: false,
      } as CustomSweetAlertOptions)


      const config = await getTokenConfig()
      const url = await fetchExcelReport(config, searchValue, createdAtDateInit, createdAtDateEnd,
        orderType, orderDirection, searchCustomer, selectedProduct)

      if (url !== null) {
        const link = document.createElement('a');
        link.href = url;
        link.download = 'customer_validateidentity_report.csv';
        link.click();
      } else {
        console.error("No se pudo obtener la URL del reporte o el reporte aún no está listo.");
      }


      Swal.close()
    } catch (err: any) {
      Swal.close()
      console.error(err)
    }
  }



  useEffect(() => {
    const fetchData = async () => {

      setError(null);
      try {
        const config = await getTokenConfig();
        const { customers } = await fetchCustomers(config);

        console.log(customers)
        setCustomer(customers);

      } catch (err: any) {
        setError(err);

      }
    };

    fetchData();

  }, []); // Arreglo de dependencias vacío


  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setError(null);

      try {
        const config = await getTokenConfig();
        const { requests, totalPages, timezone } = await fetchRequests(
          config,
          limit,
          currentPage,
          searchValue,
          createdAtDateInit,
          createdAtDateEnd,
          orderType,
          orderDirection,
          searchCustomer, // incluir el filtro por cadena
          selectedProduct


        );


        setData(requests);
        setIsLoading(false);
        setTotalPages(totalPages);
        setTimezone(timezone)

      } catch (err: any) {
        setError(err);
        setIsLoading(false);
      }
    };


    fetchData();

  }, [currentPage, limit, orderType, orderDirection]);



  const handleModalClick = async (id: number) => {
    try {
      const config = await getTokenConfig();
      const { data } = await fetchModalData(config, id);
      setModalData(data);
  
      // Actualizar los estados de customerRequestObj, customerResponseObj, providerRequestObj y providerResponseObj
      try {
        // Actualizar customerRequestObj
        const customerRequest = data.customerRequest || '{}';
        const parsedCustomerRequest = JSON.parse(customerRequest);
        if (parsedCustomerRequest.data && parsedCustomerRequest.data.deviceId) {
          const deviceId = parsedCustomerRequest.data.deviceId;
          const trimmedDeviceId = deviceId.substring(0, 4) + '*******' + deviceId.substring(deviceId.length - 4);
          parsedCustomerRequest.data.deviceId = trimmedDeviceId;
        }
        setCustomerRequestObj(parsedCustomerRequest);
  
        // Actualizar customerResponseObj
        const customerResponse = data.customerResponse || '{}';
        const parsedCustomerResponse = JSON.parse(customerResponse);
        // Aquí puedes hacer otras modificaciones necesarias en customerResponseObj si es necesario
        setCustomerResponseObj(parsedCustomerResponse);
  
        // Actualizar providerRequestObj
        const providerRequest = data.providerRequest || '{}';
        const parsedProviderRequest = JSON.parse(providerRequest);
        const deviceIdKeys = ['deviceid', 'imei_no','imei']; // Claves potenciales para el campo de dispositivo
        deviceIdKeys.forEach(key => {
          if (parsedProviderRequest[key]) {
            const deviceId = parsedProviderRequest[key];
            const trimmedDeviceId = deviceId.substring(0, 4) + '*******' + deviceId.substring(deviceId.length - 4);
            parsedProviderRequest[key] = trimmedDeviceId;
          }
        });
        setProviderRequestObj(parsedProviderRequest);
        
        // Actualizar providerResponseObj
        const providerResponse = data.providerResponse || '{}';
        const parsedProviderResponse = JSON.parse(providerResponse);
        deviceIdKeys.forEach(key => {
          if (parsedProviderResponse[key]) {
            const deviceId = parsedProviderResponse[key];
            const trimmedDeviceId = deviceId.substring(0, 4) + '*******' + deviceId.substring(deviceId.length - 4);
            parsedProviderResponse[key] = trimmedDeviceId;
          }
        });
        setProviderResponseObj(parsedProviderResponse);
  
      } catch {
        // Manejar errores al analizar JSON
        setCustomerRequestObj({});
        setCustomerResponseObj({});
        setProviderRequestObj({});
        setProviderResponseObj({});
      }
  
      // Puedes seguir actualizando otros estados de objetos si es necesario
      // ...
  
    } catch (error) {
      console.error('Error al obtener los datos del modal:', error);
    }
  };
  


  const memoizedData = useMemo(() => data, [data])
  const handleSearch = async () => {


    setCurrentPage(1)

    setIsLoading(true)
    setError(null)

    try {
      const config = await getTokenConfig()
      const { requests, totalPages, timezone } = await fetchRequests(
        config,
        limit,
        currentPage,
        searchValue,
        createdAtDateInit,
        createdAtDateEnd,
        orderType,
        orderDirection,
        searchCustomer, // incluir el filtro por cadena
        selectedProduct
      );
      setData(requests);
      setIsLoading(false);
      setTotalPages(totalPages);
      setTimezone(timezone)

    } catch (err: any) {
      setError(err)
      setIsLoading(false)
    }


  }

  if (isLoading) {
    return <div>{intl.formatMessage({ id: 'PLEASE.WAIT' })}</div>
  }


  if (error) {
    return <div>Ocurrió un error</div>
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-body py-3 '>

        <div className='row flex-wrap mb-5'>

          <div className='col-lg-3 col-md-6 col-sm-12 mb-3'>

            <div className='form-group form-label-group'>
              <label className='form-label'>Buscar</label>
              <input
                type='text'
                data-kt-user-table-filter='search'
                onChange={(event) => setSearchValue(event.target.value)}
                className='form-control form-control-solid form-control-lg'
                value={searchValue}
              />
            </div>
          </div>

          <div className='col-lg-3 col-md-6 col-sm-12 mb-3'>
            <div className='form-group form-label-group'>
              <label className='form-label'>Producto</label>
              <select
                value={selectedProduct}
                onChange={(event) => setSelectedProduct(event.target.value)}
                className='form-select form-select-lg'
              >
                <option value=''>Selecciona un producto</option>
                {/* Mapea sobre tus opciones de productos y crea opciones para el select */}

                <option key={1} value='GSMA Device Check'>
                  GSMA Device Check
                </option>

                <option key={2} value='HBM Device Check'>
                  HBM Device Check
                </option>


                <option key={3} value='GSMA Device Registry'>
                  GSMA Device Registry
                </option>


              </select>
            </div>
          </div>

          <div className='col-lg-3 col-md-6 col-sm-12 mb-3'>
            <div className='form-group form-label-group'>
              <label className='form-label'>{intl.formatMessage({ id: 'REPORT.PARTNER.C' })}</label>
              <select
                value={searchCustomer}
                onChange={(event) => setSearchCustomer(event.target.value)}
                className='form-select form-select-solid form-control-lg'
              >
                <option value=''>Selecciona un socio comercial</option>
                {customers.map((customer) => (
                  <option key={customer.name} value={`${customer.name} `}>
                    {customer.name}
                  </option>
                ))}
              </select>

            </div>
          </div>

          <div className='col-lg-3 col-md-6 col-sm-12 mb-3'>
            <div className='form-group form-label-group'>
              <label className='form-label'>Fecha de inicio</label>
              <input
                type='date'
                value={createdAtDateInit}
                onChange={(event) => setcreatedAtDateInit(event.target.value)}
                className='form-control form-control-solid form-control-lg'
              />
            </div>
          </div>
          <div className='col-lg-3 col-md-6 col-sm-12 mb-3'>
            <div className='form-group form-label-group'>
              <label className='form-label'>Fecha fin</label>
              <input
                type='date'
                value={createdAtDateEnd}
                onChange={(event) => setcreatedAtDateEnd(event.target.value)}
                className='form-control form-control-solid form-control-lg'
              />
            </div>
          </div>


        </div>
        <div className='row  flex-wrap mb-5'>
          <div className='col-lg-12 col-md-12 col-sm-12 align-bottom justify-content-end'>
            <div className='form-group d-flex justify-content-end'>
              <div className='split-button'>
                <button onClick={handleSearch} className='btn btn-primary rounded-pill' >
                  Buscar
                </button>
                <button onClick={handleReset} className='btn btn-secondary rounded-pill'>
                  Borrar
                </button>

                <button onClick={handleDownload} className='btn btn-secondary rounded-pill'>Descargar reporte</button>
              </div>
            </div>
          </div>

        </div>


        <div className='table-responsive mb-20'>

          {isLoading ? (
            <p className='mb-0'>
              <strong>Cargando...</strong>
            </p>
          ) : data.length === 0 ? (
            <p className='mb-0'>
              <strong>No hay resultados disponibles</strong>
            </p>
          ) : (

            < table className='table table-striped' id='table'>
              <thead>
                <tr className='fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200'>
                  <th onClick={() => handleOrderChange('id,asc')} className='min-w-50px'>
                    #
                  </th>
                  <th onClick={() => handleOrderChange('reference,asc')} className='min-w-50px'>
                    Identificador a
                  </th>

                  <th onClick={() => handleOrderChange('entityId,asc')} className='min-w-50px'>
                    IMEI
                  </th>
                  <th onClick={() => handleOrderChange('customerName,asc')} className='min-w-50px'>
                    Socio comercial
                  </th>

                  <th>
                    Producto
                  </th>

                  <th className='min-w-150px' onClick={() => handleOrderChange('createdAt,asc')}>
                    {intl.formatMessage({ id: 'IDENTITY_VALIDATION.DATE' })}
                  </th>
                  <th onClick={() => handleOrderChange('statusCode,asc')} className='min-w-150px'>
                    {intl.formatMessage({ id: 'IDENTITY_VALIDATION.STATUS' })}
                  </th>
                  <th onClick={() => handleOrderChange('email,asc')} className='min-w-150px'>
                    Email
                  </th>

                  <th onClick={() => handleOrderChange('userName,asc')} className='min-w-150px'>
                    {intl.formatMessage({ id: 'IDENTITY_VALIDATION_CUSTOMER_USERNAME' })}
                  </th>
                  <th className='min-w-150px'>
                    {intl.formatMessage({ id: 'IDENTITY_VALIDATION.ACTIONS' })}
                  </th>
                </tr>
              </thead>

              <tbody>
                {data.map((item, index) => {
                  const count = ((currentPage - 1) * limit) + index + 1;
                  return (
                    <tr key={item.id}>
                      <td style={{ width: '14ch' }}>{count}</td>
                      <td style={{ width: '14ch' }}>{item.reference}</td>
                      <td style={{ width: '14ch' }}>
                        {item.deviceId ?
                          `${item.deviceId.slice(0, 4)}*******${item.deviceId.slice(-4)}` :
                          'No disponible'
                        }
                      </td>

                      <td style={{ width: '14ch' }}>{item.customerName}</td>
                      <td style={{ width: '14ch' }}>
                        {item.subproductName}

                      </td>
                      <td style={{ width: '14ch' }}>

                        {moment(item.createdAt).tz(timezone).format('DD-MM-YYYY')}
                      </td>
                      <td style={{ width: '14ch' }}>{item.statusCode === '200' ? 'Aceptado' : 'Rechazado'}</td>





                      <td style={{ width: '14ch' }}>
                        {item.email}
                      </td>


                      <td style={{ width: '14ch' }}>{item.userName}</td>
                      <td style={{ width: '14ch' }}>

                        <button
                          type='button'
                          className='dropdown-toggle icon-dropdown'
                          data-bs-toggle='modal'
                          data-bs-target={`#requestModal-${item.id}`}
                          onClick={() => handleModalClick(item.id)}
                        >
                          <i className='bi bi-eye-fill'></i>
                        </button>

                        <div
                          className='modal fade '
                          id={`requestModal-${item.id}`}
                          tabIndex={-1}
                          aria-labelledby='exampleModalLabel'
                          aria-hidden='true'
                        >
                          <div className='modal-dialog modal-dialog-centered modal-lg'>
                            <div className='modal-content'>
                              <div className='modal-header'>
                                <h1 className='modal-title fs-5' id='staticBackdropLabel'>
                                  Detalle
                                </h1>
                                <button
                                  type='button'
                                  className='btn-close'
                                  data-bs-dismiss='modal'
                                  aria-label='Close'
                                ></button>

                              </div>
                              <div className='modal-body'>
                                {modalData && modalData.entityName === 'GSMA Device Registry' && (
                                  <>
                                    <div>
                                      <div className="row col-md-12">
                                        <h5>Solicitud</h5>
                                        <div className="mb-3 col-md-4">
                                          <label className="form-label">Identificador</label>
                                          <div>{modalData?.reference || ''}</div>
                                        </div>
                                        <div className="mb-3 col-md-4">
                                          <label className="form-label">IMEI</label>
                                          <td style={{ width: '14ch' }}>
                                            {customerRequestObj?.IMEI ?
                                              `${customerRequestObj.IMEI.slice(0, 4)}****${customerRequestObj.IMEI.slice(-4)}` :
                                              'No disponible'
                                            }
                                          </td>

                                        </div>
                                        <div className="mb-3 col-md-4">
                                          <label className="form-label">Acción</label>
                                          <div>{customerRequestObj?.action || 'No disponible'}</div>
                                        </div>

                                        <div className="mb-3 col-md-4">
                                          <label className="form-label">Razón</label>
                                          <div>{customerRequestObj?.reason || 'No disponible'}</div>
                                        </div>


                                        <div className="mb-3 col-md-4">
                                          <label className="form-label">Descripción</label>
                                          <div>{customerRequestObj?.description || 'No disponible'}</div>
                                        </div>
                                        <div className="mb-3 col-md-4">
                                          <label className="form-label">Comentarios</label>
                                          <div>{customerRequestObj?.comments || 'No disponible'}</div>
                                          {/* <div>{customerResponseObj?.statusCode === 200 ? 'Aceptado' : 'Rechazado'}</div> */}
                                        </div>

                                        <div className="mb-3 col-md-4">
                                          <label className="form-label">Fecha de solicitd</label>
                                          <div>{modalData.createdAt ? moment(modalData.createdAt).format('DD/MM/YYYY') : 'No disponible'}</div>
                                        </div>

                                      </div>


                                      <div className="row col-md-12">
                                        <h5>Respuesta</h5>
                                        <div className="mb-3 col-md-4">
                                          <label className="form-label">Estatus</label>
                                          <div>{customerRequestObj?.result || 'No disponible'}</div>
                                        </div>
                                        <div className="mb-3 col-md-4">
                                          <label className="form-label">IMEI</label>
                                          <div>{customerRequestObj?.IMEI ? `${customerRequestObj.IMEI.slice(0, 4)}*******${customerRequestObj.IMEI.slice(-4)}` : 'No disponible'}</div>

                                        </div>

                                        <div className="mb-3 col-md-4">
                                          <label className="form-label">Fecha de procesamiento</label>
                                          <div>{modalData.lastRegistrationDate ? moment(modalData.lastRegistrationDate).format('DD/MM/YYYY') : 'No disponible'}</div>


                                        </div>




                                        <div className="mb-3 col-md-12">
                                          <label className="form-label">Resultado</label>
                                          <div>{(providerResponseObj && providerResponseObj.message) ? providerResponseObj.message : 'No disponible'}</div>
                                        </div>





                                      </div>


                                    </div>
                                  </>
                                )}

                                {modalData && modalData.entityName !== 'GSMA Device Registry' && (
                                  <>
                                    <p className='text-start ms-5'>
                                      <strong>Request</strong>
                                    </p>
                                    <div className='text-start ms-5'>
                                      {providerRequestObj ? (
                                        <pre
                                          style={{
                                            fontFamily: 'monospace',
                                            fontSize: '14px',
                                            padding: '10px',
                                            backgroundColor: '#f6f8fa',
                                            borderRadius: '4px',
                                            whiteSpace: 'pre-wrap',
                                            wordWrap: 'break-word',
                                          }}
                                        >
                                          {JSON.stringify(providerRequestObj, null, 2)}
                                        </pre>
                                      ) : (
                                        <p>No response data available.</p>
                                      )}
                                    </div>

                                    <p className='text-start ms-5'>
                                      <strong>Response</strong>
                                    </p>
                                    <div className='text-start ms-5'>
                                      {providerResponseObj ? (
                                        <pre
                                          style={{
                                            fontFamily: 'monospace',
                                            fontSize: '14px',
                                            padding: '10px',
                                            backgroundColor: '#f6f8fa',
                                            borderRadius: '4px',
                                            whiteSpace: 'pre-wrap',
                                            wordWrap: 'break-word',
                                          }}
                                        >
                                          {JSON.stringify(providerResponseObj, null, 2)}
                                        </pre>
                                      ) : (
                                        <p>No response data available.</p>
                                      )}
                                    </div>
                                  </>
                                )}
                              </div>




                              <div className='modal-footer'>
                                <button type='button' className='btn btn-secondary' data-bs-dismiss='modal'>
                                  Cerrar
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>


                      </td>
                    </tr>
                  );
                })}
              </tbody>


            </table>
          )}


        </div>
        <div className='d-flex justify-content mb-4 same-col'>
          <div>
            <select
              value={limit}
              onChange={(event) => setLimit(Number(event.target.value))}
              className='form-select form-select-sm custom-select-page'
            >
              <option value={10}>10</option>
              <option value={15}>15</option>
              <option value={30}>30</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
          </div>
          <div>
            {memoizedData.length !== 0 && (
              <Pagination
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalPages={totalPages}
                limit={limit}
                lengthData={memoizedData.length}
              />
            )}
          </div>

        </div>
      </div>
    </div >
  )
}

export { ReportList }
