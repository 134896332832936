import { Navigate, Routes, Route, Outlet } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import { useIntl } from 'react-intl'
import { ReportList } from './ReportList'

import PermissionsService from '../auth/PermissionsService';


const ReportPagePassport = () => {
  const intl = useIntl()
  const userBreadCrumbs: Array<PageLink> = [
    {
      title: 'Reportes Passport',
      path: '/passport-reports',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  const permissions = PermissionsService.getPermissions();
  const {
    hasConsumptionReportTransactionPermission,

  } = permissions || {};


  return (
    <Routes>
      <Route element={<Outlet />}>

      {hasConsumptionReportTransactionPermission? (
        <Route
          path='list'
          element={
            <>
              <ReportList />
              <PageTitle breadcrumbs={userBreadCrumbs}>
                Reportes Passport
              </PageTitle>
            </>
          }
        />
       ) : (
          <Route path='list' element={<Navigate to='/error/403' replace />} />
        )} 
  
      </Route>

      <Route index element={<Navigate to='list' />} />

    </Routes>
  )
}

export default ReportPagePassport
