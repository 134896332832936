// ResultsTable.tsx

import React from 'react';
import { useIntl } from 'react-intl';

import { ITransaction } from './core/Report.Model'

import ReportListBody from './ReportListBody'
import Pagination from './Pagination'


interface Props {
    data: ITransaction[];
    handleOrderChange: (orderType: string) => void;
    isLoading: boolean;
    currentPage: number;
    limit: number;
    setLimit: (value: number) => void;
    totalPages: number;
    memoizedData: ITransaction[];
    setCurrentPage: (value: number) => void;

    handleDownload: (image: string) => Promise<void>;
    handleSearch: () => void; 
    timezone: string;
}


const ResultsTable: React.FC<Props> = (props) => {
    const intl = useIntl();

    const { data, handleOrderChange, isLoading, currentPage, limit, setLimit, totalPages,
         memoizedData, setCurrentPage, handleDownload, handleSearch, timezone } = props;

    console.log("datos", data)
    return (
        <>

            <div className='table-responsive mb-20'>
                {isLoading ? (
                    <p className='mb-0'>
                        <strong>Cargando...</strong>
                    </p>
                ) : data.length === 0 ? (
                    <p className='mb-0'>
                        <strong>No hay resultados disponibles</strong>
                    </p>
                ) : (
                    <table className='table table-striped' id='table'>
                        <thead>
                            <tr className='fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200'>
                                <th className='min-w-50px'>#</th>
                                <th onClick={() => handleOrderChange('nameCustomer,asc')} className='min-w-150px'>
                                    {intl.formatMessage({ id: 'REPORT_PASSPORT_CLIENT' })}
                                </th>
                                <th onClick={() => handleOrderChange('typesMoneybagCustomerId,asc')} className='min-w-150px'>
                                    {intl.formatMessage({ id: 'REPORT_PASSPORT_BAG' })}
                                </th>
                                <th onClick={() => handleOrderChange('typesTransactionsMoneybagCustomerId,asc')} className='min-w-150px'>
                                    {intl.formatMessage({ id: 'REPORT_PASSPORT_MOVEMENT_TYPE' })}
                                </th>
                                <th onClick={() => handleOrderChange('accountBank,asc')} className='min-w-150px'>
                                    {intl.formatMessage({ id: 'REPORT_PASSPORT_BANK_ACCOUNT' })}
                                </th>
                                <th onClick={() => handleOrderChange('typesPaymentsMoneybagCustomerId,asc')} className='min-w-150px'>
                                    {intl.formatMessage({ id: 'REPORT_PASSPORT_PAYMENT_METHOD' })}
                                </th>
                                <th onClick={() => handleOrderChange('amount,asc')} className='min-w-150px'>
                                    {intl.formatMessage({ id: 'REPORT_PASSPORT_AMOUNT' })}
                                </th>
                                <th onClick={() => handleOrderChange('noAut,asc')} className='min-w-150px'>
                                    {intl.formatMessage({ id: 'REPORT_PASSPORT_AUTHORIZATION_NUMBER' })}
                                </th>
                                <th onClick={() => handleOrderChange('datePayment,asc')} className='min-w-150px'>
                                    {intl.formatMessage({ id: 'REPORT_PASSPORT_PAYMENT_DATE' })}
                                </th>

                                <th onClick={() => handleOrderChange('note,asc')} className='min-w-150px'>
                                    {intl.formatMessage({ id: 'REPORT_PASSPORT_NOTE' })}
                                </th>

                                <th onClick={() => handleOrderChange('createdBy,asc')} className='min-w-150px'>
                                    Creado por
                                </th>
                                <th onClick={() => handleOrderChange('createdAt,asc')} className='min-w-150px'>
                                Fecha de creación
                                </th>
                                <th>Imagen</th>


                            </tr>
                        </thead>

                        <ReportListBody data={data} currentPage={currentPage} limit={limit} 
                        handleDownload={handleDownload} handleSearch={handleSearch} 
                        timezone={timezone}
                        />
                    </table>
                )}

            </div>

            <div className='d-flex justify-content mb-4 same-col'>
                <div>
                    <select
                        value={limit}
                        onChange={(event) => setLimit(Number(event.target.value))}
                        className='form-select form-select-sm custom-select-page'
                    >
                        <option value={10}>10</option>
                        <option value={15}>15</option>
                        <option value={30}>30</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                    </select>
                </div>
                <div>
                    {memoizedData.length !== 0 && (
                        <Pagination
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            totalPages={totalPages}
                            limit={limit}
                            lengthData={memoizedData.length}
                        />
                    )}
                </div>
            </div>
        </>
    );
}

export default ResultsTable;
