import React, {useCallback} from 'react'
import moment from 'moment'
import {Link} from 'react-router-dom'

import {Reference} from './core/References.Model'

type Props = {
  data: Reference[]
}

const ReferenceListBody: React.FC<Props> = React.memo(({data}) => {
  return (
    <tbody>
      {data.map((item) => (
        <tr key={item.id}>
          <td>{item.id}</td>
          <td>{item.reference}</td>
          <td>{item.providerName}</td>
          <td>{item.userName}</td>
          <td>{new Date(item.lastRegistrationDate).toLocaleDateString('es-ES')}</td>

          <td>{new Date(item.createdAt).toLocaleDateString('es-ES')}</td>

          <td>
            <div className='btn-group'>
              <button
                type='button'
                className='dropdown-toggle icon-dropdown'
                data-bs-config='{"popperConfig":{"strategy":"fixed"}}'
                data-bs-toggle='dropdown'
                aria-haspopup='true'
                aria-expanded='false'
                data-bs-boundary='viewport'
              >
                <i className='bi bi-filter-left'></i>
              </button>
              <ul className='dropdown-menu '>
                <li>
                  <Link to={`/references/detail/${item.reference}/${item.customerId}`} className='dropdown-item'>
                    Ver detalles
                  </Link>
                </li>
              </ul>
            </div>
          </td>
        </tr>
      ))}
    </tbody>
  )
})

export default ReferenceListBody
