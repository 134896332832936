import { reference } from '@popperjs/core';
import axios from 'axios';
import { getTokenConfig } from '../../../../auth';

export const fetchReferences = async (
    config: any,
    limit: number,
    currentPage: number,
    searchValue: string,
    lastRegistrationDateInit: string,
    lastRegistrationDateEnd: string,
    orderType: string,
    orderDirection: string,
    clientId?: number
) => {
    try {
        let queryString = `${process.env.REACT_APP_API_URL}/references?limit=${limit}&page=${currentPage}&customerId=${clientId}`;

        if (orderType && orderDirection) {
            queryString += `&order=${orderType},${orderDirection}`;
        }

        if (searchValue) {
            queryString += `&search=${searchValue}`;
        }

        if (lastRegistrationDateInit) {
            queryString += `&lastRegistrationDateInit=${lastRegistrationDateInit}`;
        }

        if (lastRegistrationDateEnd) {
            queryString += `&lastRegistrationDateEnd=${lastRegistrationDateEnd}`;
        }

        const response = await axios.get(queryString, config);

        const references = response.data.data.references;

        const totalPages = Math.ceil(response.data.data.total / limit);

        return { references, totalPages };
    } catch (err) {
        throw err;
    }
};



export const fetchReferencesDetail = async (
    config: any,
    reference: string
) => {
    try {
        let queryString = `${process.env.REACT_APP_API_URL}/requests/${reference}`;
       
        console.log(queryString)
        const response = await axios.get(queryString, config);

        const requests = response.data.data.requests;

        const totalRegisters = Math.ceil(response.data.data.total);

        return { requests, totalRegisters };
    } catch (err) {
        throw err;
    }
};
