import React, { useState, useEffect, useMemo, useCallback } from 'react'

import { useIntl } from 'react-intl'
import { getTokenConfig } from '../../../../src/auth'

import { IReport } from './core/Report.Model'
import moment from 'moment';

import Pagination from './Pagination'
import { fetchCustomers, fetchExcelReport, fetchModalData, fetchRequests } from './apis/requestsApi'
import ReportModal from './ReportModal';
import Swal, { SweetAlertOptions } from 'sweetalert2'
import { ICustomer } from '../reportsValidateIdentity/core/Report.Model';





const ReportList: React.FC = () => {
  const intl = useIntl()

  const [data, setData] = useState<IReport[]>([])
  const [customers, setCustomer] = useState<ICustomer[]>([])
  const [isLoading, setIsLoading] = useState(true)//false para carga
  const [error, setError] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [totalPages, setTotalPages] = useState(0)

  const [modalData, setModalData] = useState<any>(null);

  const [searchValue, setSearchValue] = useState('')
  const [searchCustomer, setSearchCustomer] = useState('')
  const [searchStatus, setSearchStatus] = useState('')
  const [orderType, setOrderType] = useState<string>('status,name,asc')
  const [timezone, setTimezone] = useState('');

  const [orderDirection, setOrderDirection] = useState<'asc' | 'desc'>('asc')
  const [createdAtDateInit, setcreatedAtDateInit] = useState('');
  const [createdAtDateEnd, setcreatedAtDateEnd] = useState('');


  const [modalVisible, setModalVisible] = useState(false);
  const [selectedReport, setSelectedReport] = useState<IReport | null>(null);

  const [firstClick, setFirstClick] = useState(true);


  interface CustomSweetAlertOptions extends SweetAlertOptions {
    onOpen?: () => void
  }

  useEffect(() => {
    if (firstClick && data.length > 0) {
      setSelectedReport(data[0]);
      setModalVisible(true);
      setFirstClick(false);
    }
  }, [data, firstClick]);


  const handleCloseModal = useCallback(() => {
    setSelectedReport(null);
    setModalVisible(false);
  }, []);


  const handleReset = async () => {

    setCurrentPage(1)
    await new Promise<void>((resolve) => {
      setSearchValue('')
      setSearchStatus('')
      setcreatedAtDateInit('');
      setcreatedAtDateEnd('');
      setSearchCustomer('')


      resolve()
    })
  }

  useEffect(() => {
    if (searchValue === '' && searchStatus === '' && createdAtDateInit === '' && createdAtDateEnd === '' && searchCustomer === '') {
      handleSearch()
    }
  }, [searchValue, searchStatus, createdAtDateInit, createdAtDateEnd, searchCustomer])



    


  const handleOrderChange = (newOrderType: string) => {
    setOrderType(newOrderType);

    setOrderDirection(prevDirection => prevDirection === 'asc' ? 'desc' : 'asc');
  };


  const handleDownload = async () => {
    try {

      Swal.fire({
        html: `
        <div class="spinner-container" style="overflow: hidden;>
            <i class="fas fa-spinner fa-spin"></i>
            <span>Generando reporte...</span>
          </div>
        `,
        showConfirmButton: false,
        allowOutsideClick: false,
      } as CustomSweetAlertOptions)


      const config = await getTokenConfig()
      const url = await fetchExcelReport(config, searchValue, createdAtDateInit, createdAtDateEnd, 
        orderType, orderDirection, searchCustomer)

      if (url !== null) {
        const link = document.createElement('a');
        link.href = url;
        link.download = 'customer_validateidentity_report.csv';
        link.click();
      } else {
        console.error("No se pudo obtener la URL del reporte o el reporte aún no está listo.");
      }


      Swal.close()
    } catch (err: any) {
      Swal.close()
      console.error(err)
    }
  }



  useEffect(() => {
    const fetchData = async () => {

      setError(null);
      try {
        const config = await getTokenConfig();
        const { customers } = await fetchCustomers(config);

        console.log(customers)
        setCustomer(customers);

      } catch (err: any) {
        setError(err);

      }
    };

    fetchData();

  }, []); // Arreglo de dependencias vacío


  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setError(null);

      try {
        const config = await getTokenConfig();
        const { requests, totalPages,timezone } = await fetchRequests(
          config,
          limit,
          currentPage,
          searchValue,
          createdAtDateInit,
          createdAtDateEnd,
          orderType,
          orderDirection,
          searchCustomer, // incluir el filtro por cadena
        


        );
        

        setData(requests);
        setIsLoading(false);
        setTotalPages(totalPages);
        setTimezone(timezone)

      } catch (err: any) {
        setError(err);
        setIsLoading(false);
      }
    };


    fetchData();

  }, [currentPage, limit, orderType, orderDirection]);



  const handleModalClick = async (id: number) => {
    try {
      const config = await getTokenConfig();
      const { data } = await fetchModalData(config, id); // Reemplaza 'fetchModalData' con la función que realiza la petición para obtener los datos del modal
      setModalData(data);
      setModalVisible(true);
    } catch (error) {
      console.error('Error al obtener los datos del modal:', error);
    }
  };



  const memoizedData = useMemo(() => data, [data])
  const handleSearch = async () => {


    setCurrentPage(1)

    setIsLoading(true)
    setError(null)

    try {
      const config = await getTokenConfig()
      const { requests, totalPages,timezone } = await fetchRequests(
        config,
        limit,
        currentPage,
        searchValue,
        createdAtDateInit,
        createdAtDateEnd,
        orderType,
        orderDirection,
        searchCustomer, // incluir el filtro por cadena

      );
      setData(requests);
      setIsLoading(false);
      setTotalPages(totalPages);
      setTimezone(timezone)

    } catch (err: any) {
      setError(err)
      setIsLoading(false)
    }


  }

  if (isLoading) {
    return <div>{intl.formatMessage({ id: 'PLEASE.WAIT' })}</div>
  }


  if (error) {
    return <div>Ocurrió un error</div>
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-body py-3 '>

        <div className='row flex-wrap mb-5'>

          <div className='col-lg-3 col-md-6 col-sm-12 mb-3'>

            <div className='form-group form-label-group'>
              <label className='form-label'>Buscar</label>
              <input
                type='text'
                data-kt-user-table-filter='search'
                onChange={(event) => setSearchValue(event.target.value)}
                className='form-control form-control-solid form-control-lg'
                value={searchValue}
              />
            </div>
          </div>
          <div className='col-lg-3 col-md-6 col-sm-12 mb-3'>
            <div className='form-group form-label-group'>
              <label className='form-label'>{intl.formatMessage({ id: 'REPORT.PARTNER.C' })}</label>
              <select
                value={searchCustomer}
                onChange={(event) => setSearchCustomer(event.target.value)}
                className='form-select form-select-solid form-control-lg'
              >
                <option value=''>Selecciona un socio comercial</option>
                {customers.map((customer) => (
                  <option key={customer.name} value={`${customer.name} `}>
                    {customer.name}
                  </option>
                ))}
              </select>

            </div>
          </div>

          <div className='col-lg-3 col-md-6 col-sm-12 mb-3'>
            <div className='form-group form-label-group'>
              <label className='form-label'>Fecha de inicio</label>
              <input
                type='date'
                value={createdAtDateInit}
                onChange={(event) => setcreatedAtDateInit(event.target.value)}
                className='form-control form-control-solid form-control-lg'
              />
            </div>
          </div>
          <div className='col-lg-3 col-md-6 col-sm-12 mb-3'>
            <div className='form-group form-label-group'>
              <label className='form-label'>Fecha fin</label>
              <input
                type='date'
                value={createdAtDateEnd}
                onChange={(event) => setcreatedAtDateEnd(event.target.value)}
                className='form-control form-control-solid form-control-lg'
              />
            </div>
          </div>


        </div>
        <div className='row  flex-wrap mb-5'>
          <div className='col-lg-12 col-md-12 col-sm-12 align-bottom justify-content-end'>
            <div className='form-group d-flex justify-content-end'>
              <div className='split-button'>
                <button onClick={handleSearch} className='btn btn-primary rounded-pill' >
                  Buscar
                </button>
                <button onClick={handleReset} className='btn btn-secondary rounded-pill'>
                  Borrar
                </button>

                <button onClick={handleDownload} className='btn btn-secondary rounded-pill'>Descargar reporte</button>
              </div>
            </div>
          </div>

        </div>


        <div className='table-responsive mb-20'>

          {isLoading ? (
            <p className='mb-0'>
              <strong>Cargando...</strong>
            </p>
          ) : data.length === 0 ? (
            <p className='mb-0'>
              <strong>No hay resultados disponibles</strong>
            </p>
          ) : (

            < table className='table table-striped' id='table'>
              <thead>
                <tr className='fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200'>
                  <th onClick={() => handleOrderChange('id,asc')} className='min-w-50px'>
                    #
                  </th>
                  <th className='min-w-150px' onClick={() => handleOrderChange('id,asc')}>
                    {intl.formatMessage({ id: 'IDENTITY_VALIDATION.TRANSACTION_ID' })}
                  </th>
                  <th className='min-w-150px' onClick={() => handleOrderChange('customerName,asc')}>
                    {intl.formatMessage({ id: 'REPORT.PARTNER.C' })}
                  </th>
                  <th className='min-w-150px' onClick={() => handleOrderChange('productName,asc')}>
                    {intl.formatMessage({ id: 'IDENTITY_VALIDATION.PRODUCT' })}
                  </th>
                  <th onClick={() => handleOrderChange('subproductName,asc')} className='min-w-150px'>
                    {intl.formatMessage({ id: 'IDENTITY_VALIDATION.SUBPRODUCT' })}
                  </th>
                  <th className='min-w-150px' onClick={() => handleOrderChange('createdAt,asc')}>
                    {intl.formatMessage({ id: 'IDENTITY_VALIDATION.DATE' })}
                  </th>
                  <th onClick={() => handleOrderChange('id,asc')} className='min-w-150px'>
                    {intl.formatMessage({ id: 'IDENTITY_VALIDATION.STATUS' })}
                  </th>
                  <th onClick={() => handleOrderChange('email,asc')} className='min-w-150px'>
                    {intl.formatMessage({ id: 'IDENTITY_VALIDATION.EMAIL' })}
                  </th>
                  <th onClick={() => handleOrderChange('userName,asc')} className='min-w-150px'>
                    {intl.formatMessage({ id: 'IDENTITY_VALIDATION_CUSTOMER_USERNAME' })}
                  </th>
                  <th className='min-w-150px'>
                    {intl.formatMessage({ id: 'IDENTITY_VALIDATION.ACTIONS' })}
                  </th>
                </tr>
              </thead>

              <tbody>
                {data.map((item, index) => {
                  const count = ((currentPage - 1) * limit) + index + 1;
                  return (
                    <tr key={item.id}>
                      <td style={{ width: '14ch' }}>{count}</td>
                      <td style={{ width: '14ch' }}>{item.id}</td>
                      <td style={{ width: '14ch' }}>{item.customerName}</td>
                      <td style={{ width: '14ch' }}>{item.productName}</td>
                      <td style={{ width: '14ch' }}>{item.subproductName}</td>
                      <td style={{ width: '14ch' }}>
                    
                        {moment(item.createdAt).tz(timezone).format('DD-MM-YYYY')}
                        </td>
                      <td style={{ width: '14ch' }}>{item.statusCode === '200' ? 'Aceptado' : 'Rechazado'}</td>
                      <td style={{ width: '14ch' }}>{item.email}</td>
                      <td style={{ width: '14ch' }}>{item.userName}</td>
                      <td style={{ width: '14ch' }}>

                        <button
                          type='button'
                          className='dropdown-toggle icon-dropdown'
                          data-bs-toggle='modal'
                          data-bs-target={`#requestModal-${item.id}`}
                          onClick={() => handleModalClick(item.id)}
                        >
                          <i className='bi bi-eye-fill'></i>
                        </button>

                        <div
                          className='modal fade '
                          id={`requestModal-${item.id}`}
                          tabIndex={-1}
                          aria-labelledby='exampleModalLabel'
                          aria-hidden='true'
                        >
                          <div className='modal-dialog modal-dialog-centered modal-lg'>
                            <div className='modal-content'>
                              <div className='modal-header'>
                                <h1 className='modal-title fs-5' id='staticBackdropLabel'>
                                  Detalle
                                </h1>
                                <button
                                  type='button'
                                  className='btn-close'
                                  data-bs-dismiss='modal'
                                  aria-label='Close'
                                ></button>

                              </div>
                              <div className='modal-body'>
                                <p className='text-start ms-5'>
                                  <strong>Request</strong>
                                </p>
                                <div className='text-start ms-5'>
                                  {modalData?.providerRequest ? (
                                    <pre
                                      style={{
                                        fontFamily: 'monospace',
                                        fontSize: '14px',
                                        padding: '10px',
                                        backgroundColor: '#f6f8fa',
                                        borderRadius: '4px',
                                        whiteSpace: 'pre-wrap',
                                        wordWrap: 'break-word',
                                      }}
                                    >
                                      {JSON.stringify(JSON.parse(modalData.providerRequest), null, 2)}
                                    </pre>
                                  ) : (
                                    <p>No response data available.</p>
                                  )}
                                </div>

                                <p className='text-start ms-5'>
                                  <strong>Response</strong>
                                </p>
                                <div className='text-start ms-5'>
                                  {modalData?.providerResponse ? (
                                    <pre
                                      style={{
                                        fontFamily: 'monospace',
                                        fontSize: '14px',
                                        padding: '10px',
                                        backgroundColor: '#f6f8fa',
                                        borderRadius: '4px',
                                        whiteSpace: 'pre-wrap',
                                        wordWrap: 'break-word',
                                      }}
                                    >
                                      {JSON.stringify(JSON.parse(modalData.providerResponse), null, 2)}
                                    </pre>
                                  ) : (
                                    <p>No response data available.</p>
                                  )}
                                </div>
                              </div>



                              <div className='modal-footer'>
                                <button type='button' className='btn btn-secondary' data-bs-dismiss='modal'>
                                  Cerrar
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>


                      </td>
                    </tr>
                  );
                })}
              </tbody>


            </table>
          )}
          {selectedReport && (
            <ReportModal report={selectedReport} visible={modalVisible} 
            onClose={handleCloseModal} />
          )}


        </div>
        <div className='d-flex justify-content mb-4 same-col'>
          <div>
            <select
              value={limit}
              onChange={(event) => setLimit(Number(event.target.value))}
              className='form-select form-select-sm custom-select-page'
            >
              <option value={10}>10</option>
              <option value={15}>15</option>
              <option value={30}>30</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
          </div>
          <div>
            {memoizedData.length !== 0 && (
              <Pagination
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalPages={totalPages}
                limit={limit}
                lengthData={memoizedData.length}
              />
            )}
          </div>

        </div>
      </div>
    </div >
  )
}

export { ReportList }
