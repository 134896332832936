
import {useIntl} from 'react-intl'





export function MenuInner() {
  const intl = useIntl()
  return (
    <>
   
    </>
  )
}
