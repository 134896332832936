import CryptoJS from 'crypto-js';

interface AllPermissions {
  hasUserListPermission: boolean;
  hasUserViewPermission: boolean;
  hasUserAddPermission: boolean;
  hasUserEditPermission: boolean;
  hasUserActivatePermission: boolean;
  hasUserDesactivePermission: boolean;
  hasUserDeletePermission: boolean;

  hasClientsConfigureChainsPermission: boolean;
  hasClientsViewReferencesPermission: boolean;
  hasClientsDeactivatePermission: boolean;
  hasClientsActivatePermission: boolean;
  hasClientsDeletePermission: boolean;
  hasClientsViewPermission: boolean;
  hasClientsEditPermission: boolean;
  hasClientsAddPermission: boolean;
  hasClientsListPermission: boolean;
  hasConsumptionReportCreditScorePermission: boolean;
  hasConsumptionReportIdentityValidationPermission: boolean;
  hasConsumptionReportPaymentsPermission: boolean;
  hasProductsConfigureChainsPermission: boolean;
  hasProductsDeactivatePermission: boolean;
  hasProductsActivatePermission: boolean;
  hasProductsDeletePermission: boolean;
  hasProductsListPermission: boolean;
  hasDashboardPermission: boolean;
  hasBitacoraPermission: boolean;
  hasGroupAddPermission: boolean;
  hasGroupEditPermission: boolean;
  hasGroupListPermission: boolean;

  hasClientConfigCommissionPermission: boolean;
  hasProductConfigCommissionPermission: boolean;

  hasClientConfigPaymentServiceCommissionPermission: boolean;
  hasProductConfigPaymentServiceCommissionPermission: boolean;

  hasConsumptionReportRechargePermission: boolean;
  hasConsumptionReportTransactionPermission: boolean;

  hasClientPassportPermission: boolean;
  hasConsumptionReportRI: boolean;
  hasConsumptionReportCC: boolean;
  //SMS
  hasConsumptionReportSMS: boolean;
  hasConsumptionReportERPPermission: boolean;
  hasConsumptionReportPaymentServicePermission: boolean;
  hasConsumptionReportJourneyServicePermission: boolean;
  hasConsumptionReportHBMServicePermission: boolean;
  hasConsumptionReportGenerateDocumentsPermission: boolean;

  hasConsumptionReportMails: boolean;

  hasConfigLimitSMS: boolean;
}

type Permission = {
  id: number;
  flag: boolean;
  GroupId: number;
  ResourceId: number;
  Resource: {
    id: number;
    name: string;
    label: string;
  };
};


const PermissionsService = {
  _getPermissions: (): AllPermissions | null => {
    const cachedPermissions = localStorage.getItem('userPermissions');
    const encryptionKey = process.env.REACT_APP_ENCRIPTION_KEY;

    if (cachedPermissions && encryptionKey) {
      const decryptedPermissions = CryptoJS.AES.decrypt(
        cachedPermissions,
        encryptionKey
      ).toString(CryptoJS.enc.Utf8);

      const permissions = JSON.parse(decryptedPermissions) as Permission[];

      // Verificar cada permiso y asignar el valor correspondiente
      const hasUserListPermission = permissions?.some(
        (p) => p.Resource && p.Resource.name === 'users.list' && p.flag
      );
      const hasUserViewPermission = permissions?.some(
        (p) => p.Resource && p.Resource.name === 'users.view' && p.flag
      );
      const hasUserAddPermission = permissions?.some(
        (p) => p.Resource && p.Resource.name === 'users.add' && p.flag
      );
      const hasUserEditPermission = permissions?.some(
        (p) => p.Resource && p.Resource.name === 'users.edit' && p.flag
      );
      const hasUserActivatePermission = permissions?.some(
        (p) => p.Resource && p.Resource.name === 'users.activate' && p.flag
      );
      const hasUserDesactivePermission = permissions?.some(
        (p) => p.Resource && p.Resource.name === 'users.desactive' && p.flag
      );
      const hasUserDeletePermission = permissions?.some(
        (p) => p.Resource && p.Resource.name === 'users.delete' && p.flag
      );
      const hasClientsConfigureChainsPermission = permissions?.some(
        (p) => p.Resource && p.Resource.name === 'clients.configureChains' && p.flag
      );
      const hasClientsViewReferencesPermission = permissions?.some(
        (p) => p.Resource && p.Resource.name === 'clients.viewReferences' && p.flag
      );
      const hasClientsDeactivatePermission = permissions?.some(
        (p) => p.Resource && p.Resource.name === 'clients.deactivate' && p.flag
      );
      const hasClientsActivatePermission = permissions?.some(
        (p) => p.Resource && p.Resource.name === 'clients.activate' && p.flag
      );
      const hasClientsDeletePermission = permissions?.some(
        (p) => p.Resource && p.Resource.name === 'clients.delete' && p.flag
      );
      const hasClientsViewPermission = permissions?.some(
        (p) => p.Resource && p.Resource.name === 'clients.view' && p.flag
      );
      const hasClientsEditPermission = permissions?.some(
        (p) => p.Resource && p.Resource.name === 'clients.edit' && p.flag
      );
      const hasClientsAddPermission = permissions?.some(
        (p) => p.Resource && p.Resource.name === 'clients.add' && p.flag
      );
      const hasClientsListPermission = permissions?.some(
        (p) => p.Resource && p.Resource.name === 'clients.list' && p.flag
      );

      //Reportes
      const hasConsumptionReportCreditScorePermission = permissions?.some(
        (p) => p.Resource && p.Resource.name === 'consumptionReport.creditScore' && p.flag
      );

      //aQUI
      const hasConsumptionReportIdentityValidationPermission = permissions?.some(
        (p) => p.Resource && p.Resource.name === 'consumptionReport.identityValidation' && p.flag
      );
      const hasConsumptionReportRI = permissions?.some(
        (p) => p.Resource && p.Resource.name === 'consumptionReport.RI' && p.flag
      );

      const hasConsumptionReportCC = permissions?.some(
        (p) => p.Resource && p.Resource.name === 'consumptionReport.CC' && p.flag
      );

      //SMS
      const hasConsumptionReportSMS = permissions?.some(
        (p) => p.Resource && p.Resource.name === 'consumptionReport.SMS' && p.flag
      );


      //CC /RI

      const hasConsumptionReportPaymentsPermission = permissions?.some(
        (p) => p.Resource && p.Resource.name === 'consumptionReport.payments' && p.flag
      );

      const hasConsumptionReportERPPermission = permissions?.some(
        (p) => p.Resource && p.Resource.name === 'consumptionReport.ERP' && p.flag
      );



      //HBM
      const hasConsumptionReportPaymentServicePermission = permissions?.some(
        (p) => p.Resource && p.Resource.name === 'consumptionReport.PaymentServices' && p.flag
      );

      const hasConsumptionReportJourneyServicePermission = permissions?.some(
        (p) => p.Resource && p.Resource.name === 'consumptionReport..journeys' && p.flag
      );

      const hasConsumptionReportHBMServicePermission = permissions?.some(
        (p) => p.Resource && p.Resource.name === 'consumptionReport.hbm' && p.flag
      );


      // hasConsumptionReportGenerateDocumentsPermission: boolean;

      const hasConsumptionReportGenerateDocumentsPermission = permissions?.some(
        (p) => p.Resource && p.Resource.name === 'consumptionReport.GeneracionDocumentos' && p.flag
      );


      //PRODUCTOS
      const hasProductsConfigureChainsPermission = permissions?.some(
        (p) => p.Resource && p.Resource.name === 'products.configureChains' && p.flag
      );
      const hasProductsDeactivatePermission = permissions?.some(
        (p) => p.Resource && p.Resource.name === 'products.deactivate' && p.flag
      );
      const hasProductsActivatePermission = permissions?.some(
        (p) => p.Resource && p.Resource.name === 'products.activate' && p.flag
      );
      const hasProductsDeletePermission = permissions?.some(
        (p) => p.Resource && p.Resource.name === 'products.delete' && p.flag
      );
      const hasProductsListPermission = permissions?.some(
        (p) => p.Resource && p.Resource.name === 'products.list' && p.flag
      );

      //DASHBOARD
      const hasDashboardPermission = permissions?.some(
        (p) => p.Resource && p.Resource.name === 'dashboard.view' && p.flag
      );

      const hasBitacoraPermission = permissions?.some(
        (p) => p.Resource && p.Resource.name === 'bitacoras.list' && p.flag
      );

      const hasGroupAddPermission = permissions?.some(
        (p) => p.Resource && p.Resource.name === 'groups.add' && p.flag
      );

      const hasGroupEditPermission = permissions?.some(
        (p) => p.Resource && p.Resource.name === 'groups.edit' && p.flag
      );


      const hasGroupListPermission = permissions?.some(
        (p) => p.Resource && p.Resource.name === 'groups.list' && p.flag
      );

      ///....................
      const hasClientConfigCommissionPermission = permissions?.some(
        (p) => p.Resource && p.Resource.name === 'clients.configure.commissions' && p.flag
      );

      const hasProductConfigCommissionPermission = permissions?.some(
        (p) => p.Resource && p.Resource.name === 'products.configure.commissions' && p.flag
      );




      ///
      const hasClientConfigPaymentServiceCommissionPermission = permissions?.some(
        (p) => p.Resource && p.Resource.name === 'clients.configure.paymentServices' && p.flag
      );

      const hasProductConfigPaymentServiceCommissionPermission = permissions?.some(
        (p) => p.Resource && p.Resource.name === 'products.configure.paymentServices' && p.flag
      );

      const hasConsumptionReportRechargePermission = permissions?.some(
        (p) => p.Resource && p.Resource.name === 'consumptionReport.recharges' && p.flag
      );

      const hasConsumptionReportTransactionPermission = permissions?.some(
        (p) => p.Resource && p.Resource.name === 'consumptionReport.transactions' && p.flag
      );

      const hasClientPassportPermission = permissions?.some(
        (p) => p.Resource && p.Resource.name === 'clients.passport' && p.flag
      );


      const hasConfigLimitSMS = permissions?.some(
        (p) => p.Resource && p.Resource.name === 'clients.configure.limitsSMS' && p.flag
      );

      const hasConsumptionReportMails = permissions?.some(
        (p) => p.Resource && p.Resource.name === 'consumptionReport.Mails' && p.flag
      );




      return {
        hasUserListPermission,
        hasUserViewPermission,
        hasUserAddPermission,
        hasUserEditPermission,
        hasUserActivatePermission,
        hasUserDesactivePermission,
        hasUserDeletePermission,


        hasClientsConfigureChainsPermission,
        hasClientsViewReferencesPermission,
        hasClientsDeactivatePermission,
        hasClientsActivatePermission,
        hasClientsDeletePermission,
        hasClientsViewPermission,
        hasClientsEditPermission,
        hasClientsAddPermission,
        hasClientsListPermission,

        hasConsumptionReportCreditScorePermission,
        hasConsumptionReportIdentityValidationPermission,
        hasConsumptionReportPaymentsPermission,
        hasConsumptionReportERPPermission,


        hasProductsConfigureChainsPermission,
        hasProductsDeactivatePermission,
        hasProductsActivatePermission,
        hasProductsDeletePermission,
        hasProductsListPermission,
        hasDashboardPermission,
        hasBitacoraPermission,
        hasGroupAddPermission,
        hasGroupEditPermission,
        hasGroupListPermission,
        hasConsumptionReportMails,

        // hasConsumptionReportERPPermission,
        hasClientConfigCommissionPermission,
        hasProductConfigCommissionPermission,

        hasConsumptionReportSMS,


        hasClientConfigPaymentServiceCommissionPermission,
        hasProductConfigPaymentServiceCommissionPermission,

        hasConsumptionReportRechargePermission,
        hasConsumptionReportTransactionPermission,

        hasConsumptionReportPaymentServicePermission,
        hasConsumptionReportJourneyServicePermission,
        hasConsumptionReportHBMServicePermission,
        hasConsumptionReportGenerateDocumentsPermission,

        hasClientPassportPermission,
        hasConsumptionReportCC,
        hasConsumptionReportRI,
        hasConfigLimitSMS
      };
    }

    return null;
  },
  get getPermissions() {
    return this._getPermissions;
  },
  set getPermissions(value) {
    this._getPermissions = value;
  },
};

export default PermissionsService;
