import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Reference } from './core/References.Model';
import { Link, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import Pagination from './Pagination';
import { getTokenConfig } from '../../../../src/auth';
import { fetchReferences } from './apis/referencesApi';
import ReferenceListBody from './ReferencesListBody';




const ReferenceList: React.FC = () => {
  const intl = useIntl();
  const { customerId } = useParams()
  const navigate = useNavigate();

 


  const [data, setData] = useState<Array<Reference>>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const [lastRegistrationDateInit, setLastRegistrationDateInit] = useState('');
  const [lastRegistrationDateEnd, setLastRegistrationDateEnd] = useState('');

  const [orderType, setOrderType] = useState<string>('reference');
  const [orderDirection, setOrderDirection] = useState<'asc' | 'desc'>('asc');

  const handleOrderChange = (newOrderType: string) => {
    setOrderType(newOrderType);

    setOrderDirection((prevDirection) => (prevDirection === 'asc' ? 'desc' : 'asc'));
  };




  const handleReset = async () => {
    setCurrentPage(1)
    await new Promise<void>((resolve) => {
      setSearchValue('')
      setLastRegistrationDateInit('')
      setLastRegistrationDateEnd('')
      resolve()
    })
  }

  useEffect(() => {


    if (searchValue === '') {
      handleSearch()
    }

  }, [searchValue])


  useEffect(() => {
    if (lastRegistrationDateInit === '') {
      handleSearch()
    }
  }, [lastRegistrationDateInit])

  useEffect(() => {
    if (lastRegistrationDateEnd === '') {
      handleSearch()
    }
  }, [lastRegistrationDateEnd])


  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setError(null);

      try {
        const config = await getTokenConfig();
        let args = [config, limit, currentPage, searchValue, lastRegistrationDateInit, lastRegistrationDateEnd, orderType, orderDirection];

        if (customerId !== undefined) {
          args.push(customerId);
        }



        const { references, totalPages } = await fetchReferences(
          config,
          limit,
          currentPage,
          searchValue,
          lastRegistrationDateInit,
          lastRegistrationDateEnd,
          orderType,
          orderDirection,
          Number(customerId) ?? undefined
          // Utilizando un operador ternario para verificar si clientId tiene un valor
        );

        setData(references);
        setIsLoading(false);
        setTotalPages(totalPages);

      } catch (err: any) {
        setError(err);
        setIsLoading(false);
      }
    };


    fetchData();

  }, [currentPage, limit, orderType, orderDirection]);



  const handleSearch = async () => {



    setIsLoading(true);
    setError(null);

    try {
      const config = await getTokenConfig();

      const { references, totalPages } = await fetchReferences(config, limit, currentPage, searchValue,
        lastRegistrationDateInit,
        lastRegistrationDateEnd,
        orderType, orderDirection,
        Number(customerId) ?? undefined);

      setData(references);
      setIsLoading(false);
      setTotalPages(Math.ceil(totalPages / limit));

    } catch (err: any) {
      setError(err);
      setIsLoading(false);
    }
  }
  const memoizedData = useMemo(() => data, [data])

  if (isLoading) {
    return <div>{intl.formatMessage({ id: 'PLEASE.WAIT' })}</div>;
  }

  if (error) {
    return <div>Ocurrió un error</div>;
  }


  return (
    <>
      <div className='row'>
        <div className='col-12'>
          <div className='card'>
            <div className='card-header cursor-pointer'>
              <div className='card-title m-0'>
                <h3 className='fw-bolder m-0'>
                  {intl.formatMessage({ id: 'SERVICES.LIST_REFERENCE' })}
                </h3>
              </div>
            </div>

            <div className='card-body p-9'>
              <form onSubmit={handleSearch}>
                <div className='row mb-5'>
                  <div className='col-3 d-flex align-items-start flex-wrap'>
                    <div className='w-100'>
                      <label className='form-label'>Buscar</label>
                      <input
                        type='text'
                        data-kt-user-table-filter='search'
                        onChange={(event) => setSearchValue(event.target.value)}
                        className='form-control form-control-solid'
                        value={searchValue}
                      />
                    </div>
                  </div>
                  <div className='col-2'>
                    <label className='form-label'>Fecha Inicio de ultimo registro</label>
                    <input type='date'
                      onChange={(event) => setLastRegistrationDateInit(event.target.value)}
                      className='form-control form-control-solid'
                      value={lastRegistrationDateInit}
                    />
                  </div>
                  <div className='col-2'>
                    <label className='form-label'>Fecha fin de ultimo registro</label>
                    <input type='date'
                      onChange={(event) => setLastRegistrationDateEnd(event.target.value)}
                      className='form-control form-control-solid'
                      value={lastRegistrationDateEnd}
                    />
                  </div>
                  <div className='col-5  align-bottom '>
                    <div className='split-button'>
                      <button type='submit' className='btn btn-primary rounded-pill'>
                        {intl.formatMessage({ id: 'SEARCH' })}
                      </button>
                      <button onClick={handleReset} type='button' className='btn btn-secondary  rounded-pill'>
                        {intl.formatMessage({ id: 'DELETE' })}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
              <div className='row mb-5'>
                <div className='col-1  align-center'>
                  <select className='form-select form-select-sm'>
                    <option value={10}>10</option>
                    <option value={15}>15</option>
                    <option value={30}>30</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                  </select>
                </div>
              </div>

              <div className='row mt-5'>
                <div className='col-12'>
                  <div className='table-responsive mb-3'>
                    <table className='table table-striped' id='table'>
                      <thead>
                        <tr className='fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200'>
                          <th className='min-w-50px' onClick={() => handleOrderChange("id")}>#</th>
                          <th className='min-w-150px' onClick={() => handleOrderChange('reference')} >
                            {intl.formatMessage({ id: 'SERVICES.REFERENCE' })}
                          </th>
                          <th className='min-w-150px'>
                            Socio tecnológico
                          </th>
                          <th className='min-w-150px' onClick={() => handleOrderChange('userName')} >
                            {intl.formatMessage({ id: 'SERVICES.USER' })}
                          </th>
                          <th className='min-w-150px' onClick={() => handleOrderChange('lastRegistrationDate')}>Fecha de último registro</th>
                          <th className='min-w-150px' onClick={() => handleOrderChange('createdAt')}>Fecha de generación</th>

                          <th className='text-center'>
                            {intl.formatMessage({ id: 'USERS.ACTION' })}
                          </th>
                        </tr>
                      </thead>

                      <ReferenceListBody
                        data={memoizedData}
                      />
                    </table>
                  </div>
                </div>
              </div>

              <div className='row'>
                <div className='col-12 d-flex justify-content-start'>
                  {memoizedData.length !== 0 && (
                    <Pagination
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      totalPages={totalPages}
                      limit={limit}
                      lengthData={memoizedData.length}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export { ReferenceList }
