import React, { useState, useEffect } from 'react';

import { configAlertsSMS, createSMSLimitConfig, updateSMSLimitConfig } from './apis';
import { SmsConfig } from './models';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

const ServiceConfigLimitSMS: React.FC = () => {
 
  const [idAlerts, setIdAlerts] = useState(0);
  const [emailsAlerts, setEmailsAlerts] = useState('');
  const [firstAlert, setFirstAlert] = useState(0);
  const [secondAlert, setSecondAlert] = useState(0);
  const [thirdAlert, setThirdAlert] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await configAlertsSMS();
        setIdAlerts(response.smsConfig.id ?? 0);
        setEmailsAlerts(response.smsConfig.emails ?? '');
        setFirstAlert(response.smsConfig.firstAlert ?? 0);
        setSecondAlert(response.smsConfig.secondAlert ?? 0);
        setThirdAlert(response.smsConfig.thirdAlert ?? 0);
      } catch (error) {
        console.error('Error fetching SMS configuration:', error);
        setError(error instanceof Error ? error.message : String(error));
      } finally {
        setIsLoading(false);
      }
    };
  
    fetchData();
  }, []);

  const handleSaveConfiguration = async () => {
    Swal.fire({
      icon: 'info',
      title: 'Guardando configuración',
      text: 'Por favor, espera mientras se guarda la configuración...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      showConfirmButton: false,
    });

    try {
      const value = {
        emails: emailsAlerts,
        firstAlert: firstAlert,
        secondAlert: secondAlert,
        thirdAlert: thirdAlert
      };
      let response;
      if (idAlerts === 0) {
        response = await createSMSLimitConfig(value);
        if (response) {
          Swal.fire({
            icon: 'success',
            title: 'Configuración guardada',
            text: 'La configuración de SMS se ha guardado correctamente.',
            showConfirmButton: true,
            allowOutsideClick: false,
            allowEscapeKey: false,
          });
          Swal.close(); // Cerrar el SweetAlert de "Guardando configuración" después de mostrar el mensaje de éxito
        }
      } else {
        response = await updateSMSLimitConfig(idAlerts, value);
        if (response) {
          Swal.fire({
            icon: 'success',
            title: 'Configuración actualizada',
            text: 'La configuración de SMS se ha actualizado correctamente.',
            timer: 3000,
            timerProgressBar: true,
            allowOutsideClick: false,
            allowEscapeKey: false,
            showConfirmButton: false,
          });
        }
      }
    } catch (error) {
      console.error(error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Ha ocurrido un error al guardar la configuración de SMS. Por favor, inténtalo de nuevo.',
      });
    } finally {
      setIsLoading(false);
    }
  };
  if (isLoading) {
    return <div>Cargando...</div>;
  }

  if (error) {
    return <div>Ocurrió un error: {error}</div>;
  }

  return (
    <div>

      <div className='card mb-5 mb-xl-10 mt-10' id='kt_profile_details_view'>

        <div className='card-header'>
          <h3 className='card-title'>Configurar alertas de créditos SMS </h3>
        </div>
        <div className="card-body">


          <div className="row">
            <div className='col-md-12'>
              <div className='mb-3'>
                <label htmlFor='emailsAlerts' className='form-label'>Email(s)</label>
                <input
                  type='text'
                  className='form-control'
                  placeholder='example@example.com,examp'
                  id='emailsAlerts'
                  value={emailsAlerts}
                  onChange={(e) => setEmailsAlerts(e.target.value)}
                />
                {/* Validación de los correos electrónicos */}
                {/* Agrega validaciones aquí si es necesario */}
              </div>
            </div>
          </div>

          <div className='row'>
            <div className='col-md-6'>
              <div className='mb-3'>
                <label htmlFor='firstAlert' className='form-label'>Primera alerta</label>
                <input
                  type='text'
                  className='form-control'
                  id='firstAlert'
                  value={firstAlert}
                  onChange={(e) => setFirstAlert(Number(e.target.value))}
                />
                {/* Validación de la primera alerta */}
                {/* Agrega validaciones aquí si es necesario */}
              </div>
            </div>
            <div className='col-md-6'>
              <div className='mb-3'>
                <label htmlFor='secondAlert' className='form-label'>Segunda alerta</label>
                <input
                  type='text'
                  className='form-control'
                  id='secondAlert'
                  value={secondAlert}
                  onChange={(e) => setSecondAlert(Number(e.target.value))}
                />
                {/* Validación de la segunda alerta */}
                {/* Agrega validaciones aquí si es necesario */}
              </div>
            </div>
            <div className='col-md-6'>
              <div className='mb-3'>
                <label htmlFor='thirdAlert' className='form-label'>Tercera alerta</label>
                <input
                  type='text'
                  className='form-control'
                  id='thirdAlert'
                  value={thirdAlert}
                  onChange={(e) => setThirdAlert(Number(e.target.value))}
                />
                {/* Validación de la tercera alerta */}
                {/* Agrega validaciones aquí si es necesario */}
              </div>
            </div>
          </div>
        </div>

        <div className="card-footer">
          <div className="row">
            <div className="col-md-9"></div> {/* Espacio vacío para alinear a la derecha */}
            <div className="col-md-3 d-flex justify-content-end">

              <button className="btn btn-primary" onClick={handleSaveConfiguration} disabled={isLoading}>
                Guardar configuración
              </button>
            </div>
          </div>
        </div>






      </div>
    </div>
  )
}

export { ServiceConfigLimitSMS }
