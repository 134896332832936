import {Navigate, Routes, Route, Outlet} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {useIntl} from 'react-intl'

import {Activity} from './Activity'

const LogPage = () => {
  const intl = useIntl()
  const userBreadCrumbs: Array<PageLink> = [
    {
      title: intl.formatMessage({id: 'LOG.HEADER'}),
      path: '/logs',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='list'
          element={
            <>
              <Activity />
              <PageTitle breadcrumbs={userBreadCrumbs}>
                {intl.formatMessage({id: 'LOG.HEADER'})}
              </PageTitle>
            </>
          }
        />
      </Route>

      <Route index element={<Navigate to='list' />} />
    </Routes>
  )
}

export default LogPage
