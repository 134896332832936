import React, { useCallback, useState } from 'react';
import moment from 'moment';

import { Modal } from 'react-bootstrap';
import { ITransaction } from './core/Report.Model';
import Swal, { SweetAlertOptions } from 'sweetalert2'

import { uploadDirectly } from './apis/requestsApi'

type Props = {
    data: ITransaction[];
    currentPage: number;
    limit: number;
    handleDownload: (image: string) => Promise<void>;
    handleSearch: () => void;
    timezone:string;
};

const ReportListBody: React.FC<Props> = React.memo(({ data, currentPage, limit, handleDownload, handleSearch,
timezone
}) => {
    const calculateIndex = useCallback((index: number) => {
        return (currentPage - 1) * limit + index + 1;
    }, [currentPage, limit]);

    interface CustomSweetAlertOptions extends SweetAlertOptions {
        onOpen?: () => void
    }
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState<ITransaction | null>(null);
    const [selectedFile, setSelectedFile] = useState<File | null>(null)

    const openModal = (item: ITransaction) => {
        setSelectedItem(item);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setSelectedItem(null);
        setIsModalOpen(false);
    };


    const handleFileUpload = async () => {
        if (selectedFile && selectedItem) {
            try {

                Swal.fire({
                    html: `
                    <div class="spinner-container" style="overflow: hidden;>
                        <i class="fas fa-spinner fa-spin"></i>
                        <span>Cargando imagen...</span>
                      </div>
                    `,
                    showConfirmButton: false,
                    allowOutsideClick: false,
                } as CustomSweetAlertOptions)


                const fileUrl = await uploadDirectly(selectedFile, selectedItem.id);

                Swal.close()
                console.log(`File uploaded successfully for item with id ${selectedItem.id}:`, fileUrl);
                closeModal();
                handleSearch()
            } catch (error) {

                Swal.close()

                Swal.fire({
                    title: 'Error de Subida',
                    text: 'Hubo un problema al subir el archivo. Por favor, intenta de nuevo.',
                    icon: 'error'
                });
                console.error('Error uploading file:', error);
            }
        }
    };



    return (
        <tbody>
            {data.map((item, index) => {
                return (
                    <tr key={item.id}>
                        <td style={{ width: '14ch' }}>{calculateIndex(index)}</td>
                        <td style={{ width: '14ch' }}>{item.nameCustomer}</td>
                        <td style={{ width: '14ch' }}>{item.moneybagCustomerType.name}</td>

                        <td>{item.transactionType.name}</td>
                        <td style={{ width: '14ch' }}>{item.accountBank}</td>
                        <td style={{ width: '14ch' }}>{item.paymentType.name}</td>
                        <td style={{
                            width: '14ch',
                            color: item.transactionType.id === 1 ? 'green' :
                                item.transactionType.id === 2 ? 'red' : 'black'
                        }}>
                            $ {item.amount}
                        </td>

                        <td style={{ width: '14ch' }}>{item.noAut}</td>
                        <td style={{ width: '14ch' }}>
                        {moment(item.datePayment).tz(timezone).format('DD-MM-YYYY')}
                           </td>
                     

                        <td style={{ width: '14ch' }}>{item.note}</td>
                        <td style={{ width: '14ch' }}>
                            {item.createdBy}</td>
                        <td style={{ width: '14ch' }}>
                        {moment(item.createdAt).tz(timezone).format('DD-MM-YYYY')}
                           </td>
                        <td>


                            {item.vaucher ? (
                                <a onClick={(e) => {
                                    e.preventDefault(); // Previene el comportamiento por defecto
                                    handleDownload(item.vaucher);
                                }} className="btn btn-primary circular-button">
                                    <i className="fa-solid fa-file-arrow-down p-0"></i>
                                </a>

                            ) : (
                                <button
                                    className="btn btn-muted circular-button"
                                    onClick={() => openModal(item)}
                                    style={{ backgroundColor: 'gray', color: 'white' }}
                                >
                                    <i className="fa-solid fa-file-arrow-down p-0" style={{ color: 'white' }}></i>
                                </button>

                            )}
                        </td>

                        {/* <td style={{ width: '14ch' }}>{item.vaucher}</td> */}

                    </tr>
                );
            })}

            <Modal show={isModalOpen} onHide={closeModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Cargar Archivo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3">
                        <label className="form-label">
                            Seleccionar Archivo
                        </label>
                        <input
                            type="file"
                            className="form-control"
                            accept="image/*"
                            onChange={(e) => {
                                if (e.target.files && e.target.files.length > 0) {
                                    const file = e.target.files[0];

                                    // Verificar si el archivo es una imagen
                                    if (!file.type.startsWith('image/')) {
                                        Swal.fire({
                                            title: 'Error',
                                            text: 'Por favor, selecciona un archivo de imagen válido.',
                                            icon: 'error'
                                        });
                                        return;
                                    }

                                    // Tamaño del archivo en megabytes
                                    const fileSizeInMB = file.size / (1024 * 1024);

                                    // Comprobar si el archivo supera el límite de 2 MB
                                    if (fileSizeInMB > 2) {
                                        Swal.fire({
                                            title: 'Error',
                                            text: 'El archivo es demasiado grande. El tamaño máximo permitido es de 2 MB.',
                                            icon: 'error'
                                        });
                                    } else {
                                        setSelectedFile(file);
                                    }
                                }
                            }}
                        />

                    </div>
                    <div className="d-flex justify-content-end mb-3">
                        <button className="btn btn-primary me-2" onClick={handleFileUpload} disabled={!selectedItem}>
                            Cargar
                        </button>
                        <button className="btn btn-secondary" onClick={closeModal}>
                            Cancelar
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
        </tbody>
    );
});

export default ReportListBody;
