
import { getTokenConfig } from '../../../../../src/auth'

import axios, {AxiosResponse } from 'axios';

interface VerifierResponse {
    signedUrl: string;
    // Puedes agregar más propiedades aquí si el verificador devuelve más información relevante.
}



export const fetchRequests = async (
    config: any,
    limit: number,
    currentPage: number,
    searchValue: string,
    createdAtDateInit: string,
    createdAtDateEnd: string,
    orderType: string,
    orderDirection: string,
    customerName: string, // incluir el filtro por cadena como un parámetro opcional
    selectedProduct?: string

) => {


    try {
        let queryString = `${process.env.REACT_APP_API_URL}/admin/devices-check-report?limit=${limit}&page=${currentPage}`;


        if (orderType === 'id,asc') {
            queryString += `&order=id,${orderDirection}`;
        }
        else if (orderType === 'deviceId,asc') {
            queryString += `&order=deviceId,${orderDirection}`;
        }
        else if (orderType === 'customerName,asc') {
            queryString += `&order=customerName,${orderDirection}`;
        }

        else if (orderType === 'subproductName,asc') {
            queryString += `&order=center,${orderDirection}`;
        }
        else if (orderType === 'statusCode,asc') {
            queryString += `&order=statusCode,${orderDirection}`;
        }
        else if (orderType === 'userName,asc') {
            queryString += `&order=userName,${orderDirection}`;
        }


        else if (orderType === 'email,asc') {
            queryString += `&order=email,${orderDirection}`;
        }
        else if (orderType === 'userName,asc') {
            queryString += `&order=userName,${orderDirection}`;
        }
        else if (orderType === 'email,asc') {
            queryString += `&order=email,${orderDirection}`;
        }
        else if (orderType === 'email,asc') {
            queryString += `&order=email,${orderDirection}`;
        }
        
        

        if (searchValue) {
            queryString += `&search=${searchValue}`;
        }

        if (selectedProduct) {
            queryString += `&subproductName=${selectedProduct}`;
        }

        if (createdAtDateInit) {
            queryString += `&createdAtDateInit=${createdAtDateInit}`;
        }

        if (createdAtDateEnd) {
            queryString += `&createdAtDateEnd=${createdAtDateEnd}`;
        }

        if (customerName) { // agregar el filtro por cadena
            queryString += `&customerName=${customerName}`;
        }


        const response = await axios.get(queryString, config);

        const requests = response.data.data.requests;

        const timezone = response.data.data.timezone;



        const totalPages = Math.ceil(response.data.data.total / limit);

        return { requests, totalPages, timezone };
    } catch (err) {
        throw err;
    }
};


export const fetchCustomers = async (
    config: any
) => {
    try {
        let queryString = `${process.env.REACT_APP_API_URL}/admin/list-customers`;
     

        const response = await axios.get(queryString, config);

        const customers = response.data.data.customers;

        return { customers };
    } catch (err) {
        throw err;
    }
};




export const fetchExcelReport = async (config: any, searchValue: string, createdAtDateInit: string, createdAtDateEnd: string, orderType: string, orderDirection: string, searchCustomer: string,   selectedProduct?: string) => {
    try {
        let queryString = `${process.env.REACT_APP_API_URL}/download/admin/devices-check-report?search=${searchValue}&createdAtDateInit=${createdAtDateInit}&createdAtDateEnd=${createdAtDateEnd}&orderType=${orderType}&orderDirection=${orderDirection}&customerName=${searchCustomer}&subproductName=${selectedProduct}`;

        const response = await axios.get(queryString, config);

        const nameReport = response.data.name;

        if (!nameReport) {
            console.error("Error al obtener el nombre del archivo.");
            return null;
        }

        let signedUrl: string | null = null;
        const interval = 5000; // Intervalo en milisegundos (en este caso, 5 segundos)
        const maxAttempts = 36; // Número máximo de intentos (12 x 5 segundos = 1 minuto)

        let attempts = 0;
        while (!signedUrl && attempts < maxAttempts) {
            signedUrl = await verifyFileReady(config, nameReport);
            if (!signedUrl) {
                await new Promise((resolve) => setTimeout(resolve, interval)); // Esperar el intervalo antes de verificar nuevamente.
                attempts++;
            }
        }

        return signedUrl;
    } catch (error) {
        console.error("Error al obtener el nombre del archivo:", error);
        return null;

    }
};


const verifyFileReady = async (config: any, nameReport: string): Promise<string | null> => {
    try {
        const verifyUrl = `${process.env.REACT_APP_API_URL}/download/admin/devices-check-report?mode=check&filename=${nameReport}`;
        const response: AxiosResponse<VerifierResponse> = await axios.get(verifyUrl, config);

       

        const signedUrl = response.data.signedUrl;
        return signedUrl || null; // Devuelve el signedUrl si está presente, o null si no lo está.
    } catch (error) {
        console.error("Error al verificar si el archivo está listo:", error);
        return null;
    }
};


export const fetchInfoService = async (id: number, endpoint: string): Promise<any> => {
    const config = await getTokenConfig()
    let queryString = `${process.env.REACT_APP_API_URL}/detail/odoo-report`
    try {
        const response = await axios.get(queryString, {
            ...config,
            params: {
                id: id,
                endpoint: endpoint,
            }
        }
        )
        console.log("Response")
        console.log(response)

        return {
            status: response.status,
            data: response.data.data,
            message: response.data.message
        }

    } catch (error) {
        return {
            //  status: err.response ? err.response.status : 500,
            data: null
        }
    }
}




export const fetchModalData = async (
    config: any,
    id: number
) => {
    try {
        const queryString = `${process.env.REACT_APP_API_URL}/detail/odoo-report?id=${id}`;

        const response = await axios.get(queryString, config);

        console.log(response.data.data)
        const data = response.data.data.request
        return { data };
    } catch (err) {
        throw err;
    }
};
