/* eslint-disable array-callback-return */
import React, {useState, useEffect, useMemo, Fragment} from 'react'
import {Link} from 'react-router-dom'
import {useNavigate} from 'react-router-dom'

import {useIntl} from 'react-intl'
import {getTokenConfig} from '../../../../../src/auth'
import axios from 'axios'
import {fetchSections, saveGroup} from './../api/requestAPI'
import {IGroupSection, IGroupSectionItem, IGroupSectionAux} from './../core/Group.Model'
import Swal, {SweetAlertOptions} from 'sweetalert2'

interface CustomSweetAlertOptions extends SweetAlertOptions {
  onOpen?: () => void
}

const AddGroupForm: React.FC = () => {
  const intl = useIntl()
  const [name, setName] = useState<string>('')
  const [data, setData] = useState<IGroupSection[]>([])
  const navigate = useNavigate()
  const [loaded, setLoaded] = useState(false)
  const [loading, setLoading] = useState(false)

  const [errorMessage, setErrorMessage] = useState<Error[] | null>(null)
  const [accessToken, setAccessToken] = useState<string | null>(null)

  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {}, [data])

  const getData = async () => {
    setLoaded(false)
    const config = await getTokenConfig()
    const {requests} = await fetchSections(config)
    setData(requests)
    console.log(requests)
    setLoaded(true)
  }

  const handleUpdateSelectAllOnItemChange = (section: number) => {
    const currentData = [...data]
    var isSelected = true

    currentData[section].views
      .filter((item) => item.label !== '')
      .map((view: IGroupSectionItem) => {
        if (view.flag === false) {
          isSelected = false
        }
      })
    currentData[section].isSelectAll = isSelected
    setData(currentData)
    console.log(currentData)
  }

  const handleUpdateAllSection = (event: any, section: number) => {
    const currentData = [...data]

    currentData[section].isSelectAll = event.target.checked

    currentData[section].views
      .filter((item) => item.label !== '')
      .map((view: IGroupSectionItem) => {
        view.flag = event.target.checked
      })

    setData(currentData)
    console.log(currentData)
  }

  const handleUpdateItem = (event: any, section: number, item: number) => {
    const currentData = [...data]
    currentData[section].views[item].flag = event.target.checked

    setData(currentData)
    handleUpdateSelectAllOnItemChange(section)
    console.log(currentData)
  }

  const handleSave = async () => {
    setLoading(true)
    Swal.fire({
      html: `
				<div class="spinner-container">
					<i class="fas fa-spinner fa-spin"></i>
					<span>${intl.formatMessage({id: 'GROUP.ADD.DIALOG'})}</span>
				</div>
			`,
      showConfirmButton: false,
      allowOutsideClick: false,
    } as CustomSweetAlertOptions)
    setTimeout(async () => {
      if (name !== '') {
        const dataAux: IGroupSectionAux[] = []

        data.map((section: IGroupSection) => {
          const newSection: IGroupSectionAux = {
            sectionName: section.sectionName,
            views: section.views,
          }

          dataAux.push(newSection)
        })

        const body: any = {
          group: {
            name: name,
          },
          resources: dataAux,
        }
        console.log('body', body)
        const config = await getTokenConfig()
        await saveGroup(config, body).then((response: any) => {
          console.log(response)
          setLoading(false)
          if (response.data.status === 200) {
            Swal.fire({
              title: '¡Éxito!',
              text: response.data.data.message,
              icon: 'success',
              timer: 7000,
            })
            Swal.close()
            navigate('/groups')
          } else {
            Swal.fire({
              title: '¡Error!',
              text: response.data.message[0].message,
              icon: 'error',
              timer: 7000,
            })
          }
        })
      } else {
        setLoading(false)
        Swal.fire({
          title: '¡Error!',
          text: 'El nombre del grupo no puede estar vacío',
          icon: 'error',
          timer: 7000,
        })
      }
    }, 2000)
  }

  if (!loaded) {
    return <div>{intl.formatMessage({id: 'PLEASE.WAIT'})}</div>
  }

  return (
    <div className='card mb-1 '>
      <div className='card-header border-1 cursor-pointer'>
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>{intl.formatMessage({id: 'GROUPS.CREATE'})}</h3>
        </div>
      </div>
      <div className='card-body py-3'>
        <div className='row'>
          <div className='col-md-6'>
            <div className='form-group'>
              <label htmlFor='name'>{intl.formatMessage({id: 'GROUPS.NAME'})}</label>
              <input
                value={name}
                onChange={(event: any) => setName(event.target.value)}
                type='text'
                className='form-control'
                id='name'
                placeholder=''
              />
            </div>
          </div>
          <div className='col-md-12 mt-5'>
            <div className='table-responsive'>
              <table className='table table-striped' id='table'>
                <thead>
                  <tr className='fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200'>
                    <th className='min-w-450px'>{intl.formatMessage({id: 'GROUPS.SECTION'})}</th>
                    <th className='min-w-450px'>{intl.formatMessage({id: 'GROUPS.VIEW'})}</th>

                    <th className='min-w-450px'>{intl.formatMessage({id: 'GROUPS.SELECTION'})}</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, index) => {
                    if (item.views.filter((item) => item.label !== '').length > 0) {
                      return (
                        <Fragment key={`frag${index}`}>
                          <tr key={index} className='checkbox-list customTrGroups'>
                            <td className='col-form-label' rowSpan={item.views.length + 1}>
                              <div className='form-group form-check'>
                                <label className='checkbox checkbox-rounded'>
                                  <input
                                    type='checkbox'
                                    className='form-check-input'
                                    checked={item.isSelectAll}
                                    onChange={(e) => handleUpdateAllSection(e, index)}
                                  />
                                  <span></span>
                                  {item.sectionName}
                                </label>
                              </div>
                            </td>
                          </tr>

                          {item.views
                            .filter((item) => item.label !== '')
                            .map((view, indexItem) => (
                              <tr key={`${index}subKey${indexItem}`}>
                                <th style={{textTransform: 'capitalize'}} key={`Label${indexItem}`}>
                                  {view.label}
                                </th>
                                <th>
                                  <div className='form-group form-check' key={`Input${indexItem}`}>
                                    <label className='checkbox checkbox-rounded'>
                                      <input
                                        type='checkbox'
                                        className='form-check-input'
                                        onChange={(event: any) =>
                                          handleUpdateItem(event, index, indexItem)
                                        }
                                        checked={view.flag}
                                      />
                                    </label>
                                  </div>
                                </th>
                              </tr>
                            ))}
                        </Fragment>
                      )
                    } else {
                    }
                  })}
                </tbody>
              </table>
            </div>
          </div>

          <div className='d-flex justify-content-end py-6'>
            <button type='button' onClick={() => handleSave()} className='btn btn-primary rounded-pill'>
              {!loading && intl.formatMessage({id: 'ACTION.SAVE'})}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  {intl.formatMessage({id: 'PLEASE.WAIT'})}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
            <span>&nbsp;&nbsp;</span>
            <button
              type='button'
              className='btn btn-secondary mr-2 ml-2 rounded-pill'
              onClick={() => navigate('/groups/list')}
            >
              {intl.formatMessage({id: 'ACTION.CANCEL'})}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export {AddGroupForm}
