import axios from 'axios';
import { getTokenConfig } from '../../../../../src/auth'



export const fetchCustomers = async () => {
    const config = await getTokenConfig()
    try {
        let queryString = `${process.env.REACT_APP_API_URL}/admin/list-customers`;
   

        const response = await axios.get(queryString, config);

        const customers = response.data.data.customers;

        return { customers };
    } catch (err) {
        throw err;
    }
};

export const fetchCategories = async () => {
    const config = await getTokenConfig()
    try {
        let queryString = `${process.env.REACT_APP_API_URL}/list/categories`;

        const response = await axios.get(queryString, config);



        const categories = response.data.data.categories;

        return { categories };
    } catch (err) {
        throw err;
    }
};




export const fetchDetailCustomer = async (idCustomer: string) => {
    const config = await getTokenConfig()
    const idCustomerInt = parseInt(idCustomer);
    let queryString = `${process.env.REACT_APP_API_URL}/detail/customer/${idCustomerInt}`
    try {
        const response = await axios.get(queryString, {
            ...config
        }
        )
        const customer = response.data.data.customer;
        return {
            customer
        }

    } catch (error) {
        return {
            //  status: err.response ? err.response.status : 500,
            data: null
        }
    }
}



export const fetchLastReport = async () => {
    const config = await getTokenConfig()

    let queryString = `${process.env.REACT_APP_API_URL}/last-report`
    try {
        const response = await axios.get(queryString, {
            ...config
        }
        )


     

        const lastReport = response.data.data.report;
        const createdAt = response.data.data.createdAt;
        return {
            lastReport, createdAt
        }

    } catch (error) {
        return {
            //  status: err.response ? err.response.status : 500,
            data: null
        }
    }
}




export const saveInfoReport = async (reportParams: any) => {
    const config = await getTokenConfig();
    try {
        const url = `${process.env.REACT_APP_API_URL}/save-info/report`;


        const response = await axios.post(url, reportParams, config);

        const customers = response.data.data.customers;

        return { customers };
    } catch (err) {
        throw err;
    }
};


export const fetchDataToGraph = async (reportParams: any) => {
    const config = await getTokenConfig();
    try {
        const url = `${process.env.REACT_APP_API_URL}/dashboard/home`;

        const response = await axios.post(url, reportParams, config);

     

        const dataToGraph = response.data.data;

        return { dataToGraph };
    } catch (err) {
        throw err;
    }
};


export const fetchDataToGraphTable = async (reportParams: any) => {
    const config = await getTokenConfig();
    try {
        const url = `${process.env.REACT_APP_API_URL}/dashboard/home`;

        const response = await axios.post(url, reportParams, config);

     

        const dataToGraph = response.data.data;

        return { dataToGraph };
    } catch (err) {
        throw err;
    }
};

export const fetchDataToGraphTotal = async (reportParams: any) => {
    const config = await getTokenConfig();
    try {
        const url = `${process.env.REACT_APP_API_URL}/dashboard/home`;

        const response = await axios.post(url, reportParams, config);

     

        const dataToGraph = response.data.data;

        return { dataToGraph };
    } catch (err) {
        throw err;
    }
};


