import  { CognitoUserPool } from "amazon-cognito-identity-js"


const poolData = {
    UserPoolId: "us-east-2_cidNzLq5C",
    ClientId: "32kn24h51m7bqlbmresucsgc2m", 
    
}


export default new CognitoUserPool(poolData);