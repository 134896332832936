import axios, {  AxiosResponse } from 'axios';
import { getTokenConfig } from '../../../../../src/auth'





export const configDesc = async () => {
    try {
      let queryString = `${process.env.REACT_APP_API_URL_RECHARGES}/recharges/config`;
  
      const config = await getTokenConfig(); // Assuming getTokenConfig is a function that returns a config object for axios
      const response = await axios.get(queryString, config);
    

      console.log(response.data.data.recharges)
  
  
      const recharges = response.data.data.recharges;
   
  
      return { recharges};
    } catch (err) {
      throw err;
    }
  };
  

  export const updateRechargeConfig = async (value: any) => {
    try {
        let queryString = `${process.env.REACT_APP_API_URL_RECHARGES}/recharges/config`;


        console.log(value)
        const config = await getTokenConfig(); 
        const response = await axios.put(queryString, value, config);

        if (response.status !== 200) {
            throw new Error('Failed to update recharge configuration');
        }

        return response.data;
    } catch (err) {
        throw err;
    }
};

  